import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/reducers/reducer';
import {
	setDeletingTagId,
	setEditingTagId,
	setIsCreatingTagModalActive,
	setIsDeletingTagModalActive,
	setIsEditingTagModalActive,
	setIsTagMenuModalActive,
} from '../../../../../../redux/reducers/module/tagReducer';
import TagMenuModal from './TagMenuModal';
import { tagModalsActive, tags } from '../../../../../../redux/selectors/module/tag';
import { NewModal } from '../../../../../ui/new-modal';

interface TagsMenuModalWrapperProps {
	modalInModalActive: boolean,
}

const TagsMenuModalWrapper = ({
	modalInModalActive,
}: TagsMenuModalWrapperProps) => {
	const isTagMenuModalActive = useSelector((state: RootState) => tagModalsActive(state).isTagMenuModalActive);

	const tagsData = useSelector((state: RootState) => tags(state));

	const dispatch = useDispatch();

	const onDeletingTag = (id: number) => {
		dispatch(setDeletingTagId(id));
		dispatch(setIsDeletingTagModalActive(true));
	};

	const onEditingTag = (id: number) => {
		dispatch(setEditingTagId(id));
		dispatch(setIsEditingTagModalActive(true));
	};

	const handleTagMenuModal = (value: boolean) => dispatch(setIsTagMenuModalActive(value));

	const onClickBtnTagMenu = () => dispatch(setIsCreatingTagModalActive(true));

	return (
		<NewModal
			active={isTagMenuModalActive}
			setActive={handleTagMenuModal}
			modalInModalActive={modalInModalActive}
		>
			<TagMenuModal
				data={tagsData}
				onEditing={onEditingTag}
				onDeleting={onDeletingTag}
				searchHolder="Поиск тега"
				onCreate={onClickBtnTagMenu}
				createBtnText="Создать тег"
			/>
		</NewModal>
	);
};

export default TagsMenuModalWrapper;
