import { BaseQueryFn, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { v4 as uuid } from 'uuid';
import { SERVER_ENVIRONMENT_DEV, SERVER_ENVIRONMENT_RELEASE, SERVER_ENVIRONMENT_TEST } from '../../api/API';
import { notificationError } from '../../components/Notifications/callNotifcation';

const BASE_API_URL_PRODUCTION = 'https://wakadoo.ru/api/';
const BASE_API_URL_TEST = 'https://test.wakadoo.ru/api/';
const BASE_API_URL_DEV = 'http://localhost:25504/api/';

export const providesList = <R extends { id: string | number }[], T extends string>(
	resultsWithIds: R | undefined,
	tagType: T,
) => (resultsWithIds
		? [...resultsWithIds.map(({ id }) => ({
			type: tagType,
			id,
		}))]
		: [tagType]);

const setBaseUrl = () => {
	const serverEnvironment = process.env.REACT_APP_ENVIRONMENT_SERVER;

	switch (serverEnvironment) {
	case SERVER_ENVIRONMENT_RELEASE:
		return BASE_API_URL_PRODUCTION;
	case SERVER_ENVIRONMENT_TEST:
		return BASE_API_URL_TEST;
	case SERVER_ENVIRONMENT_DEV:
		return BASE_API_URL_DEV;
	default:
		return '';
	}
};

const paramsSerializer = (params: any) => {
	const searchParams = new URLSearchParams();
	Object.keys(params)
		.forEach((key) => {
			const value = params[key];
			if (Array.isArray(value)) {
				value.forEach((item) => {
					searchParams.append(key, item);
				});
			} else if (value) {
				searchParams.append(key, value);
			}
		});
	return searchParams.toString();
};

export const baseQuery = fetchBaseQuery({
	baseUrl: setBaseUrl(),
	paramsSerializer,
	credentials: 'include',
	prepareHeaders: (headers) => {
		const authorization = localStorage.getItem('authorization')
			? localStorage.getItem('authorization')
			: sessionStorage.getItem('authorization');

		headers.set('Client-Request-Id', uuid());
		headers.set('Authorization', authorization || '');

		return headers;
	},
});

const MB = 1024 * 1024;

export const baseQueryFile: BaseQueryFn<string | any, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions,
) => {
	if (args?.fileSize > 4 * MB) {
		notificationError('Сохранения файла', 'Загрузка файлов более 4MB запрещена');
	} else {
		return baseQuery(args, api, extraOptions);
	}
	return new Promise(args);
};
