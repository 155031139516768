import ManipulatorCommand from '../ManipulatorCommand';
import CommandType from '../CommandType';
import ILayerSequencesBody from './ILayerSequencesBody';
import ComponentLayers from '../../../mechanics/layers/ComponentLayers';
import { LayerSequences } from '../../../Types';
import IMutablePagesComponentTree from '../../../component-tree/IMutablePagesComponentTree';
import IComponentTreeMutator from '../../../component-tree/IComponentTreeMutator';
import ILayeredComponentTree from '../../../component-tree/ILayeredComponentTree';

class LayerSequencesCommand extends ManipulatorCommand<ILayerSequencesBody> {
	public type: CommandType = CommandType.LAYER_SEQUENCES;

	private readonly layerSequences: LayerSequences;
	private readonly componentTree: ILayeredComponentTree;

	constructor(componentTree: ILayeredComponentTree, layerSequences: LayerSequences) {
		super();
		this.layerSequences = layerSequences;
		this.componentTree = componentTree;
	}

	public exec = (): void => {
		this.componentTree.setLayerSequences(this.layerSequences);
	};

	protected buildBody(): ILayerSequencesBody {
		return {};
	}
}

export default LayerSequencesCommand;
