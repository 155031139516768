import React from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { IAdminAccountsItem } from '../../../../../../entities/admin/api/types';
import { UserCardAdminStatus } from './status/status';
import css from './user-card.module.scss';
import { copyToClipboard } from '../../../../../../helpers/copyText';

interface AdminUserCardProps {
	data: IAdminAccountsItem,
	styles?: string,
}

export const AdminUserCard = ({
	data,
	styles,
}: AdminUserCardProps) => {
	const navigate = useNavigate();

	const {
		name,
		email,
		tariff,
		photo,
		organization,
	} = data;

	const copyEmail = (e: React.MouseEvent) => {
		e.stopPropagation();
		copyToClipboard(email);
	};

	const toUserPage = () => navigate(`/admin/users/${email}`);

	return (
		<tr onClick={toUserPage} className={cx(css.wrapper, styles)}>
			<td className={css.main}>
				{photo.length && <img className={css.picture} src={`/api/file/${photo}`} alt="user" />}
				<div className={css.name}>{name}</div>
			</td>
			<td className={css.organization}>
				{organization.name}
			</td>
			<td className={css.email}>
				<span role="presentation" onClick={copyEmail}>{email}</span>
			</td>
			<td>
				<UserCardAdminStatus tariff={tariff} />
			</td>
		</tr>
	);
};
