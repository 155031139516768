import MultiPageManipulator from './MultiPageManipulator';
import ComponentAlignment from '../Sketch/mechanics/component-alignment/ComponentAlignment';
import DynamicGraphicFactory from '../Sketch/factories/graphic/DynamicGraphicFactory';
import IMutablePagesComponentTree from '../Sketch/component-tree/IMutablePagesComponentTree';
import DynamicComponentFactory from '../Sketch/factories/component/DynamicComponentFactory';
import PageScaleListener from '../Sketch/interface/bars/tool-bar/panelZoom/PageScaleListener';

/**
 * Реализация манипулятора скетча со всеми возможностями изменения структуры скетча учитывая многостраничную структуру.
 */
abstract class DynamicMultiPageManipulator<UseCasesType, SpatialAreaTreeType>
	extends MultiPageManipulator<
		IMutablePagesComponentTree,
		DynamicGraphicFactory,
		DynamicComponentFactory,
		UseCasesType,
		SpatialAreaTreeType
	> {
	protected readonly componentAlignment: ComponentAlignment;

	protected constructor(manipulatorContainer: HTMLDivElement) {
		super(manipulatorContainer);
		this.componentFactory = new DynamicComponentFactory();
		this.componentAlignment = new ComponentAlignment();

		PageScaleListener.start();
		this.addDestructListener(PageScaleListener.stop);
	}
}

export default DynamicMultiPageManipulator;
