import _ from 'lodash';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import ITableCellTexture from './cells/ITableCellTexture';
import TableCell from './cells/TableCell';
import TableComponent from '../../components/table/TableComponent';
import Utils from '../../utils/impl/Utils';
import { notificationError } from '../../../Notifications/callNotifcation';
import Dependent from '../../utils/dependent/Dependent';
import TableOrganizer from '../../mechanics/component-organizer/TableOrganizer';

/**
 * Сущность для изменения сетки ячеек таблицы.
 */
class TableGridMutator {
	private readonly postMutationListeners: ((component: TableComponent) => VoidFunction)[];

	constructor() {
		this.postMutationListeners = [];
	}

	/**
	 * Объединяет ячейки в фокусе.
	 * @param component компонент таблицы, в которой будет происходить объединение.
	 */
	public mergeFocusCell = (component: TableComponent) => {
		const cells = component.getCells();
		const focusCells = component.getFocusCells();
		if (focusCells === null || focusCells.length < 2) {
			return;
		}

		const isAccessMerge = this.validateMerge(focusCells);
		if (!isAccessMerge) {
			notificationError('Объединение ячеек', 'Фигура, образованная выбранными ячейками, '
				+ 'не соответствует прямоугольнику. Пожалуйста, выберите ячейки таким образом, '
				+ 'чтобы они образовывали прямоугольник.');
			return;
		}

		const columnCount = component.getColumnCount();

		const row = Math.min(...focusCells.map(cell => cell.getRow()));
		const column = Math.min(...focusCells.map(cell => cell.getColumn()));
		const targetCell = cells.filter(cell => cell.getRow() === row && cell.getColumn() === column)[0];
		if (targetCell === undefined) {
			throw new ManipulatorError('target cell not found');
		}

		let columnSpan = 0;
		focusCells.forEach(cell => {
			const cellRow = cell.getRow();
			if (cellRow !== row) {
				return;
			}
			columnSpan += cell.getColumnSpan();
		});

		let rowSpan = 0;
		focusCells.forEach(cell => {
			const cellColumn = cell.getColumn();
			if (cellColumn !== column) {
				return;
			}
			rowSpan += cell.getRowSpan();
		});

		const updatedTextures: ITableCellTexture[] = [];
		cells.forEach(cell => {
			const texture = cell.getTexture();
			if (cell === targetCell) {
				texture.rowSpan = rowSpan;
				texture.columnSpan = columnSpan;
				updatedTextures.push(texture);
			}
			if (!focusCells.includes(cell)) {
				updatedTextures.push(texture);
			}
		});

		this.applyChanges(component, updatedTextures, columnCount);

		if (!component.focusOnlyCellByID(targetCell.getID())) {
			throw new ManipulatorError('error focus only cell');
		}
	};

	/**
	 * Разбивает объединенные ячейки.
	 * @param component компонент таблицы, в которой будет происходить разбивка.
	 */
	public splitFocusCells = (component: TableComponent) => {
		const cells = component.getCells();
		const focusCells = component.getFocusCells();
		if (focusCells === null) {
			return;
		}

		const isAccessSplit = this.validateSplit(focusCells);
		if (!isAccessSplit) {
			return;
		}

		const createdCells: ITableCellTexture[] = [];
		const updatedTextures: ITableCellTexture[] = [];
		focusCells.forEach(cell => {
			const texture = cell.getTexture();
			if (texture.columnSpan === 1 && texture.rowSpan === 1) {
				updatedTextures.push(texture);
				return;
			}

			for (let currentRow = texture.row; currentRow < texture.row + texture.rowSpan; currentRow++) {
				for (
					let currentColumn = texture.column;
					currentColumn < texture.column + texture.columnSpan;
					currentColumn++) {
					const createdTexture = this.getSingleCellTexture(currentRow, currentColumn, texture.background);
					createdCells.push(createdTexture);
					updatedTextures.push(createdTexture);
				}
			}
		});

		cells.forEach(cell => {
			const isFocusCell = focusCells.includes(cell);
			if (isFocusCell) {
				return;
			}
			const texture = cell.getTexture();
			updatedTextures.push(texture);
		});

		const columnCount = component.getColumnCount();
		this.applyChanges(component, updatedTextures, columnCount);

		const firstSplitCell = createdCells[0];
		component.focusOnlyCellByID(firstSplitCell.id);
	};

	/**
	 * Удаляет колонки, которые пересекают ячейки в фокусе.
	 * @param component компонент, в котором будет происходить удаление колонок.
	 */
	public deleteFocusColumns = (component: TableComponent) => {
		const focusCells = component.getFocusCells();
		if (focusCells === null) {
			return;
		}

		const columnIndexes = this.getColumnIndexes(focusCells);
		columnIndexes.forEach((columnIndex, index) => {
			this.deleteColumn(component, columnIndex - index);
		});
	};

	/**
	 * Удаляет строки, которые пересекают ячейки в фокусе.
	 * @param component компонент таблицы, в котором будет происходить удаление строк.
	 */
	public deleteFocusRows = (component: TableComponent) => {
		const focusCells = component.getFocusCells();
		if (focusCells === null) {
			return;
		}

		const rowIndexes = this.getRowIndexes(focusCells);
		rowIndexes.forEach((rowIndex, index) => {
			this.deleteRow(component, rowIndex - index);
		});
	};

	/**
	 * Добавляет новую колонку таблицы перед самой крайней слева ячейкой в фокусе.
	 * @param component - компонент таблицы, в котором происходит добавление колонок.
	 */
	public addColumnBefore(component: TableComponent) {
		const targetIndex = component.getLeftmostColumnIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);
		// Сдвинуть column у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем колонку если она находится после строки от которой добавляем
			if (cell.columnSpan > 1) {
				if (targetIndex > cell.column && targetIndex <= cell.column + cell.columnSpan - 1) cell.columnSpan += 1;
			}
			if (cell.column >= targetIndex) cell.column += 1;
		}

		// Получаем шаблон стилей строки
		const patternColumn = textures.filter(texture => texture.column === targetIndex);
		for (let i = 0; i < patternColumn.length; i++) {
			const patternCell = patternColumn[i];

			const cell = this.getSingleCellTexture(patternCell.row, targetIndex, patternCell.background);
			cell.rowSpan = patternCell.rowSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getColumnMultipliers();
		const columnCount = component.getColumnCount() + 1;

		multipliers.splice(targetIndex, 0, 1);

		component.setColumnMultipliers(multipliers);
		this.applyChanges(component, updatedTextures, columnCount);
	}

	/**
	 * Добавляет новые колонки таблицы после самой крайней справа ячейки в фокусе.
	 * @param component - компонент таблицы в которой происходит добавление колонок.
	 */
	public addColumnAfter(component: TableComponent) {
		const targetIndex = component.getRightmostColumnIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть column у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем строку если она находится после строки от которой добавляем
			if (cell.columnSpan > 1) {
				if (targetIndex >= cell.column && targetIndex < cell.column + cell.columnSpan - 1) cell.columnSpan += 1;
			}
			if (cell.column > targetIndex) cell.column += 1;
		}

		// Получаем шаблон стилей строки
		const patternColumn = textures.filter(texture => (texture.column === targetIndex && texture.columnSpan === 1)
			|| targetIndex === (texture.column + texture.columnSpan - 1));

		for (let i = 0; i < patternColumn.length; i++) {
			const patternCell = patternColumn[i];

			const cell = this.getSingleCellTexture(patternCell.row, targetIndex + 1, patternCell.background);
			if (patternCell.rowSpan > 1) cell.rowSpan = patternCell.rowSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getColumnMultipliers();
		const columnCount = component.getColumnCount() + 1;

		multipliers.splice(targetIndex + 1, 0, 1);

		component.setColumnMultipliers(multipliers);
		this.applyChanges(component, updatedTextures, columnCount);
	}

	/**
	 * Добавляет новую строку ПОД выделенными ячейками (новая строка будет находиться над самой верхней ячейкой в
	 * выделении).
	 * @param component - Компонент таблицы.
	 */
	public addRowUnder(component: TableComponent) {
		const targetIndex = component.getLowestIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть row у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем строку если она находится после строки от которой добавляем
			if (cell.rowSpan > 1) {
				if (targetIndex >= cell.row && targetIndex < cell.row + cell.rowSpan - 1) cell.rowSpan += 1;
			}
			if (cell.row > targetIndex) cell.row += 1;
		}

		// Получаем шаблон стилей строки
		const patternRow = textures.filter(texture => (texture.row === targetIndex && texture.rowSpan === 1)
			|| targetIndex === (texture.row + texture.rowSpan - 1));

		for (let i = 0; i < patternRow.length; i++) {
			const patternCell = patternRow[i];

			const cell = this.getSingleCellTexture(targetIndex + 1, patternCell.column, patternCell.background);
			cell.columnSpan = patternCell.columnSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getRowsMultipliers();
		const columnCount = component.getColumnCount();
		
		multipliers.splice(targetIndex + 1, 0, 1);

		component.setRowMultipliers(multipliers);
		this.applyChanges(component, updatedTextures, columnCount);
	}

	/**
	 * Добавляет новую строку НАД выделенными ячейками новая строка будет находиться над самой верхней ячейков в
	 * выделении.
	 * @param component - Компонент таблицы.
	 */
	public addRowOver(component: TableComponent) {
		const targetIndex = component.getHighestIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть row у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем строку если она находится после строки от которой добавляем
			if (cell.rowSpan > 1) {
				if (targetIndex > cell.row && targetIndex <= cell.row + cell.rowSpan - 1) cell.rowSpan += 1;
			}
			if (cell.row >= targetIndex) cell.row += 1;
		}

		// Получаем шаблон стилей строки
		const patternRow = textures.filter(texture => texture.row === targetIndex);

		for (let i = 0; i < patternRow.length; i++) {
			const patternCell = patternRow[i];

			const cell = this.getSingleCellTexture(targetIndex, patternCell.column, patternCell.background);
			cell.columnSpan = patternCell.columnSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getRowsMultipliers();
		const columnCount = component.getColumnCount();

		multipliers.splice(targetIndex, 0, 1);

		component.setRowMultipliers(multipliers);
		this.applyChanges(component, updatedTextures, columnCount);
	}

	public addPostMutationListener = (listener: (component: TableComponent) => VoidFunction) => {
		this.postMutationListeners.push(listener);
	};

	private applyChanges = (component: TableComponent, updatedTextures: ITableCellTexture[], columnCount: number) => {
		component.loadCells(updatedTextures, columnCount);
		component.renderCells();
		this.callPostMutationListeners(component);
	};

	private callPostMutationListeners = (component: TableComponent) => {
		this.postMutationListeners.forEach(listener => listener(component));
	};

	/**
	 * Возвращает массив индексов колонок, которые пересекают ячейки, отсортированный по возрастанию.
	 * @param cells ячейки.
	 */
	private getColumnIndexes = (cells: TableCell[]): number[] => {
		const focusColumnIndexes: Set<number> = new Set<number>();
		cells.forEach(cell => {
			const cellColumn = cell.getColumn();
			const cellColumnSpan = cell.getColumnSpan();

			for (let column = cellColumn; column < cellColumn + cellColumnSpan; column++) {
				focusColumnIndexes.add(column);
			}
		});
		return [...focusColumnIndexes].sort();
	};

	/**
	 * Возвращает массив индексов строк, которые пересекают ячейки, отсортированный по возрастанию.
	 * @param cells ячейки.
	 */
	private getRowIndexes = (cells: TableCell[]): number[] => {
		const focusRowIndexes: Set<number> = new Set<number>();
		cells.forEach(cell => {
			const cellRow = cell.getRow();
			const cellRowSpan = cell.getRowSpan();
			for (let row = cellRow; row < cellRow + cellRowSpan; row++) {
				focusRowIndexes.add(row);
			}
		});
		return [...focusRowIndexes].sort((a, b) => a - b);
	};

	/**
	 * Удаляет колонку по индексу.
	 * @param component компонент таблицы.
	 * @param columnIndex индекс колонки для удаления.
	 */
	private deleteColumn = (component: TableComponent, columnIndex: number) => {
		const columns = component.getColumnCount();
		if (columns === 1) {
			return;
		}

		const cells = component.getCells();
		const areas = component.getGridAreas();
		const rowCount = component.getRowCount();
		const deleteTextures: string[] = [];
		const textures = cells.map(cell => cell.getTexture());

		let updatedTextures = cells.map(cell => cell.getTexture());

		for (let currentRow = 0; currentRow < rowCount; currentRow++) {
			const targetId = areas[currentRow][columnIndex];
			if (targetId === undefined) {
				throw new ManipulatorError('target id not found');
			}
			const targetTexture = updatedTextures.find(texture => texture.id === targetId);
			if (targetTexture === undefined) {
				throw new ManipulatorError('target texture not found');
			}

			if (targetTexture.columnSpan === 1) {
				deleteTextures.push(targetTexture.id);
			} else {
				targetTexture.columnSpan -= 1;
			}

			currentRow += targetTexture.rowSpan - 1;
		}

		const startMutateCellColumn = columnIndex + 1;

		if (startMutateCellColumn !== columns) {
			for (let row = 0; row < rowCount; row++) {
				for (let column = startMutateCellColumn; column < columns; column++) {
					const targetId = areas[row][column];
					if (targetId === undefined) {
						throw new ManipulatorError('target id not found');
					}
					const targetTexture = updatedTextures.find(texture => texture.id === targetId);
					if (targetTexture === undefined) {
						throw new ManipulatorError('target texture not found');
					}
					const currentPositionTexture = textures.find(texture => texture.id === targetId);
					if (currentPositionTexture === undefined) {
						throw new ManipulatorError('current position texture not found');
					}
					const isControlPosition = this.validateControlPosition(currentPositionTexture, column, row);
					if (isControlPosition) {
						targetTexture.column -= 1;
						row += targetTexture.rowSpan - 1;
					}
				}
			}
		}

		const multipliers = component.getColumnMultipliers();
		const updatedMultipliers = multipliers.filter((multiplier, index) => index !== columnIndex);
		const updatedColumns = columns - 1;
		updatedTextures = updatedTextures.filter(texture => !deleteTextures.includes(texture.id));

		component.setColumnMultipliers(updatedMultipliers);
		this.applyChanges(component, updatedTextures, updatedColumns);
	};

	/**
	 * Удаляет строку по индексу.
	 * @param component компонент таблицы.
	 * @param rowIndex индекс строки для удаления.
	 */
	private deleteRow = (component: TableComponent, rowIndex: number) => {
		const rowCount = component.getRowCount();
		if (rowCount === 1) {
			return;
		}

		const cells = component.getCells();
		const areas = component.getGridAreas();
		const columnCount = component.getColumnCount();
		const deleteTextures: string[] = [];
		const textures = cells.map(cell => cell.getTexture());

		let updatedTextures = cells.map(cell => cell.getTexture());

		for (let currentColumn = 0; currentColumn < columnCount; currentColumn++) {
			const targetId = areas[rowIndex][currentColumn];
			if (targetId === undefined) {
				throw new ManipulatorError('target id not found');
			}
			const targetTexture = updatedTextures.find(texture => texture.id === targetId);
			if (targetTexture === undefined) {
				throw new ManipulatorError('target texture not found');
			}

			if (targetTexture.rowSpan === 1) {
				deleteTextures.push(targetTexture.id);
			} else {
				targetTexture.rowSpan -= 1;
			}

			currentColumn += targetTexture.columnSpan - 1;
		}

		const startMutateCellRow = rowIndex + 1;
		if (startMutateCellRow !== rowCount) {
			for (let row = startMutateCellRow; row < rowCount; row++) {
				for (let column = 0; column < columnCount; column++) {
					const targetId = areas[row][column];
					if (targetId === undefined) {
						throw new ManipulatorError('target id not found');
					}
					const targetTexture = updatedTextures.find(texture => texture.id === targetId);
					if (targetTexture === undefined) {
						throw new ManipulatorError('target texture not found');
					}
					const currentPositionTexture = textures.find(texture => texture.id === targetId);
					if (currentPositionTexture === undefined) {
						throw new ManipulatorError('current position texture not found');
					}

					const isControlPosition = this.validateControlPosition(currentPositionTexture, column, row);
					if (isControlPosition) {
						targetTexture.row -= 1;
						column += targetTexture.columnSpan - 1;
					}
				}
			}
		}
		const multipliers = component.getRowsMultipliers();
		multipliers.splice(rowIndex, 1);
		component.setRowMultipliers(multipliers);

		updatedTextures = updatedTextures.filter(texture => !deleteTextures.includes(texture.id));
		this.applyChanges(component, updatedTextures, columnCount);
	};

	/**
	 * Выполняет проверку, образуют ли ячейки правильный прямоугольник. Вычисляет площадь ожидаемого
	 * прямоугольника и сравнивает её с площадью ячеек.
	 * @param cells ячейки таблицы.
	 */
	private validateMerge = (cells: TableCell[]): boolean => {
		let minColumn = Number.MAX_SAFE_INTEGER;
		let minRow = Number.MAX_SAFE_INTEGER;
		let maxColumn = Number.MIN_SAFE_INTEGER;
		let maxRow = Number.MIN_SAFE_INTEGER;

		cells.forEach(cell => {
			minColumn = Math.min(minColumn, cell.getColumn());
			minRow = Math.min(minRow, cell.getRow());
			maxColumn = Math.max(maxColumn, cell.getColumn() + cell.getColumnSpan());
			maxRow = Math.max(maxRow, cell.getRow() + cell.getRowSpan());
		});

		const width = maxColumn - minColumn;
		const height = maxRow - minRow;
		const area = width * height;

		let cellsArea = 0;
		cells.forEach(cell => {
			cellsArea += cell.getColumnSpan() * cell.getRowSpan();
		});

		return area === cellsArea;
	};

	private validateSplit = (cells: TableCell[]): boolean => {
		let isValid = false;
		cells.forEach(cell => {
			const rowSpan = cell.getRowSpan();
			const columnSpan = cell.getColumnSpan();
			if (rowSpan > 1 || columnSpan > 1) {
				isValid = true;
			}
		});
		return isValid;
	};

	private getSingleCellTexture = (row: number, column: number, background: string): ITableCellTexture => ({
		id: Utils.Generate.UUID4(),
		column,
		row,
		background,
		rowSpan: 1,
		content: Utils.Component.getDefaultTextModel(),
		columnSpan: 1,
	});

	private getCellTextureById = (cells: TableCell[], id: string): ITableCellTexture | null => {
		let targetCell: ITableCellTexture | null = null;
		cells.forEach((cell) => {
			if (cell.getTexture().id === id) {
				targetCell = cell.getTexture();
			}
		});
		return targetCell;
	};

	private validateControlPosition = (
		texture: ITableCellTexture,
		column: number,
		row: number,
	): boolean => texture.row === row && texture.column === column;
}

export default TableGridMutator;
