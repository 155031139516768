import cx from 'classnames';
import css from './selectable-filter-card.module.scss';

export interface ISelectAbleCardData {
	text: string,
	id: string,
	photo?: string,
}

type TSelectableCardStyle = 'filled' | 'light-bordered' | 'bright-bordered'

interface SelectAbleUserCardProps {
	styles?: string,
	selected?: boolean,
	data: ISelectAbleCardData,
	onSelect?: (id: string) => void
	selectedType?: TSelectableCardStyle,
}

export const SelectableFilterCard = ({
	data,
	styles,
	onSelect,
	selected,
	selectedType = 'filled',
}: SelectAbleUserCardProps) => {
	const {
		id,
		text,
		photo,
	} = data;

	return (
		<div
			role="presentation"
			onClick={() => onSelect && onSelect(id)}
			className={cx(css.wrapper, styles, selected && css[selectedType])}
		>
			{photo && <img className={css.picture} src={`/api/file/${photo}`} alt="user" />}
			<div className={css.name}>{text}</div>
		</div>
	);
};
