import Dependent from '../../utils/dependent/Dependent';
import TableComponent from '../../components/table/TableComponent';
import { notificationError } from '../../../Notifications/callNotifcation';
import TableCell from '../../graphic/table/cells/TableCell';
import XLSXTableImporter from '../import-table/implements/XLSXTableImporter';
import IImportTableStructure from '../import-table/IImportTableStructure';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import { ITokenText, TokenType } from '../mext/parser';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';

export interface IXLSXTableReplacerDependencies {
	XLSXTableImporter: XLSXTableImporter,
}

abstract class XLSXTableReplacer<Dependencies extends IXLSXTableReplacerDependencies> extends Dependent<Dependencies> {
	private readonly postReplaceListeners: ((tableComponent: TableComponent) => void)[];

	protected constructor() {
		super();
		this.postReplaceListeners = [];
	}

	/**
	 * Запускает процесс выбора XLSX файла пользователем и замены содержимого таблиц его структурой с передачей
	 * форматирования ячеек.
	 * Логика переноса стилей с заменяемой таблицы на новую:
	 * Форматирование считывается с ячеек в фокусе либо в случае их отсутствия со всей таблицы. За основу берем
	 * последовательность стилей форматирования в строке/строках и количество этих строк. В том случае, когда у новой
	 * таблицы больше строк или столбцов, чем выделено в заменяемой таблице, то эти последовательности просто
	 * повторяются.
	 * При не совпадении числа страниц в файле и количества таблиц - последние будут добавлены/проигнорированы.
	 */
	public replaceTable = (tableComponents: TableComponent[]) => {
		if (tableComponents.length > 1) {
			notificationError('Замена таблицы', 'В фокусе более одной таблицы');
			return;
		}

		// Получаем таблицу и ячейки в фокусе
		const focusTable = tableComponents[0];
		if (focusTable === undefined) {
			throw new ManipulatorError('table is undefined');
		}
		const focusCells = focusTable.getFocusCells();

		// Получаем ячейки по которым будет копировать форматирование
		const patternCells: TableCell[] = focusCells === null ? focusTable.getCells() : focusCells;

		// Создаем шаблон стилей и проверяем его
		const patternStyles = this.generatePatternStyles(patternCells);

		if (!this.isPatternStylesRectangle(patternCells, patternStyles)) {
			notificationError('Замена таблицы', 'Ячейки в фокусе, не образуют прямоугольник');
			return;
		}

		this.dependencies.XLSXTableImporter.getStructure((tables: IImportTableStructure[]) => {
			// Всегда вставляем таблицу из первого листа.
			const { rowCount, columnCount, cells } = tables[0];

			const resizedPatternStyles = this.resizePatternStyles(patternStyles, rowCount, columnCount);

			for (let i = 0; i < cells.length; i++) {
				const cell = cells[i];
				const { row, column } = cell;

				cell.content.tokens = this.getStylesFromPattern(cell, resizedPatternStyles[row][column]);
				cell.background = resizedPatternStyles[row][column].background;
			}

			const rowMultipliers = Array(rowCount).fill(1);
			const columnMultipliers = Array(columnCount).fill(1);

			focusTable.setRowMultipliers(rowMultipliers);
			focusTable.setColumnMultipliers(columnMultipliers);
			focusTable.loadCells(cells, columnCount);
			focusTable.renderCells();

			this.callPostReplaceListeners(focusTable);
		});
	};

	protected addPostReplaceListener(listener: (tableComponent: TableComponent) => void) {
		this.postReplaceListeners.push(listener);
	}

	private callPostReplaceListeners = (tableComponent: TableComponent) => {
		this.postReplaceListeners.forEach(listener => listener(tableComponent));
	};

	private getStylesFromPattern = (
		cell: ITableCellTexture,
		pattern: ITableCellTexture,
	) => {
		const cellTextToken = cell.content.tokens.find(token => token.type === TokenType.Text) as ITokenText;
		const patternTextToken = pattern.content.tokens.find(token => token.type === TokenType.Text) as ITokenText;

		const tokens = [...cell.content.tokens];
		for (let i = 0; i < tokens.length; i++) {
			if (tokens[i].type === TokenType.Text) {
				tokens[i] = { ...patternTextToken, value: cellTextToken.value };
			}
		}
		return tokens;
	};

	/**
	 * Возвращает масштабированный шаблон стилей, до размеров импортируемой таблицы.
	 * @param patternStyles Шаблон стилей
	 * @param rowCount Количество строк, в импортируемой таблице
	 * @param columnCount Количество колонок, в импортируемой таблице
	 */
	private resizePatternStyles = (
		patternStyles: ITableCellTexture[][],
		rowCount: number,
		columnCount: number,
	): ITableCellTexture[][] => {
		const resizedArray: ITableCellTexture[][] = [];

		const currentRowCount = patternStyles.length;
		const currentColumnCount = patternStyles[0].length;

		for (let row = 0; row < rowCount; row++) {
			if (resizedArray[row] === undefined) resizedArray[row] = [];
			for (let column = 0; column < columnCount; column++) {
				const originalRow = row % currentRowCount;
				const originalColumn = column % currentColumnCount;

				resizedArray[row][column] = patternStyles[originalRow][originalColumn];
			}
		}

		return resizedArray;
	};

	/**
	 * Возвращает результат проверки полученной фигуры из ячеек на соответствие прямоугольнику.
	 * @param cells Ячейки для вычисления итогового количества строк и колонок
	 * @param patternStyles Шаблон стилей, для проверки
	 */
	private isPatternStylesRectangle = (cells: TableCell[], patternStyles: ITableCellTexture[][]): boolean => {
		// Размеры шаблонной таблицы
		const uniqueColumns = new Set<number>();
		const uniqueRows = new Set<number>();

		for (let i = 0; i < cells.length; i++) {
			const { row, column } = cells[i].getTexture();
			uniqueRows.add(row);
			uniqueColumns.add(column);
		}

		const rowCount = uniqueRows.size;
		const columnCount = uniqueColumns.size;

		for (let row = 0; row < rowCount; row++) {
			for (let column = 0; column < columnCount; column++) {
				if (patternStyles[row][column] === undefined) {
					return false;
				}
			}
		}

		return true;
	};

	/**
	 * Возвращает сгенерированный шаблон стилей, на основе ячеек.
	 * @param cells Ячейки для копирования стилей
	 */
	private generatePatternStyles = (cells: TableCell[]): ITableCellTexture[][] => {
		// Вычисляем размеры таблицы для построения
		let rowCount = 0;
		let columnCount = 0;

		for (let i = 0; i < cells.length; i++) {
			const { row, column } = cells[i].getTexture();

			if (row > rowCount) rowCount = row;
			if (column > columnCount) columnCount = column;
		}

		rowCount++;
		columnCount++;

		const pattern: ITableCellTexture[][] = [];

		// Инициализация 2-мерного массива нужного размера
		for (let i = 0; i < rowCount; i++) {
			pattern[i] = new Array(columnCount).fill(null);
		}
		// Заполняем 2-мерный массив клетками для последующего копирование стилей
		for (let i = 0; i < cells.length; i++) {
			const cell = cells[i].getTexture();
			const {
				row, rowSpan, column, columnSpan,
			} = cell;

			pattern[row][column] = cell;

			if (rowSpan > 1 || columnSpan > 1) {
				/* TODO Убрать проверку на rowCount & columnCount если починили установку columnSpan & rowSpan. */
				for (let currentRow = row; currentRow < row + rowSpan && currentRow < rowCount; currentRow++) {
					for (let currentColumn = column; currentColumn < column + columnSpan
					&& currentColumn < columnCount; currentColumn++) {
						if (!pattern[currentRow][currentColumn]) {
							pattern[currentRow][currentColumn] = cell;
						}
					}
				}
			}
		}
		// Вырезаем необходимую часть для копирования стилей
		const slicedPattern = pattern.flatMap(cells => {
			const filteredCells = cells.filter(cell => cell !== null);
			return filteredCells.length > 0 ? [filteredCells] : [];
		});

		return slicedPattern;
	};
}

export default XLSXTableReplacer;
