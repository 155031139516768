import { useEffect, useMemo } from 'react';
import { TTemplateSortBy } from '../../../../../../../entities/templates/types';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import { useDebounce } from '../../../../../../../shared/libs/useDebounce';
import { galleryAPI } from '../../../../../../../entities/templates/gallery/api/api';
import { ITEM_LIMIT_PER_PAGE } from '../../SectionTemplate';
import useDynamicPagination from '../../../../../../../hooks/useDynamicPagination';
import { setGalleryTemplates } from '../../../../../../../entities/templates/gallery/model/slice';
import { IGalleryTemplate } from '../../../../../../../entities/templates/gallery/model/types';

export const useGetGalleryTemplates = (sortBy?: TTemplateSortBy) => {
	const dispatch = useAppDispatch();

	const {
		search,
		tagFilter,
		galleryTemplates,
	} = useAppSelector(state => state.galleryTemplate);

	const debounceSearch = useDebounce(search.trim(), 250);

	const setList = (list: IGalleryTemplate[]) => dispatch(setGalleryTemplates(list));

	const tags = useMemo(() => tagFilter.map(tag => tag.id.toString()), [tagFilter]);

	const {
		data: initialTemplates,
		isLoading,
	} = galleryAPI.useGetAllQuery({
		page: 1,
		sortBy,
		tags,
		limit: ITEM_LIMIT_PER_PAGE,
		search: debounceSearch,
	});

	const [getTemplates] = galleryAPI.useLazyGetAllQuery();

	const fetchData = async (page: number, perPage: number) => {
		if (galleryTemplates) {
			return getTemplates({
				page,
				sortBy,
				limit: perPage,
				search: debounceSearch,
			})
				.unwrap()
				.then(data => {
					setList([...galleryTemplates, ...data.galleryTemplates]);
				});
		}
		return undefined;
	};

	const {
		setCurrentPage,
		setTotalCount,
	} = useDynamicPagination(
		fetchData,
		ITEM_LIMIT_PER_PAGE,
		2,
		galleryTemplates?.length || 0,
	);

	// * При изменении поискового значения, вмонтировании компонента
	useEffect(() => {
		if (initialTemplates) {
			setCurrentPage(2);
			setList(initialTemplates?.galleryTemplates || []);

			setTotalCount(initialTemplates?.totalCount || 0);
		}
	}, [initialTemplates]);

	return {
		isLoading,
	};
};
