import InterfacePanel from '../../../InterfacePanel';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import TableComponent from '../../../../components/table/TableComponent';
import Utils from '../../../../utils/impl/Utils';
import ITableCellTexture from '../../../../graphic/table/cells/ITableCellTexture';
import ITableComponentTexture from '../../../../components/table/ITableComponentTexture';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';
import NamedColorSelector from '../../top-bar/NamedColorSelector';
import SquareButton from '../../../buttons/SquareButton';
import SvgCollection from '../../../../utils/SvgCollection';
import ButtonsContainer from '../../../buttons/ButtonsContainer';

/**
 * Панель для управления характеристиками таблиц.
 */
class TablePanel extends InterfacePanel {
	private readonly CSS_ELEMENT_CLASS_NAME = 'propertyBar__table-panel table-panel';
	private readonly HEADER_TEXT = 'Таблица';

	private readonly cellColorFillSelector: NamedColorSelector;
	private readonly cellColorBorderSelector: NamedColorSelector;

	constructor(
		private readonly useCases: IBaseUseCases,
	) {
		super();

		this.setRootElementClassName(this.CSS_ELEMENT_CLASS_NAME);
		this.setHeader(this.HEADER_TEXT);

		this.cellColorFillSelector = new NamedColorSelector({
			text: 'Заливка',
			textClassName: 'table-panel__fill-text',
			containerClassName: 'table-panel__fill',
			onSelectColorListener: this.onSelectFillColor,
			isDisableTransparent: true,
		});

		this.cellColorBorderSelector = new NamedColorSelector({
			text: 'Граница',
			textClassName: 'table-panel__border-color-title',
			containerClassName: 'table-panel__border-color',
			onSelectColorListener: this.onSelectBorderColor,
			isDisableTransparent: true,
		});

		const thirdRowContainer = new ButtonsContainer('table-properties__third-row');

		thirdRowContainer.appendSquareButton(button => {
			button.setIcon(SvgCollection.PDF_REPLACE);
			button.addClickListener(this.onLoadXSLX);
			button.setRootElementClassName('table-properties__item');
		});

		this.rootElement.append(this.cellColorFillSelector.getElement());
		this.rootElement.append(this.cellColorBorderSelector.getElement());
		this.rootElement.append(thirdRowContainer.getElement());
	}

	private onSelectFillColor = (color: string) => {
		this.useCases.setFocusTableCellFill(color);
	};

	private onSelectBorderColor = (color: string) => {
		this.useCases.setFocusTableBorderColor(color);
	};

	public onLoadXSLX = (): void => {
		this.useCases.replaceTablesFromXLSX();
	};

	public sync = (components: TableComponent[]) => {
		const textures: ITableComponentTexture[] = [];
		const cells: ITableCellTexture[] = [];

		components.forEach(component => {
			const componentTexture = component.getTexture();
			cells.push(...componentTexture.cells);
			textures.push(componentTexture);
		});

		const sameTextures = Utils.Object.checkFieldsEquality<ITableComponentTexture>(...textures);
		const sameCells = Utils.Object.checkFieldsEquality<ITableCellTexture>(...cells);

		if (sameTextures.borderColor !== null) {
			this.cellColorBorderSelector.setColor(sameTextures.borderColor as string);
		}

		if (sameCells.background !== null) {
			this.cellColorFillSelector.setColor(sameCells.background as string);
		}
	};

	private setHeader = (text: string) => {
		const headerElement = HTMLGenerator.getH4({
			className: 'table-panel__title',
			text,
		});
		this.rootElement.append(headerElement);
	};
}

export default TablePanel;
