import XLSXTableReplacer, { IXLSXTableReplacerDependencies } from './XLSXTableReplacer';
import SpatialAreaTree from '../spatial-quadrants/spatial-tree/SpatialAreaTree';
import TableOrganizer from '../component-organizer/TableOrganizer';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import ComponentFocusObserver from '../../utils/observers/ComponentFocusObserver';
import renderStep from '../../utils/RenderStepGenerator';
import TableComponent from '../../components/table/TableComponent';

interface IMultiPageTableReplacerDependencies extends IXLSXTableReplacerDependencies {
	spatialTree: SpatialAreaTree,
	tableOrganizer: TableOrganizer,
	sketchStabilizer: SketchStructureStabilizer,
	componentFocusObserver: ComponentFocusObserver,
}

class MultiPageTableReplacer extends XLSXTableReplacer<IMultiPageTableReplacerDependencies> {
	constructor() {
		super();
		this.addPostReplaceListener((tableComponent: TableComponent) => {
			setTimeout(this.dependencies.tableOrganizer.reorganize
				.bind(this, tableComponent), 0);
			setTimeout(this.dependencies.tableOrganizer.reorganize
				.bind(this, tableComponent), 100);
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 100);
			setTimeout(tableComponent.renderCells.bind(this), 100);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction, 100);
		});
	}
}

export default MultiPageTableReplacer;
