import {
	Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import React, { Suspense } from 'react';
import { TabsHeaderLayout } from '../../../layouts/tabs-header';
import { workshopTabs } from '../model/tabs-data';
import { LINK_WORKSHOP_MODULES, LINK_WORKSHOP_TEMPLATES } from '../model/consts';
import { IntegratorWorkshopTemplates } from '../../templates/ui/templates';
import { IntegratorModules } from '../../../../../pages/integrator/modules';

export const IntegratorWorkshop = () => {
	const { pathname } = useLocation();

	return (
		<TabsHeaderLayout title="Мастерская" tabsData={workshopTabs}>
			<div className="list-container">
				{
					pathname.includes('/workshop/*')
						? <Navigate to="templates" />
						: null
				}
				{
					pathname.includes('workshop/templates*')
						? <Navigate to="templates" />
						: null
				}
				{
					pathname.includes('workshop/modules/*')
						? <Navigate to="modules" />
						: null
				}
				<Suspense fallback="">
					<Routes>
						<Route path={LINK_WORKSHOP_TEMPLATES} element={<IntegratorWorkshopTemplates />} />
						<Route path={LINK_WORKSHOP_MODULES} element={<IntegratorModules />} />
						{/* <Route path={LINK_WORKSHOP_MODULES} element={<IntegratorModules />} /> */}
					</Routes>
				</Suspense>
			</div>
		</TabsHeaderLayout>
	);
};
