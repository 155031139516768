import React, { useMemo } from 'react';
import css from './search-tag-bar.module.scss';
import SearchInput from '../../../components/ui/searchInput/SearchInput';
import { TagDropdownWrapper } from '../../tag-dropdown-wrapper';
import { CreateTagDropdown } from '../../create-tag-dropdown';
import useCatchEnterClick from '../../../hooks/useCatchEnterClick';
import { useSearchTagDropdownVisibility } from '../libs/useSearchTagDropdownVisibility';
import { ITagData } from '../../../entities/modules/tag/api/types';
import logo from '../../../components/pages/registration/logo/Logo';
import { notificationError } from '../../../components/Notifications/callNotifcation';

interface SearchTagBarProps {
	search: string,
	templateTags: ITagData[],
	tags: ITagData[] | undefined,
	setSearch: (value: string) => void,
	bindTag: (tag: ITagData) => Promise<void>,
	createTag: (value: string) => Promise<ITagData | null>,
}

export const SearchTagBar = ({
	tags,
	search,
	bindTag,
	createTag,
	setSearch,
	templateTags,
}: SearchTagBarProps) => {
	const selectedTags = useMemo(() => [...templateTags.map(el => el.id)], [templateTags]);

	const {
		hideDropDown,
		showDropDown,
		searchBlockRef,
		isDropdownVisible,
	} = useSearchTagDropdownVisibility();

	const handleSearch = (text: string) => {
		if (!isDropdownVisible) showDropDown();

		setSearch(text);
	};

	const onCreateTag = async () => {
		try {
			const res = await createTag(search);
			if (res) {
				await bindTag(res);
			}

			setSearch('');
			hideDropDown();
		} catch (e) {
			notificationError('Произошла ошибка при создании тега', 'Не удалось создать тег');
		}
	};

	const onTagClick = async (tag: ITagData | undefined) => {
		if (tag && !selectedTags.includes(tag.id)) {
			await bindTag(tag);
			hideDropDown();
		}
	};

	useCatchEnterClick(onCreateTag, isDropdownVisible && !!search && !tags?.length);
	useCatchEnterClick(() => onTagClick(tags && tags[0]), isDropdownVisible && !!search && tags?.length === 1);

	return (
		<div ref={searchBlockRef} className={css.dropdown_wrapper}>
			<SearchInput
				placeholder="Поиск"
				searchValue={search}
				onClick={showDropDown}
				onChange={handleSearch}
			/>
			<TagDropdownWrapper isDropdownVisible={isDropdownVisible}>
				<CreateTagDropdown
					data={tags}
					createTag={onCreateTag}
					onTagClick={onTagClick}
					creatingTagName={search}
					selectedTags={selectedTags}
				/>
			</TagDropdownWrapper>
		</div>
	);
};
