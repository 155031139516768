import React, { useEffect, useState } from 'react';
import { NewModal } from '../../new-modal';
import css from './multiple-selection.module.scss';
import useCatchEnterClick from '../../../../hooks/useCatchEnterClick';
import { MainButton } from '../../main-button/main-button';
import { IMultipleItemI, TSelection } from '../model/types';
import { MultipleItemList } from './list';
import NotFound from '../../../pages/features/moduleWindow/editModuleWindow/editBlocks/NotFound';

interface MultipleSelectionProps {
	items: IMultipleItemI[] | undefined,
	isActive: boolean,
	btnText: string,
	isLoading?: boolean,
	notFoundText: string,
	onAdd: (ids: string[]) => void,
	selection: TSelection,
	setIsActive: (value: boolean) => void,
}

export const MultipleSelection = ({
	isActive,
	onAdd,
	items,
	btnText,
	isLoading,
	notFoundText,
	selection,
	setIsActive,
}: MultipleSelectionProps) => {
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	// * При выделении/отмены выделения пользователя
	const handleBinding = (id: string) => {
		if (selectedIds.includes(id)) {
			setSelectedIds([...selectedIds.filter(item => item !== id)]);
		} else if (selection === 'single') {
			setSelectedIds([id]);
		} else {
			setSelectedIds([...selectedIds, id]);
		}
	};

	const add = () => onAdd(selectedIds);
	const checkItemActive = (id: string) => selectedIds.includes(id);

	useCatchEnterClick(add, selectedIds.length > 0);

	useEffect(() => {
		if (!isActive) setSelectedIds([]);
	}, [isActive]);

	return (
		<NewModal active={isActive} setActive={setIsActive}>
			<div className={css.wrapper}>
				{
					items
						? (
							<MultipleItemList
								items={items}
								handleBinding={handleBinding}
								checkItemActive={checkItemActive}
							/>
						)
						: (
							<NotFound
								text={notFoundText}
								style={css.not_found}
							/>
						)
				}
				<MainButton
					type="button"
					disabled={!selectedIds.length || isLoading}
					onClick={add}
					btnStyle="bright"
				>
					{btnText}
				</MainButton>
			</div>
		</NewModal>
	);
};
