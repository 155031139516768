import { notificationError } from '../../../../../components/Notifications/callNotifcation';
import { workshopModuleApi } from '../../../../../entities/workshop-module';
import { useAppDispatch } from '../../../../../shared/libs/redux';

export const useEditModuleCategories = () => {
	const dispatch = useAppDispatch();

	const [update] = workshopModuleApi.useUpdateMutation();

	const editModuleCategories = async (moduleId?: string, category?: number) => {
		try {
			if (moduleId && category) {
				await update({ moduleId, category }).unwrap();

				dispatch(workshopModuleApi.util?.invalidateTags(['workshopModule']));
			}
		} catch (err) {
			notificationError('Произошла ошибка при обновлении модуля...');
		}
	};

	return {
		editModuleCategories,
	};
};
