import ISocketAuthBody from './socket-body/ISocketAuthBody';
import ISocketActionBody from './socket-body/ISocketActionBody';
import SocketMessage from './SocketMessage';
import SocketType from './SocketType';
import { AnyManipulatorCommand } from '../Types';

class SocketMessageFactory {
	public getAuthMessage = (authToken: string, sketchID: string): SocketMessage<ISocketAuthBody> => {
		const body: ISocketAuthBody = {
			authorization: authToken,
			sketch: sketchID,
		};
		return new SocketMessage<ISocketAuthBody>(SocketType.AUTH_TYPE, body);
	};

	public getActionMessage = (commands: AnyManipulatorCommand[]): SocketMessage<ISocketActionBody> => {
		const serializedCommands = commands.map(command => command.serialize());
		const body: ISocketActionBody = {
			commands: serializedCommands,
		};

		return new SocketMessage<ISocketActionBody>(SocketType.ACTION_TYPE, body);
	};
}

export default SocketMessageFactory;
