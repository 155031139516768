import Dependent from '../../utils/dependent/Dependent';
import IGraphic from '../../graphic/IGraphic';
import IComponent from '../../components/IComponent';
import IContextMenu from './upgrade/IContextMenu';
import GraphicType from '../../graphic/GraphicType';
import SketchComponentType from '../../components/SketchComponentType';
import TableComponent from '../../components/table/TableComponent';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import SpatialAreaTree from '../spatial-quadrants/spatial-tree/SpatialAreaTree';
import IDescartesPosition from '../../utils/IDescartesPosition';

export interface IContextMenuHandlerDependencies {
	areaTree: SpatialAreaTree,
	mousePositionObserver: MousePositionObserver,
}

abstract class ContextMenuHandler<Dependencies extends IContextMenuHandlerDependencies>
	extends Dependent<Dependencies> {
	protected activeMenu: IContextMenu | null;

	protected constructor() {
		super();
		this.activeMenu = null;
	}

	public show = () => {
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		const crossGraphic = this.dependencies.areaTree.getHighestCrossGraphic();
		const crossComponent = this.dependencies.areaTree.getHighestCrossComponent();

		this.coordinateMenuForShow(mousePosition, crossGraphic, crossComponent);
	};

	abstract coordinateMenuForShow(mousePosition: IDescartesPosition,
		crossGraphic: IGraphic | null,
		crossComponent: IComponent | null): void
}

export default ContextMenuHandler;
