import BaseUseCases, { IBaseUseCasesDependencies } from '../base/BaseUseCases';
import IMultiPageUseCases from '../IMultiPageUseCases';
import Utils from '../../utils/impl/Utils';
import API from '../../../../api/API';
import Orientation from '../../page/Orientation';
import IGraphic from '../../graphic/IGraphic';
import { notificationError } from '../../../Notifications/callNotifcation';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import PageGraphic from '../../graphic/page/PageGraphic';
import GraphicType from '../../graphic/GraphicType';
import IMutablePagesComponentTree from '../../component-tree/IMutablePagesComponentTree';
import IPageTexture from '../../graphic/page/IPageTexture';

export interface IMultiPageUseCasesDependencies extends IBaseUseCasesDependencies {
	componentTree: IMutablePagesComponentTree,
}

abstract class MultiPageUseCases<Dependencies extends IMultiPageUseCasesDependencies>
	extends BaseUseCases<Dependencies>
	implements IMultiPageUseCases {
	public setPageBackgroundImage = () => {
		const pagesContainer = this.dependencies.componentTree.getPagesContainer();

		// текстуры в качестве резервного копирования
		const textures = this.getPagesTextures();

		this.dependencies.sketchStabilizer.startUserAction();

		pagesContainer.enableEditMode();
		Utils.File.getPictureBase64FromFile((filename: string, base64: string, size: number) => {
			this.dependencies.componentTree.getPages()
				.forEach(page => page.enableBackgroundPictureLoadingMode());
			API.file.create({
				name: filename,
				bytes: base64,
			}, size)
				.then(res => {
					const pages = pagesContainer.getGraphics();
					const pagesCount = pages.length;

					let currentLoaded = 0;

					pages.forEach(page => page.setBackgroundImage(res.id, () => {
						currentLoaded++;
						if (currentLoaded === pagesCount) {
							this.dependencies.spatialTree.sync();
							this.dependencies.sketchStabilizer.stopUserAction();
							this.dependencies.manipulatorInterface.sync();
						}
					}));
				})
				.catch(() => {
					this.dependencies.componentTree.getPages()
						.forEach(page => page.disableBackgroundPictureLoadingMode());
					this.applyPagesTextures(textures);

					this.dependencies.sketchStabilizer.stopUserAction();
				});
		});
	};

	/** Устанавливает цвет фона всем страницам. */
	public setPageBackgroundColor = (color: string) => {
		const pages = this.dependencies.componentTree.getPages();

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			pages.forEach((page) => {
				page.removeBackgroundImage();
				page.setBackgroundColor(color);
			});
		});

		this.dependencies.spatialTree.sync();
	};

	/**
	 * Запускает процесс установки повторяющееся изображение в качестве фона страниц.
	 */
	public setPageRepeatingImage = () => {
		const pagesContainer = this.dependencies.componentTree.getPagesContainer();

		// Сохраняем текстуры в качестве резервного копирования
		const textures = this.getPagesTextures();

		this.dependencies.sketchStabilizer.startUserAction();

		pagesContainer.enableEditMode();
		Utils.File.getPictureBase64FromFile((filename: string, base64: string, size: number) => {
			this.dependencies.componentTree.getPages()
				.forEach(page => page.enableBackgroundPictureLoadingMode());
			API.file.create({
				name: filename,
				bytes: base64,
			}, size)
				.then(res => {
					const pages = pagesContainer.getGraphics();
					pages.forEach(page => page.setRepeatingImage({
						source: res.id,
						size: 140,
					}));
					this.dependencies.sketchStabilizer.stopUserAction();

					this.dependencies.spatialTree.sync();
					this.dependencies.manipulatorInterface.sync();
				})
				.catch(() => {
					this.dependencies.componentTree.getPages()
						.forEach(page => page.disableBackgroundPictureLoadingMode());
					this.applyPagesTextures(textures);

					this.dependencies.sketchStabilizer.stopUserAction();
				});
		});
	};

	/** Устанавливает ориентацию всех страниц. */
	public setPageOrientation = (orientation: Orientation) => {
		const pages = this.dependencies.componentTree.getPages();

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			pages.forEach((page) => {
				page.setOrientation(orientation);

				const isPageHasBackgroundImage = page.hasBackgroundImage();
				if (isPageHasBackgroundImage) {
					page.stretchBackgroundImageToHeight();
				}
			});

			this.stretchToWidthForTables();
		});
	};

	/**
	 * Удаляет текущую страницу в фокусе.
	 * @param pageGraphic Графика удаляемой страницы.
	 */
	public deletePage = (pageGraphic: IGraphic) => {
		const pagesContainer = this.dependencies.componentTree.getRootComponent();
		const pages = pagesContainer.getGraphics();

		if (pages.length === 1) {
			notificationError('Удаление страницы', 'Невозможно удалить последнюю страницу. Пожалуйста, убедитесь, '
				+ 'что в документе есть более одной страницы перед попыткой удаления.');
			return;
		}

		const pageNumber = this.dependencies.componentTree.getPageNumber(pageGraphic);
		if (pageNumber === null) {
			throw new ManipulatorError('page number not found');
		}

		const embeddedGraphics = this.dependencies.componentTree.getPageEmbeddedGraphics(pageNumber);
		this.dependencies.sketchStabilizer.executeUserAction(() => {
			if (embeddedGraphics !== null) {
				embeddedGraphics.forEach(graphic => {
					this.dependencies.componentTree.mutateByRemoveGraphic(graphic);
				});
			}

			this.dependencies.componentTree.mutateByRemoveGraphic(pageGraphic);
		});

		this.dependencies.componentTree.syncPagePanels();
	};

	public appendPageAfter = (pageGraphic: PageGraphic) => {
		const graphicStructure = pageGraphic.getUniqueStructure();
		const pagesContainer = this.dependencies.componentTree.getPagesContainer();
		const pages = pagesContainer.getGraphics();
		const beforeGraphicIndex = pages.indexOf(pageGraphic);
		if (beforeGraphicIndex === undefined) {
			throw new ManipulatorError('graphic not found');
		}

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			this.dependencies.componentTree.executeMutations(tools => {
				const newGraphic = tools.graphicFactory.createGraphic<PageGraphic>(GraphicType.PAGE, pagesContainer);

				newGraphic.setStructure(() => graphicStructure);

				tools.mutator.mutateByAppendGraphicAfter(pagesContainer, pageGraphic, newGraphic);

				// Сдвиг офсета всех компонентов на страницах ниже, так как добавилась новая страница в середину.
				const components = pagesContainer.getComponents();
				if (components === null) {
					return;
				}

				for (let i = 0; i < components.length; i++) {
					const offset = components[i].getOffset();
					if (offset === null) {
						throw new ManipulatorError('offset is null');
					}
					if (offset > beforeGraphicIndex) {
						tools.mutator.mutateByChangeOffset(components[i], offset + 1);
					}
				}
			});
		});

		this.dependencies.componentTree.validateDOMStructure();
		this.dependencies.spatialTree.sync();
		this.dependencies.manipulatorInterface.sync();
		this.dependencies.componentTree.syncPagePanels();
	};

	public setRepeatingImageSize = (value: number) => {
		const pages = this.dependencies.componentTree.getPages();

		this.dependencies.sketchStabilizer.executeUserAction(() => {
			pages.forEach(page => {
				const texture = page.getTexture();
				if (texture.repeatingImage === null) {
					throw new ManipulatorError('repeating image not found');
				}

				texture.repeatingImage.size = value;

				page.setTexture(() => texture);
			});
		});
	};

	/**
	 * Возвращает текстуры всех страниц.
	 */
	private getPagesTextures = (): IPageTexture[] => {
		const textures: IPageTexture[] = [];

		this.dependencies.componentTree.getPages().forEach(page => {
			textures.push(page.getTexture());
		});

		return textures;
	};

	/**
	 * Применяет текстуры на все страницы.
	 * @param textures массив текстур, который необходимо установить
	 */
	private applyPagesTextures = (textures: IPageTexture[]) => {
		this.dependencies.componentTree.getPages().forEach((page, index) => {
			const texture = textures[index];
			page.setTexture((prev: IPageTexture) => ({
				...prev,
				texture,
			}));
		});
	};
}

export default MultiPageUseCases;
