import { useNavigate } from 'react-router-dom';
import React from 'react';
import Loader from 'react-ts-loaders';
import cx from 'classnames';
import { ICustomTemplateData } from '../../../../../../../../entities/templates/custom/model/types';
import { useAppDispatch } from '../../../../../../../../shared/libs/redux';
import { TemplateCard } from '../../../../../../../../widgets/templates/template-card';
import { TeamTemplateContextMenu } from './context-menu/context-menu';
import useContextMenu from '../../../../../../../../hooks/mouse/useContextMenu';
import { setSelectedCustomTemplate } from '../../../../../../../../entities/templates/custom/model/slice';
import { useCustomContextMenuActions } from '../../utils/useCustomContextMenuActions';
import css from './custom-template.module.scss';
import { notificationError } from '../../../../../../../Notifications/callNotifcation';

interface CustomTemplateCardProps {
	template: ICustomTemplateData,
	isLoading: boolean;
	isGlobalLoading: boolean;
	setGlobalLoading: (templateId: string | null) => void;
}

export const CustomTemplate = ({
	template,
	isLoading,
	isGlobalLoading,
	setGlobalLoading,
}: CustomTemplateCardProps) => {
	const { id } = template;

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const {
		onMoveTemplate,
		setIsActionMenuOpened,
		onCopyLink,
		onSendOffer,
		isActionMenuOpened,
	} = useCustomContextMenuActions(id);

	const {
		menuRef,
		menuPosition,
		handleItemClick,
		wrapperRef,
	} = useContextMenu({
		setMenuVisible: setIsActionMenuOpened,
	});

	const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
		dispatch(setSelectedCustomTemplate(template));
		handleItemClick(e);
	};

	// * Перейти на панель шаблона
	const toTemplatePanel = () => {
		if (!isLoading && !isGlobalLoading) {
			setGlobalLoading(id);
			setIsActionMenuOpened(false);
			try {
				navigate(`/collection/template-custom-panel/${id}`);
			} catch (error) {
				notificationError('Возникла ошибка при выборе модуля!');
				console.error('Произошла ошибка: ', error);
			} finally {
				setGlobalLoading(null);
			}
		}
	};

	if	(isLoading) return <Loader className={css.loader} type="ring" color="#d8d7d7" size={80} />;

	return (
		<div
			role="presentation"
			ref={wrapperRef}
			className={cx(css.wrapper, { [css.disabled]: isGlobalLoading })}
			onClick={!isGlobalLoading ? toTemplatePanel : undefined}
			onContextMenu={!isGlobalLoading ? onContextMenu : undefined}
		>
			<TemplateCard
				template={template}
				selected={false}
				isEditTitle={false}
			/>
			<TeamTemplateContextMenu
				menuRef={menuRef}
				onCopyLink={onCopyLink}
				onSendOffer={onSendOffer}
				menuPosition={menuPosition}
				onMoveTemplate={onMoveTemplate}
				isActionMenuOpened={isActionMenuOpened}
				setIsActionMenuOpened={setIsActionMenuOpened}
			/>
		</div>
	);
};
