import SketchComponentType from '../SketchComponentType';
import GroupGraphic from '../../graphic/group/GroupGraphic';
import UniterComponent from '../UniterComponent';

/**
 * Групповой компонент, который служит для объединения других компонентов, в том числе и других групп.
 */
class GroupComponent extends UniterComponent<null, GroupGraphic> {
	public readonly type: SketchComponentType = SketchComponentType.GROUP;

	public getTexture = () => null;
	public getUniqueTexture = () => null;
	public setTexture = (fn: (prev: null) => null) => null;
}

export default GroupComponent;
