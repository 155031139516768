import IComponent from '../../components/IComponent';
import SketchComponentType from '../../components/SketchComponentType';
import PictureComponent from '../../components/picture/PictureComponent';
import IComponentFactory from './IComponentFactory';
import { AnyComponentStructure } from '../../Types';
import ComponentFactory from './ComponentFactory';
import TextGraphic from '../../graphic/text/TextGraphic';
import TextComponent from '../../components/text/TextComponent';
import IMutablePagesComponentTree from '../../component-tree/IMutablePagesComponentTree';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import ComponentFocusObserver from '../../utils/observers/ComponentFocusObserver';
import IComponentUniter from '../../components/IComponentUniter';
import SpatialAreaTree from '../../mechanics/spatial-quadrants/spatial-tree/SpatialAreaTree';
import TableComponent from '../../components/table/TableComponent';
import SketchStructureStabilizer from '../../mechanics/mutation-observer/SketchStructureStabilizer';
import IBaseUseCases from '../../use-cases/base/IBaseUseCases';
import PageStructureObserver from '../../utils/observers/PageStructureObserver';

interface IComponentFactoryDependencies {
	useCases: IBaseUseCases,
	areaTree: SpatialAreaTree,
	componentTree: IMutablePagesComponentTree,
	sketchStabilizer: SketchStructureStabilizer,
	pageStructureObserver: PageStructureObserver,
	componentFocusObserver: ComponentFocusObserver,
}

/**
 * Реализация паттерна фабрики, собирает и настраивает компоненты.
 */
class DynamicComponentFactory extends ComponentFactory<IComponentFactoryDependencies> implements IComponentFactory {
	// eslint-disable-next-line no-useless-constructor
	constructor() {
		super();
	}

	public createComponent = <ComponentType extends IComponent>(
		structure: AnyComponentStructure,
	): ComponentType => {
		const component = this.getClearComponent(structure.type);
		component.setStructure(() => structure);

		if (structure.type === SketchComponentType.PICTURE) {
			const pictureComponent = component as PictureComponent;
			pictureComponent.addPostEnableEditMode(pictureComponent.fixGraphicState);
			pictureComponent.addPostEnableEditMode(this.loadPictureEmptyPictureComponent.bind(this, pictureComponent));
			pictureComponent.addPostEnableEditMode(this.dependencies.areaTree.sync);
		}

		if (structure.type === SketchComponentType.TABLE) {
			const tableComponent = component as TableComponent;
			tableComponent.addPostCellInputListener(this.dependencies.areaTree.sync);
		}

		// if (structure.type === SketchComponentType.TEXT) {
		// 	// TODO Критично. В редких случаях удаляется компонент с текстом. Проверить.
		// 	component
		// 		.addPostDisableEditModeListener(this.removeEmptyTextComponent.bind(this, component as TextComponent));
		// }

		if (component.isUniter) {
			const uniterComponent = component as IComponentUniter;
			uniterComponent.addPostDisableEditModeListener(uniterComponent.syncSingleComponentsEditMode);
			uniterComponent.addPostEnableEditMode(uniterComponent.syncSingleComponentsEditMode);
			uniterComponent.addPostEnableEditMode(this.dependencies.areaTree.sync);
		}

		component.addPostDisableEditModeListener(this.dependencies.pageStructureObserver.sync);

		component.addPostDisableEditModeListener(this.dependencies.sketchStabilizer.forceStopUserAction);

		component.addPostEnableEditMode(this.dependencies.componentFocusObserver.sync);

		return component as ComponentType;
	};

	/**
	 * Проверяет текстовый компонент на наличие пустых токенов.
	 * Если пустой токен найден, компонент помечается к удалению.
	 * Процесс удаления осуществляется через метод removeComponent.
	 * @param component - текстовый компонент, который нужно проверить и, возможно, удалить.
	 */
	private removeEmptyTextComponent = (component: TextComponent) => {
		// Получаем графику компонента
		const graphics = component.getGraphics() as TextGraphic[];

		// Флаг для отслеживания удаления компонента
		let isRemoved = false;

		// Перебираем каждый график в компоненте
		graphics.forEach(graphic => {
			// Если компонент уже помечен к удалению, выходим из цикла
			if (isRemoved) {
				return;
			}
			// Получаем токены содержимого текстуры графика
			const { tokens } = graphic.getTexture().content;
			// Проверяем, содержит ли массив токены
			if (tokens.length === 0) {
				// Если массив пуст, помечаем компонент к удалению
				isRemoved = true;
			} else {
				// Перебираем каждый токен в массиве
				tokens.forEach(token => {
					// Если найден пустой токен, помечаем компонент к удалению
					if (token.value === '') {
						isRemoved = true;
					}
				});
			}
		});
		// Если компонент помечен к удалению, удаляем компонент
		if (isRemoved) {
			this.removeComponent(component);
		}
	};

	/**
	 * Удаляет текстовый компонент из дерева компонентов
	 * выполняя мутации на дереве компонентов, используя инструмент мутатора
	 * для удаления указанного компонента.
	 * @param component - текстовый компонент, который нужно удалить из дерева компонентов.
	 */
	private removeComponent = (component: TextComponent) => {
		// Выполняем мутации на дереве компонентов для удаления указанного компонента
		this.dependencies.componentTree.executeMutations(tools => {
			tools.mutator
				.mutateByRemoveComponent(component);
		});
	};

	/**
	 * Запускает загрузку изображения при пустом компоненте изображения.
	 * @param component Компонент изображения, по которому будет принято решение об обновлении изображения.
	 */
	private loadPictureEmptyPictureComponent = (component: PictureComponent) => {
		const graphics = component.getGraphics() as PictureGraphic[];
		const graphicTextures = graphics.map(graphic => graphic.getTexture());
		const isNotIncludePicture = graphicTextures.some(texture => texture.source === null);

		if (isNotIncludePicture) {
			this.dependencies.useCases.loadPictureToFocusComponentWithAdaptFrame();
		}
	};
}

export default DynamicComponentFactory;
