import React, { useState } from 'react';
import cx from 'classnames';
import { ReactComponent as DeleteTeamIcon } from '../../../../shared/images/black-trash.svg';
import { ReactComponent as AddEmployeeIcon } from '../../../../shared/images/black-plus.svg';
import { ReactComponent as MoreIcon } from '../images/more.svg';
import { ActionsWithCardsItem } from '../../../../components/ui/actionsWithCards/TransitionActionsWithCards';
import { HiddenUsers } from '../../hidden-user';
import { Info } from './info';
import css from './card.module.scss';
import { renderCountWord } from '../../../../helpers/renderCountWord';
import { checkUsersRightsInterface } from '../../../../helpers/usersRights';
import { ITeamItem } from '../../../../entities/team/api/types';
import { ILegalEntityItem } from '../../../../entities/legal-entity/api/types';
import AutoCloseActionsCards from '../../../../components/ui/autoclose-actions-cards/autoclose-actions-cards';

interface EntityCardProps {
	deleteText: string,
	onBindingEntity: () => void,
	entity: ITeamItem | ILegalEntityItem,
	onDeleteEntity: (id: string) => void,
	onAddManager?: (() => void) | undefined,
	statusRights: checkUsersRightsInterface,
	onUntieUser: (email: string, id: string) => void,
}

export const EntityCard = ({
	entity,
	deleteText,
	onAddManager,
	onUntieUser,
	statusRights,
	onDeleteEntity,
	onBindingEntity,
}: EntityCardProps) => {
	const {
		accounts,
		name,
		id,
	} = entity;

	const [isOpen, setIsOpen] = useState(false);
	const [isShowDeleteWindow, setIsShowDeleteWindow] = useState(false);

	const handleOpen = () => setIsOpen(!isOpen);
	const onCloseDeleteWindow = () => setIsShowDeleteWindow(false);
	const handleActionsWindow = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setIsShowDeleteWindow(!isShowDeleteWindow);
	};

	const employeeCount = renderCountWord(accounts?.length, ['сотрудник', 'сотрудников', 'сотрудника']);

	const deleteEntity = () => onDeleteEntity(id);
	const untieUser = (email: string) => onUntieUser(email, id);

	const actionsWithCardsItems: ActionsWithCardsItem[] = [
		{
			text: 'Добавить сотрудника',
			smile: <AddEmployeeIcon />,
			onClick: onBindingEntity,
		},
		{
			text: deleteText,
			smile: <DeleteTeamIcon />,
			onClick: deleteEntity,
		},
	];

	return (
		<div>
			<div
				role="presentation"
				className={cx(css.wrapper, isOpen && css.opened)}
				onClick={handleOpen}
			>
				<Info
					isOpen={isOpen}
					name={name}
					employeeCount={employeeCount}
				/>
				{
					statusRights.delete && (
						<div
							className={css.more}
							onClick={handleActionsWindow}
							role="presentation"
						>
							<MoreIcon />
							<AutoCloseActionsCards
								onClose={onCloseDeleteWindow}
								isActive={isShowDeleteWindow}
								items={actionsWithCardsItems}
							/>
						</div>
					)
				}
			</div>
			<HiddenUsers
				users={accounts}
				onAddManager={onAddManager}
				untieUser={untieUser}
				areUsersShown={isOpen}
				onAddUser={onBindingEntity}
				deleteRight={statusRights.delete}
			/>
		</div>
	);
};
