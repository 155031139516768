import ConfirmModal from '../../../../../components/modals/common/confirmModal/ConfirmModal';
import { NewModal } from '../../../../../components/ui/new-modal';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { useDeleteWorkshopTemplate } from '../api/useDeleteWorkshopTemplate';
import { setWorkshopDeleteModalActive } from '../../../../../entities/templates/workshop/template/model/slice';

export const DeleteWorkshopTemplateModal = () => {
	const dispatch = useAppDispatch();

	const id = useAppSelector(state => state.workshopTemplate.activeWorkshopTemplate);
	const isActive = useAppSelector(state => state.workshopTemplate.isDeleteModalActive);

	const handleModal = (value: boolean) => dispatch(setWorkshopDeleteModalActive(value));

	const {
		isLoading,
		onDeleteTemplate,
	} = useDeleteWorkshopTemplate();

	const deleteTemplate = async () => {
		if (id) await onDeleteTemplate([id]);
	};

	return (
		<NewModal
			active={isActive}
			setActive={handleModal}
		>
			<ConfirmModal
				disabled={isLoading}
				isModalActive={isActive}
				onDelete={deleteTemplate}
				onCloseModal={() => handleModal(false)}
				text="Вы действительно хотите удалить шаблон без возможности восстановления?"
			/>
		</NewModal>
	);
};
