import React from 'react';
import TransitionActionsWithCards, {
	ActionsWithCardsItem,
} from '../../../../../../ui/actionsWithCards/TransitionActionsWithCards';
import css from './context-menu.module.scss';
import { ReactComponent as TrashIcon } from '../../../../../../../shared/images/red-trash.svg';
import { ReactComponent as CopyLinkIcon } from '../../../../../../../shared/images/copy-link.svg';
import { ReactComponent as EditIcon } from '../../../../../../../shared/images/edit-icon.svg';

interface WorkshopTemplateContextMenuProps {
	onDelete: () => void,
	onCopyLink: () => void,
	isActionMenuOpened: boolean,
	menuPosition: { x: number; y: number },
	menuRef: React.RefObject<HTMLDivElement>,
	setIsActionMenuOpened: (value: boolean) => void,
	onContextMenuRename: () => void,
}

export const WorkshopTemplateContextMenu = ({
	menuRef,
	onDelete,
	menuPosition,
	onCopyLink,
	isActionMenuOpened,
	setIsActionMenuOpened,
	onContextMenuRename,
}: WorkshopTemplateContextMenuProps) => {
	const onCloseMenu = () => setIsActionMenuOpened(false);

	const actionsWithCardsItems: ActionsWithCardsItem[] = [
		{
			text: 'Скопировать ссылку',
			smile: <CopyLinkIcon />,
			onClick: onCopyLink,
		},
		{
			text: 'Переименовать',
			smile: <EditIcon />,
			onClick: onContextMenuRename,
		},
		{
			text: 'Удалить',
			smile: <TrashIcon />,
			onClick: onDelete,
			cssClassSmile: css.deleteOfferIcon,
			cssClassText: css.deleteOfferText,
		},
	];

	return (
		<div
			ref={menuRef}
			style={{
				position: 'absolute',
				left: menuPosition.x,
				top: menuPosition.y,
				display: 'inline-block',
				zIndex: 100,
			}}
		>
			<TransitionActionsWithCards
				ref={menuRef}
				onClose={onCloseMenu}
				isActive={isActionMenuOpened}
				cssClassMain={css.cards_actions}
				items={actionsWithCardsItems}
			/>
		</div>
	);
};
