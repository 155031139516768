import React, { useEffect } from 'react';
import {
	Navigate, Route, Routes, useNavigate, 
} from 'react-router-dom';
import { useAppSelector } from '../../shared/libs/redux';
import Collection from '../../components/pages/collection/Collection';
import { Admin } from '../../components/pages/admin-panel/admin';
import { PATH_AUTH_SUCCESS } from '../../components/pages/registration/authorization/Authorization';
import PreviewContainer from '../../components/pages/collection/section/template/preview/PreviewContainer';
import { TariffExpired } from '../../components/pages/tariff-expired';
import Integrator from '../../components/pages/integrator-panel/integrator/ui/integrator';
import { ACCOUNT_STATUS } from '../../entities/user/account/api/types';
import {
	WorkshopTemplateConstructorWithRouter,
} from '../../components/SketchManipulators/WorkshopTemplateConstructor/ComponentWorkshopTemplateConstructor';
import { StatusAccess } from '../providers/status-access';
import {
	PrivateTemplateOfferSettings,
} from '../../components/pages/collection/section/template/offer-settings/private-template-offer-settings';
import {
	CustomTemplateOfferSettings,
} from '../../components/pages/collection/section/template/offer-settings/custom-template-offer-settings';
import {
	TeamTemplateOfferSettings,
} from '../../components/pages/collection/section/template/offer-settings/team-template-offer-settings';
import {
	TemplateConstructorWithRouter,
} from '../../components/SketchManipulators/PrivateTemplateConstructor/ComponentPrivateTemplateConstructor';
import {
	ComponentOrganizationModuleConstructorWithRouter,
} from '../../components/SketchManipulators/ModuleConstructor/ComponentOrganizationModuleConstructor';

export const AuthorizedRoutes = () => {
	const navigate = useNavigate();

	const tariff = useAppSelector(state => state.personalAccount.account.tariff);
	useEffect(() => {
		if (tariff === null) navigate('/tariff-expired');
	}, [tariff]);

	return (
		<Routes>
			<Route path="/collection/*" element={<Collection />} />
			<Route path="/admin/*" element={<Admin />} />
			<Route path="/integrator/*" element={<Integrator />} />
			<Route path="/" element={<Navigate to={PATH_AUTH_SUCCESS} />} />
			<Route path="/module/constructor/:id" element={<ComponentOrganizationModuleConstructorWithRouter />} />
			<Route path="/constructor/:id" element={<TemplateConstructorWithRouter />} />

			<Route path="/private-template-present/:id" element={<PrivateTemplateOfferSettings />} />
			<Route path="/team-template-present/:id" element={<TeamTemplateOfferSettings />} />
			<Route path="/custom-template-present/:id" element={<CustomTemplateOfferSettings />} />

			<Route path="/preview/:itemId" element={<PreviewContainer />} />

			<Route path="tariff-expired" element={<TariffExpired />} />
			<Route
				path="/integrator/workshop/template-constructor/:id"
				element={(
					<StatusAccess accessStatus={[ACCOUNT_STATUS.INTEGRATOR, ACCOUNT_STATUS.SERVICE_ADMIN]}>
						<WorkshopTemplateConstructorWithRouter />
					</StatusAccess>
				)}
			/>
			<Route path="*" element={<Navigate to="/collection/template/gallery" />} />
		</Routes>
	);
};
