import { useState } from 'react';
import { WorkshopTemplate } from '../template/WorkshopTemplate';
import { TemplateListWrapper } from '../../../../../../widgets/templates/list-wrapper';
import { IWorkshopTemplate } from '../../../../../../entities/templates/workshop/template/model/types';
import { useIsMounted } from '../../../../../../hooks/lifecycle/useIsMounted';

interface WorkshopTemplatesListProps {
	isLoading: boolean;
	templates: IWorkshopTemplate[];
}

export const WorkshopTemplatesList = ({
	templates,
	isLoading,
}: WorkshopTemplatesListProps) => {
	const [isGlobalLoading, setIsGlobalLoading] = useState(false);
	const isMounted = useIsMounted();

	const setGlobalLoadingState = (id: string | null) => {
		if (isMounted.current) {
			setIsGlobalLoading(!!id);
		}
	};

	return (
		<TemplateListWrapper isDataLoading={isLoading}>
			{
				templates.map(template => (
					<WorkshopTemplate
						key={template?.id}
						template={template}
						isGlobalLoading={isGlobalLoading}
						setGlobalLoading={setGlobalLoadingState}
					/>
				))
			}
		</TemplateListWrapper>
	);
};
