import { IComponentInjectorDependencies } from '../component-injector/ConstructorInjector';
import TextInjector from '../component-injector/text/TextInjector';
import PictureInjector from '../component-injector/picture/PictureInjector';
import TableInjector from '../component-injector/table/TableInjector';
import FigureInjector from '../component-injector/figureInjector/FigureInjector';
import SpatialAreaTree from '../mechanics/spatial-quadrants/spatial-tree/SpatialAreaTree';
import Dependent from '../utils/dependent/Dependent';
import { AnyConstructorInjector } from '../Types';
import TableOrganizer from '../mechanics/component-organizer/TableOrganizer';
import ModuleInjector from '../component-injector/module/ModuleInjector';
import ActionStore from '../mutations/ActionStore';
import ComponentAlignment from '../mechanics/component-alignment/ComponentAlignment';
import SketchStructureStabilizer from '../mechanics/mutation-observer/SketchStructureStabilizer';
import IComponentAlignment from '../mechanics/component-alignment/IComponentAlignment';

interface IInjectorFactoryDependencies extends IComponentInjectorDependencies {
	componentAlignment: IComponentAlignment,
}

/**
 * Фабрика для создания инжекторов компонентов в структуру манипулятора.
 */
class InjectorFactory extends Dependent<IInjectorFactoryDependencies> {
	public getTextInjector = (): TextInjector => {
		const injector = new TextInjector();
		this.configureInjector(injector);
		return injector;
	};

	public getPictureInjector = (): PictureInjector => {
		const injector = new PictureInjector();
		this.configureInjector(injector);
		return injector;
	};

	public getTableInjector = (): TableInjector => {
		const injector = new TableInjector();
		this.configureInjector(injector);
		return injector;
	};

	public getFigureInjector = (): FigureInjector => {
		const injector = new FigureInjector();
		this.configureInjector(injector);
		return injector;
	};

	public getModuleInjector = (): ModuleInjector => {
		const injector = new ModuleInjector();
		injector.addPostInjectListener(component => this.dependencies.componentAlignment.alignExtremeLeft(component));
		this.configureInjector(injector);
		return injector;
	};

	private configureInjector = (injector: AnyConstructorInjector) => {
		injector.connectDependencies(this.dependencies);
		injector.addPostInjectListener(this.dependencies.componentTree.moveComponentToForeground);
		injector.addPostInjectListener(this.dependencies.componentTree.disableFocusExcept);
		injector.injectDependencies();
	};
}

export default InjectorFactory;
