import React, { useMemo } from 'react';
import CreateInput from '../createInput/CreateInput';
import CreateInputArrow from '../createInputArrow/CreateInputArrow';
import css from './createModuleWindow.module.scss';
import { ReactComponent as OpenArrowIcon } from './images/open-arrow.svg';
import { ReactComponent as CloseArrowIcon } from './images/close-arrow.svg';
import { tagAPI } from '../../../../../entities/modules/tag/api/api';
import { workshopCategoryApi } from '../../../../../entities/workshop-category';

interface MainBlockProps {
	showMore: boolean,
	choicedTagId: string[],
	nameInputValue: string,
	choicedCategoryId: string[],
	setShowMore: (value: boolean) => void,
	onAddChoicedTag: (value: string) => void,
	setNameInputValue: (value: string) => void,
	onRemoveChoicedTag: (value: string) => void,
	setChoicedCategoryId: (value: string[]) => void,
}

const MainBlock = ({
	nameInputValue,
	setNameInputValue,
	choicedCategoryId,
	choicedTagId,
	showMore,
	onAddChoicedTag,
	onRemoveChoicedTag,
	setShowMore,
	setChoicedCategoryId,
}: MainBlockProps) => {
	// * При добавлении элемента в список выбранных категорий
	const onAddChoicedCategory = (id: string) => {
		setChoicedCategoryId([id]);
	};

	// * При удалении элемента из списка выбранных категорий
	const onRemoveChoicedCategory = (id: string) => {
		setChoicedCategoryId([]);
	};

	const { data: categoriesData } = workshopCategoryApi.useGetWorkshopCategoriesQuery({
		page: 1,
		limit: 127,
	});

	const { data: tagsData } = tagAPI.useGetAllQuery({
		page: 1,
		limit: 127,
	});

	const preparedCategories = useMemo(
		() => categoriesData?.moduleCategories
			?.map(category => ({ ...category, id: category.id.toString() }))
			?.sort((a, b) => Number(a?.sequenceNumber || 0) - Number(b?.sequenceNumber || 0)),
		[categoriesData],
	);

	const preparedTags = tagsData?.moduleTags?.map(tag => {
		const {
			id,
			name,
		} = tag;
		return {
			id: id.toString(),
			name,
		};
	});

	return (
		<>
			<CreateInput
				text={nameInputValue}
				subTitle="Название модуля"
				onChange={setNameInputValue}
				defaultValue="Название модуля"
				maxLength={128}
			/>
			<CreateInputArrow
				text="Категория"
				inputData={preparedCategories}
				choicedItemsId={choicedCategoryId}
				onAddChoicedItem={onAddChoicedCategory}
				onRemoveChoicedItem={onRemoveChoicedCategory}
			/>
			<CreateInputArrow
				text="Теги"
				inputData={preparedTags}
				choicedItemsId={choicedTagId}
				onAddChoicedItem={onAddChoicedTag}
				onRemoveChoicedItem={onRemoveChoicedTag}
			/>
			<div
				className={css.more}
				onClick={() => setShowMore(!showMore)}
				role="presentation"
			>
				<div className={css.text}>Дополнительно</div>
				{
					showMore
						? <OpenArrowIcon />
						: <CloseArrowIcon />
				}
			</div>
		</>
	);
};

export default MainBlock;
