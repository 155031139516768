import { adminAPI } from '../../../../../../entities/admin/api/api';
import { AdminUserCard } from '../user-card/user-card';
import { IAdminUsersFilterItem } from '../../models/filter-data';
import css from './users-list.module.scss';

interface AdminUsersListProps {
	search: string,
	filter: IAdminUsersFilterItem,
}

export const AdminUsersList = ({
	search,
	filter,
}: AdminUsersListProps) => {
	const { data } = adminAPI.useGetAccountsQuery({
		search,
		page: 1,
		limit: 127,
		filterBy: filter.id,
	});

	return (
		<table className={css.wrapper}>
			<tbody>
				{
					data?.accounts?.map((user, i) => {
						const isLast = i === (data && data.accounts.length - 1) || false;
						const isFirst = i === 0;
						const single = data.accounts.length === 1;

						return (
							<AdminUserCard
								key={user.email}
								data={user}
								styles={`${
									(isLast && css.last_card)
								|| (isFirst && css.first_card)
								|| (single && css.singe)
								}`}
							/>
						);
					})
				}
			</tbody>
		</table>
	);
};
