import { createApi } from '@reduxjs/toolkit/query/react';
import {
	IBindWorkshopTagRequest,
	ICreateWorkshopTagResponse,
	IGetWorkshopTagsRequest,
	IGetWorkshopTagsResponse,
	IUpdateWorkshopTagRequest,
} from '../model/types/types';
import { baseQuery } from '../../../shared/api/api';
import { IUpdateTagRequest } from '../../modules/tag/api/types';

const WORKSHOP_TAGS = '/workshop/module/tag';

export const workshopModuleTagApi = createApi({
	reducerPath: 'workshopModuleTagApi',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['workshopTag'],
	endpoints: builder => ({
		create: builder.mutation<ICreateWorkshopTagResponse, { name: string }>({
			query: (data) => ({
				url: WORKSHOP_TAGS,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['workshopTag'],
		}),
		getWorkshopTags: builder.query<IGetWorkshopTagsResponse, IGetWorkshopTagsRequest>({
			query: (data) => ({
				url: WORKSHOP_TAGS,
				method: 'GET',
				params: data,
			}),
			providesTags: ['workshopTag'],
		}),
		delete: builder.mutation<void, { tagId: number }>({
			query: ({ tagId }) => ({
				url: `${WORKSHOP_TAGS}/${tagId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['workshopTag'],
		}),
		update: builder.mutation<void, IUpdateWorkshopTagRequest>({
			query: ({
				id,
				name,
			}) => ({
				url: `${WORKSHOP_TAGS}/${id}`,
				method: 'PUT',
				body: {
					name,
				},
			}),
			invalidatesTags: ['workshopTag'],
		}),
		// 	getAvailable: builder.query<IGetTagResponse, IGetAvailableTagsRequest>({
		// 		query: ({
		// 					moduleId,
		// 					...data
		// 				}) => ({
		// 			url: `${URI_TAG_AVAILABLE(moduleId)}/available`,
		// 			method: 'GET',
		// 			params: data,
		// 		}),
		// 		providesTags: ['tag'],
		// 	}),
		bind: builder.mutation<void, IBindWorkshopTagRequest>({
			query: ({
				moduleId,
				tags,
			}) => ({
				url: `/workshop/module/${moduleId}/tag`,
				method: 'POST',
				body: {
					tags,
				},
			}),
			invalidatesTags: ['workshopTag'],
		}),
		unbind: builder.mutation<void, {moduleId: string, tags: number[]}>({
			query: ({
				moduleId,
				tags,
			}) => ({
				url: `/workshop/module/${moduleId}/tag`,
				method: 'DELETE',
				body: {
					tags,
				},
			}),
			invalidatesTags: ['workshopTag'],
		}),
	}),
});
