import ElementContainer from '../../utils/ElementContainer';
import HTMLElementName from '../../utils/HTMLElementName';

import IBaseUseCases from '../../use-cases/base/IBaseUseCases';
import SvgCollection from '../../utils/SvgCollection';
import IGraphic from '../../graphic/IGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import HTMLGenerator from '../../utils/HTMLGenerator';
import Utils from '../../utils/impl/Utils';

interface ICreateButtonProps {
	icon: SvgCollection,
	text: string,
	buttonClassName: string,
	onClickListener: VoidFunction,
}

class PictureActionLayer extends ElementContainer<HTMLDivElement> {
	private readonly COMPACT_MODE_CLASS_NAME = 'compact-mode';
	private readonly LAYER_CLASS_NAME = 'picture-action-layer';
	private readonly REPLACE_CLASS_NAME = 'picture-action-layer_replace';
	private readonly EDIT_CLASS_NAME = 'picture-action-layer_edit';

	private readonly replaceButton: HTMLElement;
	private readonly cropButton: HTMLElement;
	private readonly graphic: IGraphic;

	constructor(containerElement: HTMLElement, graphic: IGraphic, useCases: IBaseUseCases) {
		super(HTMLElementName.DIV);
		this.graphic = graphic;

		this.setRootElementClassName(this.LAYER_CLASS_NAME);
		containerElement.append(this.rootElement);

		this.replaceButton = this.createButton({
			icon: SvgCollection.IMG_LOAD,
			buttonClassName: this.REPLACE_CLASS_NAME,
			onClickListener: useCases.loadPictureToFocusComponentWithAdaptPicture,
			text: 'Заменить',
		});

		this.cropButton = this.createButton({
			icon: SvgCollection.CROP_IMAGE,
			buttonClassName: this.EDIT_CLASS_NAME,
			onClickListener: this.onCrop,
			text: 'Редактировать',
		});

		this.rootElement.append(this.replaceButton, this.cropButton);
	}

	public enableCompactMode = () => {
		this.cropButton.classList.add(this.COMPACT_MODE_CLASS_NAME);
		this.rootElement.classList.add(this.COMPACT_MODE_CLASS_NAME);
		this.replaceButton.classList.add(this.COMPACT_MODE_CLASS_NAME);
	};

	public disableCompactMode = () => {
		this.cropButton.classList.remove(this.COMPACT_MODE_CLASS_NAME);
		this.rootElement.classList.add(this.COMPACT_MODE_CLASS_NAME);
		this.replaceButton.classList.remove(this.COMPACT_MODE_CLASS_NAME);
	};

	public enable = () => {
		this.cropButton.style.display = 'flex';
		this.replaceButton.style.display = 'flex';
	};

	public disable = () => {
		this.cropButton.style.display = 'none';
		this.replaceButton.style.display = 'none';
	};

	private createButton({
		icon, buttonClassName, onClickListener, text,
	}: ICreateButtonProps): HTMLElement {
		const container = HTMLGenerator.getDiv();
		container.className = buttonClassName;
		Utils.DOM.injectSVG(container, icon);
		container.addEventListener('click', onClickListener);

		const textElement = HTMLGenerator.getSpan({ text });
		container.append(textElement);

		return container;
	}

	private onCrop = () => {
		const parentComponent = this.graphic.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}
		parentComponent.enableEditMode();
	};
}
export default PictureActionLayer;
