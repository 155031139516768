import type { Tree } from './tree';
import { FontFamily, FontSize, TextAlign } from '../editor/types';

export enum TokenType {
    /** Обычный текстовый фрагмент */
    Text = 'text',

    /** Ссылка на внешний ресурс */
    Link = 'link',

    /**
     * Специальный пользовательский стикер с древних времён OK:
     * #u123456789s#
     */
    UserSticker = 'user_sticker',

    /** Упоминание: @user_name */
    Mention = 'mention',

    /** Команда: /command */
    Command = 'command',

    /** Хэштэг: #hashtag */
    HashTag = 'hashtag',

    /** Символ форматирования Markdown */
    Markdown = 'markdown',

    /** Перенос строки */
    Newline = 'newline',

    /** Параграф */
    Paragraph = 'paragraph',

    /** Красная строка */
    RedLine = 'red_line',
}

export enum TokenFormat {
    None = 0,

    /** Жирный текст */
    Bold = 1 << 0,

    /** Курсивный текст */
    Italic = 1 << 1,

    /** Подчёркнутый текст */
    Underline = 1 << 2, // 4

    /** Перечёркнутый текст */
    Strike = 1 << 3,

    /** Моноширинный текст */
    Monospace = 1 << 4,

    /** Важный текст/заголовок */
    Heading = 1 << 5,

    /** Красный текст */
    Marked = 1 << 6,

    /** Подсвеченный фрагмент текста */
    Highlight = 1 << 7,

    /** Текст ссылки в Markdown: `[label]` */
    LinkLabel = 1 << 8,

    /** Ссылка в Markdown: `(example.com)` */
    Link = 1 << 9,
}

export interface IEmoji {
    /** Начало эмоджи в родительском токене */
    from: number;
    /** Конец эмоджи в родительском токене */
    to: number;
    /**
     * Фактический эмоджи для указанного диапазона.
     * Используется для текстовых эмоджи (алиасов)
     * */
    emoji?: string;
}

/**
 * Интерфейс для сохранения стилей при удалении текста
 */
export interface ITokenStyle {
    fontSize: FontSize,
    fontFamily: FontFamily,
    color: string,
    format: TokenFormat;
    textAlign: TextAlign,
    lineHeight: number,
}

export interface ITokenBase {
    /** Тип токена */
    type: TokenType;
    /** Текстовое содержимое токена */
    value: string;
    fontSize: FontSize,
    fontFamily: FontFamily,
    color: string,
    /** Текущий формат токена */
    format: TokenFormat;
    textAlign: TextAlign,
    lineHeight: number,
}

export interface ITokenText extends ITokenBase {
    type: TokenType.Text;
    /**
     * Признак, указывающий, что при добавлении текста точно на границу текущего
     * и предыдущего токена, текст будет добавлен именно в текущий, а не в
     * предыдущий токен
     */
    sticky: boolean;
}

export interface ITokenLink extends ITokenBase {
    type: TokenType.Link;
    link: string;

    /**
     * Флаг, означающий, что ссылка была автоматически распознана в тексте,
     * а не добавлена пользователем.
     */
    auto: boolean;

    /**
     * Признак, указывающий, что при добавлении текста точно на границу текущего
     * и предыдущего токена, текст будет добавлен именно в текущий, а не в
     * предыдущий токен
     */
    sticky: boolean;
}

export interface ITokenUserSticker extends ITokenBase {
    type: TokenType.UserSticker;
    /** ID стикера */
    stickerId: string;
}

export interface ITokenMention extends ITokenBase {
    type: TokenType.Mention;
    /** Значение упоминания */
    mention: string;
}

export interface ITokenCommand extends ITokenBase {
    type: TokenType.Command;
    /** Команда */
    command: string;
}

export interface ITokenHashTag extends ITokenBase {
    type: TokenType.HashTag;
    /** Значение хэштэга */
    hashtag: string;
}

export interface ITokenMarkdown extends ITokenBase {
    type: TokenType.Markdown;
}

export interface ITokenNewline extends ITokenBase {
    type: TokenType.Newline;
}

export interface ITokenParagraph extends ITokenBase {
    type: TokenType.Paragraph;
}

export interface ITokenRedLine extends ITokenBase {
    type: TokenType.RedLine;
}

export type Token = ITokenText | ITokenLink | ITokenUserSticker | ITokenMention
    | ITokenCommand | ITokenHashTag | ITokenMarkdown | ITokenNewline | ITokenParagraph | ITokenRedLine;

/**
 * Помогает понять какие токены или какая их часть находятся в диапазоне выделения текста.
 */
export interface IMextTokenRange {
    token: Token,
    start: number,
    end: number,
    isFull: boolean,
}

export interface IParserOptions {
    linkProtocols?: string[] | Tree;

    /**
     * Использовать текущий формат парсера при добавлении текстового токена
     * XXX: опция странная, но нужна для html-парсера и md-парсера. Постараться
     * избавиться от неё
     */
    useFormat?: boolean;

    /**
     * Можно передать токен, с которого нужно взять форматирование. Если токен не передан, будет парситься на основе
     * стартового токена.
     */
    prototypeToken?: Token;
}
