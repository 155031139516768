import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITemplateModuleScheme } from '../types/TemplateModuleScheme';

const initialState: ITemplateModuleScheme = {
	onGalleryModuleClick: null,
	onEntityModuleClick: null,

	isTemplateModalActive: false,
	isEditTemplateModuleModalActive: false,

	onCloseModuleModal: null,

	selectedCategoryId: undefined,
	selectedTagsIds: [],
};

export const templateModuleSlice = createSlice({
	name: 'templateModule',
	initialState,
	selectors: {
		getIsTemplateModuleActive: (state) => state.isTemplateModalActive,
		getIsEditTemplateModuleActive: (state) => state.isEditTemplateModuleModalActive,

		getEntityModuleClick: (state) => state.onEntityModuleClick,
		getGalleryModuleClick: (state) => state.onGalleryModuleClick,

		getCloseModuleModal: (state) => state.onCloseModuleModal,

		getSelectedCategoryId: (state) => state.selectedCategoryId,
		getSelectedTagsIds: (state) => state.selectedTagsIds,
	},
	reducers: {
		setCloseModuleModalFunc: (state, action: PayloadAction<() => void>) => {
			state.onCloseModuleModal = action.payload;
		},

		setOnEntityModuleClick: (state, action: PayloadAction<(moduleId: string) => void>) => {
			state.onEntityModuleClick = action.payload;
		},
		setOnGalleryModuleClick: (state, action: PayloadAction<(moduleId: string) => void>) => {
			state.onGalleryModuleClick = action.payload;
		},

		setIsTemplateModuleActive: (state, action: PayloadAction<boolean>) => {
			state.isTemplateModalActive = action.payload;
		},
		setIsEditTemplateModuleActive: (state, action: PayloadAction<boolean>) => {
			state.isEditTemplateModuleModalActive = action.payload;
		},

		setSelectedCategoryId: (state, action: PayloadAction<number | undefined>) => {
			state.selectedCategoryId = action.payload;
		},
		setSelectedTagsIds: (state, action: PayloadAction<number[]>) => {
			state.selectedTagsIds = action.payload;
		},
	},
});

export const {
	actions: templateModuleActions,
	reducer: templateModuleReducer,
	selectors: templateModuleSelectors,
} = templateModuleSlice;
