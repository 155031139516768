import IComponent from '../../components/IComponent';
import SketchComponentType from '../../components/SketchComponentType';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import GroupComponent from '../../components/group/GroupComponent';
import PictureComponent from '../../components/picture/PictureComponent';
import TableComponent from '../../components/table/TableComponent';
import TextComponent from '../../components/text/TextComponent';
import FigureComponent from '../../components/figure/FigureComponent';
import PagesContainer from '../../components/pages-container/PagesContainer';
import ModuleComponent from '../../components/module/ModuleComponent';
import Dependent from '../../utils/dependent/Dependent';
import IComponentFactory from './IComponentFactory';
import { AnyComponentStructure } from '../../Types';

/**
 * Реализация паттерна фабрики, собирает и настраивает компоненты.
 */
abstract class ComponentFactory<Dependencies> extends Dependent<Dependencies> implements IComponentFactory {
	public abstract createComponent<ComponentType extends IComponent>(structure: AnyComponentStructure): ComponentType;

	public getClearComponent = <ComponentType extends IComponent>(type: SketchComponentType): ComponentType => {
		switch (type) {
		case SketchComponentType.PAGES_CONTAINER: {
			return new PagesContainer() as unknown as ComponentType;
		}
		case SketchComponentType.MODULE: {
			return new ModuleComponent() as unknown as ComponentType;
		}
		case SketchComponentType.GROUP: {
			return new GroupComponent() as unknown as ComponentType;
		}
		case SketchComponentType.PICTURE: {
			return new PictureComponent() as unknown as ComponentType;
		}
		case SketchComponentType.TABLE: {
			return new TableComponent() as unknown as ComponentType;
		}
		case SketchComponentType.TEXT: {
			return new TextComponent() as unknown as ComponentType;
		}
		case SketchComponentType.FIGURE: {
			return new FigureComponent() as unknown as ComponentType;
		}
		default: {
			throw new ManipulatorError(`constructor component "${type}" not found`);
		}
		}
	};
}

export default ComponentFactory;
