import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { useDeleteWorkshopTag } from '../../services/delete-tag/useDeleteWorkshopTag';
import { workshopTagSlice } from '../../../../entities/workshop-tag';
import { NewModal } from '../../../../components/ui/new-modal';
import ConfirmModal from '../../../../components/modals/common/confirmModal/ConfirmModal';

export const DeleteWorkshopTagModal = () => {
	const dispatch = useAppDispatch();

	const { onDeleteTag, requestData } = useDeleteWorkshopTag();

	const isActive = useSelector(workshopTagSlice.selectors.getIsDeleteModalActive);

	const handleModal = (value: boolean) => {
		dispatch(workshopTagSlice.actions.setIsDeleteModalActive(value));
	};

	const closeModal = () => handleModal(false);

	return (
		<NewModal
			active={isActive}
			setActive={handleModal}
		>
			<ConfirmModal
				text="Вы действительно хотите удалить тег без возможности восстановления?"
				onDelete={onDeleteTag}
				isModalActive={isActive}
				onCloseModal={closeModal}
				disabled={requestData.isLoading}
			/>
		</NewModal>
	);
};
