import cx from 'classnames';
import React from 'react';
import { ITariff, ITransaction } from '../../../entities/tariff/api/types';
import css from './user-tariff.module.scss';
import { renderCountWord } from '../../../helpers/renderCountWord';
import { tariffMapData } from '../../../entities/tariff/model/data';
import { calculateTariffExpiration } from '../libs/calculate-tariff-expiration';
import { calculateDuration } from '../../../shared/libs/utils/calculate-duration';

interface UserTariffProps {
    tariff: ITariff | null | undefined,
    tariffTransactions: ITransaction[] | undefined,
    children: React.ReactNode,
}

export const UserTariff = ({
	tariff,
	children,
	tariffTransactions,
}: UserTariffProps) => {
	const transactions = tariffTransactions && [...tariffTransactions];

	const today = new Date();

	// * поиск транзакции, которая ближе всего к сегодняшнему дню
	transactions?.sort((a, b) => calculateDuration(
		today,
		new Date(a?.startDate),
	) - calculateDuration(today, new Date(b?.startDate)));

	const remainingDaysStr = tariff
		? calculateTariffExpiration(tariff?.startDate, tariff?.duration)
		: calculateDuration((transactions && new Date(transactions[0].startDate)) 
		|| new Date(), today).toString();

	return (
		<div className={css.wrapper}>
			<div>
				<div className={css.description}>Тарифный план</div>
				<div className={css.tariff_name}>
					{
						tariff?.name
							? tariffMapData.get(tariff?.name)
							: 'Отсутствует'
					}
				</div>
			</div>
			<div>
				{
					tariff?.name && (
						<div className={cx(css.description, css.expiration)}>
							{remainingDaysStr}
							{' '}
							до истечения тарифа
						</div>
					)
				}
				{children}
			</div>
		</div>
	);
};
