import { useState } from 'react';
import { copyBuffer, domainName } from '../../../../../helper';
import { notificationSuccess } from '../../../../Notifications/callNotifcation';
import {
	useDeleteWorkshopTemplate,
} from '../../../../../features/workshop/templates/delete/api/useDeleteWorkshopTemplate';
import {
	setTemplateForRenaming,
} from '../../../../../entities/templates/workshop/template/model/slice';
import { useAppDispatch } from '../../../../../shared/libs/redux';

export const useWorkshopContextMenu = (id: string) => {
	const [isActionMenuOpened, setIsActionMenuOpened] = useState(false);
	const dispatch = useAppDispatch();

	const closeActionMenu = () => setIsActionMenuOpened(false);

	const { onDeleteTemplate } = useDeleteWorkshopTemplate();

	// * Удаление шаблона
	const onDelete = async () => {
		await onDeleteTemplate([id]);
		closeActionMenu();
	};

	const handleMenu = (value: boolean) => setIsActionMenuOpened(value);

	const copyLink = () => {
		copyBuffer(`${domainName}integrator/workshop/templates/${id}`);
		closeActionMenu();
		notificationSuccess('Текст скопирован в буфер обмена!');
	};

	const rename = () => {
		dispatch(setTemplateForRenaming(id));
		closeActionMenu();
	};

	return {
		isActionMenuOpened,
		handleMenu,
		onDelete,
		copyLink,
		rename,
	};
};
