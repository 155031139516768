import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFilterData, { FromFilterData } from '../../../../../hooks/filter/useFilterData';
import { WorkshopTemplatesList } from './list/list';
import { useAppSelector } from '../../../../../shared/libs/redux';
import { EmptyPlugList } from '../../../../../widgets/templates/empty-list';
import { WorkshopTemplateSearchBar } from './search-bar/search-bar';
import { onWorkshopTemplateCreate } from '../../../../../helpers/create-template/onWorkshopTemplateCreate';
import {
	useGetAllWorkshopTemplates,
} from '../../../../../features/workshop/templates/get-all/api/useGetAllWorkshopTemplates';
import {
	DeleteWorkshopTemplateModal,
} from '../../../../../features/workshop/templates/delete/ui/delete-workshop-template-modal';
import {
	PublishWorkshopTemplateModal,
} from '../../../../../features/workshop/templates/publish-to-gallery/ui/publish-workshop-template-modal';
import { SelectWorkshopUserFilterModal } from './select-workshop-user-filter-modal/select-workshop-user-filter-modal';

export const IntegratorWorkshopTemplates = () => {
	const navigate = useNavigate();

	const [isTemplateCreating, setIsTemplateCreating] = useState(false);

	const templates = useAppSelector(state => state.workshopTemplate.templates);

	const {
		sortingData,
		currentFilter,
	} = useFilterData(FromFilterData.TEMPLATE);

	const onTemplateCreate = () => onWorkshopTemplateCreate(navigate, setIsTemplateCreating);

	const { isLoading } = useGetAllWorkshopTemplates(currentFilter.sortBy);

	return (
		<>
			<WorkshopTemplateSearchBar
				sortingData={sortingData}
				isLoading={isTemplateCreating}
				onTemplateCreate={onTemplateCreate}
			/>
			{
				((templates && templates.length) || isLoading)
					? (
						<WorkshopTemplatesList templates={templates} isLoading={isLoading} />
					)
					: (
						<EmptyPlugList
							isCreateBtn
							isNeedProcessing={isTemplateCreating}
							btnText="Создать шаблон"
							onClick={onTemplateCreate}
							title="Создайте первый шаблон"
							description="Используйте конструктор для создания коммерческого предложения"
						/>
					)
			}
			<DeleteWorkshopTemplateModal />
			<PublishWorkshopTemplateModal />
			<SelectWorkshopUserFilterModal />
		</>
	);
};
