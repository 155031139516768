import Dependent from '../../utils/dependent/Dependent';
import TableComponent from '../../components/table/TableComponent';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import TableGraphic from '../../graphic/table/TableGraphic';
import Utils from '../../utils/impl/Utils';
import IGraphicFactory from '../../factories/graphic/IGraphicFactory';
import GraphicType from '../../graphic/GraphicType';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import IMutablePagesComponentTree from '../../component-tree/IMutablePagesComponentTree';
import IDescartesPosition from '../../utils/IDescartesPosition';
import IGraphic from '../../graphic/IGraphic';
import IFrameConfiguration from '../../frame/IFrameConfiguration';
import IComponent from '../../components/IComponent';
import Stack from '../../structures/Stack';
import ITableRowProperty from '../../components/table/ITableRowProperty';
import IMutationTools from '../../component-tree/IMutationTools';
import IPageTexture from '../../graphic/page/IPageTexture';

interface ITableOrganizerDependencies {
	graphicFactory: IGraphicFactory,
	componentTree: IMutablePagesComponentTree,
}

/**
 * Сущность, предоставляющая методы для организации таблицы при многостраничной структуре.
 */
class TableOrganizer extends Dependent<ITableOrganizerDependencies> {
	/**
	 * Перераспределяет строки таблицы в соответствии с положением графики в пространстве.
	 */
	public reorganize = (tableComponent: TableComponent) => {
		this.dependencies.componentTree.executeMutations(_ => {
			setTimeout(tableComponent.syncRealRowHeight.bind(this), 20);
			setTimeout(tableComponent.syncGraphicsHeight.bind(this), 0);

			const rowsProperties = tableComponent.getRowProperties();
			if (rowsProperties.every(prop => prop.height === 0)) {
				return;
			}

			const startRows = this.getStartRows(tableComponent, rowsProperties);

			this.setTableStartRows(tableComponent, startRows, rowsProperties.length);
			this.correctExtendTableGraphicPosition(tableComponent);
		});
	};

	/**
	 * Обновляет ячейки в компоненте.
	 * @param component - компонент таблицы, с которым будет происходить работа.
	 * @param cellTextures - структуры новых ячеек.
	 * @param columns - количество колонок.
	 */
	public updateTableCell = (component: TableComponent, cellTextures: ITableCellTexture[], columns: number) => {
		component.loadCells(cellTextures, columns);
		component.renderCells();
	};

	/**
	 * Сообщает всей графике начальные номера строк для отрисовки.
	 * @param tableComponent - компонент таблица, у которой будут обновлены стартовые номера строк.
	 * @param startRowNumbers - номера начальных строк отрисовки для каждой графики.
	 * @param rowCountAll - общее число строк.
	 */
	private setTableStartRows = (tableComponent: TableComponent, startRowNumbers: number[], rowCountAll: number) => {
		const tableGraphics = tableComponent.getGraphics();
		const currentStartRows = tableGraphics.map(graphic => graphic.getTexture().startRow);
		const currentRowCount = tableComponent.getRowCount();

		const isMatch = Utils.Object.deepEqual(startRowNumbers, currentStartRows);
		if (isMatch && currentRowCount === rowCountAll) {
			return;
		}

		this.dependencies.componentTree.executeMutations(tools => {
			// Если текущее количество графики больше требуемого
			if (tableGraphics.length > startRowNumbers.length) {
				const deleteGraphics = tableGraphics.slice(startRowNumbers.length);
				deleteGraphics.forEach(graphic => this.dependencies.componentTree.mutateByRemoveGraphic(graphic));
			}

			// Если текущего количества графики не хватает, чтобы вместить все ячейки
			if (tableGraphics.length < startRowNumbers.length) {
				const parentComponent = tableComponent.getParentComponent();
				if (parentComponent === null) {
					throw new ManipulatorError('parent component not found');
				}
				const tableComponentOffset = tableComponent.getOffset();
				if (tableComponentOffset === null) {
					throw new ManipulatorError('table component is null');
				}

				for (let graphicIndex = tableGraphics.length; graphicIndex < startRowNumbers.length; graphicIndex++) {
					const startRow = startRowNumbers[graphicIndex];
					if (startRow === undefined) {
						throw new ManipulatorError('start row not found');
					}

					// Найти страницу, на которую будет добавляться графика
					const graphics = tableComponent.getGraphics();
					const prevTableGraphic = graphics[graphics.length - 1];
					const prevGraphicConfiguration = prevTableGraphic.getFrameConfiguration();
					const lastGraphicPageNumber = this.dependencies.componentTree
						.getGraphicPageNumber(prevTableGraphic);
					const page = this.dependencies.componentTree.getPageFromNumber(lastGraphicPageNumber + 1);
					if (page === null) {
						throw new ManipulatorError('page not found');
					}

					const pageTexture = page.getTexture();

					// Найти графику, на которую будет добавляться графика таблицы
					const parentGraphic = this.getParentGraphicToExtendTable(tableComponent, graphicIndex);
					if (parentGraphic === undefined) {
						throw new ManipulatorError('parent graphic not found');
					}

					const extendGraphicPosition = this.getExtendGraphicPosition(
						pageTexture.paddingTop,
						parentGraphic,
						prevTableGraphic,
					);
					const extendTableGraphic = this.getExtendGraphic(
						tableComponent,
						startRow,
						extendGraphicPosition,
						prevGraphicConfiguration,
					);
					tools.mutator.mutateByAppendGraphic(tableComponent, extendTableGraphic);
				}
			}

			const updatedGraphics = tableComponent.getGraphics();
			updatedGraphics.forEach((graphic, index) => {
				const startRow = startRowNumbers[index];
				const nextStartRow = startRowNumbers[index + 1];
				const rowCount = nextStartRow === undefined ? rowCountAll - startRow : nextStartRow - startRow;

				graphic.setRowCount(rowCount);
				graphic.setStartRow(startRow);

				const rowMultipliers = tableComponent.calculateRowMultipliersForGraphic(graphic);
				graphic.setRowsMultipliers(rowMultipliers);
				
				graphic.renderCellLayer();

				graphic.enableFocus();
			});

			tableComponent.syncGraphicsHeight();
		});
	};

	/**
	 * Возвращает созданную добавочную графику таблицы.
	 * @param tableComponent Компонент таблицы.
	 * @param startRow Номер строки, с которой следует начать производить вставку ячеек.
	 * @param extendGraphicPosition Позиция для создаваемой графики.
	 * @param prevGraphicConfiguration Конфигурация фрейма предыдущей графики перед создаваемой.
	 */
	private getExtendGraphic = (
		tableComponent: TableComponent,
		startRow: number,
		extendGraphicPosition: IDescartesPosition,
		prevGraphicConfiguration: IFrameConfiguration,
	): TableGraphic => {
		const graphic = this.dependencies.graphicFactory.createGraphic<TableGraphic>(GraphicType.TABLE, tableComponent);

		const { x, y } = extendGraphicPosition;
		const { width } = prevGraphicConfiguration;
		const borderColor = tableComponent.getBorderColor();
		const columnMultipliers = tableComponent.getColumnMultipliers();

		graphic.setColumnMultipliers(columnMultipliers);
		graphic.setStartRow(startRow);
		graphic.setBorderColor(borderColor);
		graphic.setFrameConfiguration(prev => ({
			...prev,
			x,
			y,
			width,
		}));

		return graphic;
	};

	/**
	 * Возвращает необходимую позицию для новой графики таблицы с учетом того, что любая графика таблицы,
	 * за исключением первой, должна располагаться на верхнем отступе страницы и иметь такую же
	 * координату по горизонтали, как у предыдущей графики.
	 * @param pagePaddingTop Внутренний верхний отступ страницы.
	 * @param parentGraphic Графика, в которую будет вставлена новая графика таблицы.
	 * @param prevGraphic Предыдущая графика таблицы перед вставляемой.
	 */
	private getExtendGraphicPosition = (
		pagePaddingTop: number,
		parentGraphic: IGraphic,
		prevGraphic: IGraphic,
	): IDescartesPosition => {
		const prevGraphicConfiguration = prevGraphic.getFrameConfiguration();

		if (parentGraphic.type === GraphicType.PAGE) {
			return {
				y: pagePaddingTop,
				x: prevGraphicConfiguration.x,
			};
		}

		let currentParentGraphic: IGraphic | null = parentGraphic;
		let currentConfiguration = currentParentGraphic.getFrameConfiguration();

		let topOffsetSum = 0;
		while (currentParentGraphic !== null) {
			topOffsetSum += currentConfiguration.y;

			currentParentGraphic = currentParentGraphic.getParentGraphic();
			if (currentParentGraphic !== null) {
				currentConfiguration = currentParentGraphic.getFrameConfiguration();
			}
		}

		const relativeParentPositionY = topOffsetSum - pagePaddingTop;

		return {
			y: relativeParentPositionY,
			x: prevGraphicConfiguration.x,
		};
	};

	/**
	 * Корректирует позицию дополнительных график таблицы, чтобы они были всегда привязаны
	 * к верхнему отступу листа, учитывая их вложенность в другие компоненты.
	 * @param tableComponent Компонент таблицы, содержащий обрабатываемую графику.
	 */
	private correctExtendTableGraphicPosition = (tableComponent: IComponent) => {
		const graphics = tableComponent.getGraphics();
		const firstGraphic = tableComponent.getFirstGraphic();
		if (firstGraphic === null) {
			throw new ManipulatorError('first graphic not found');
		}
		const relativeFirstGraphicPosition = this.dependencies.componentTree.getRelativePagePosition(firstGraphic);
		const extendGraphics = graphics.slice(1);

		if (extendGraphics.length === 0) {
			return;
		}

		extendGraphics.forEach(graphic => {
			const relativePagePosition = this.dependencies.componentTree.getRelativePagePosition(graphic);
			const pageIndex = this.dependencies.componentTree.getGraphicPageNumber(graphic);
			const page = this.dependencies.componentTree.getPageFromNumber(pageIndex);
			if (page === null) {
				throw new ManipulatorError('page not found');
			}
			const pageTexture = page.getTexture();

			if (relativePagePosition.y !== pageTexture.paddingTop) {
				this.correctVerticalExtendGraphic(graphic, pageTexture);
			}

			if (relativePagePosition.x !== relativeFirstGraphicPosition.x) {
				this.correctHorizontalExtendGraphic(graphic, relativeFirstGraphicPosition.x);
			}
		});
	};

	/**
	 * Корректирует вертикальную позицию графики таблицы по внутреннему отступу страницы.
	 * @param graphic Корректируемая графика таблицы.
	 * @param pageTexture Текстура страницы, от которой идет корректировка позиции.
	 */
	private correctVerticalExtendGraphic = (graphic: IGraphic, pageTexture: IPageTexture) => {
		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic not found');
		}

		const parentRelativePagePosition = this.dependencies.componentTree.getRelativePagePosition(parentGraphic);

		let offset = 0;
		if (parentRelativePagePosition.y > pageTexture.paddingTop) {
			offset = -(parentRelativePagePosition.y - pageTexture.paddingTop);
		} else {
			offset = Math.abs(parentRelativePagePosition.y) + pageTexture.paddingTop;
		}

		graphic.setFrameConfiguration(prev => ({
			...prev,
			y: offset,
		}));
	};

	/**
	 * Корректирует горизонтальною позицию графики таблицы по требуемой относительной страницы позиции.
	 * @param graphic Корректируемая графика таблицы.
	 * @param requiredPosition Требуемая глобальная горизонтальная позиция.
	 */
	private correctHorizontalExtendGraphic = (graphic: IGraphic, requiredPosition: number) => {
		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic not found');
		}

		const parentRelativePagePosition = this.dependencies.componentTree.getRelativePagePosition(parentGraphic);

		let offset = 0;
		if (parentRelativePagePosition.y > requiredPosition) {
			offset = -(parentRelativePagePosition.y - requiredPosition);
		} else {
			offset = Math.abs(parentRelativePagePosition.y) + requiredPosition;
		}

		graphic.setFrameConfiguration(prev => ({
			...prev,
			x: offset,
		}));
	};

	/**
	 * Возвращает родительскую графику для графики таблицы.
	 * @param tableComponent Компонент таблицы.
	 * @param graphicIndex Индекс графики таблицы, для которой необходимо вернуть родительскую графику.
	 * @param mutationTools Инструменты для мутации структуры дерева компонентов.
	 */
	private getParentGraphicToExtendTable = (
		tableComponent: IComponent,
		graphicIndex: number,
	): IGraphic => {
		const tableComponentOffset = tableComponent.getOffset();
		if (tableComponentOffset === null) {
			throw new ManipulatorError('table component not include offset');
		}
		const lastTableGraphic = tableComponent.getLastGraphic();
		if (lastTableGraphic === null) {
			throw new ManipulatorError('last graphic not found');
		}
		const parentComponent = tableComponent.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}

		let parentComponentGraphics = parentComponent.getGraphics();
		let parentGraphic = parentComponentGraphics[tableComponentOffset + graphicIndex];
		if (parentGraphic === undefined) {
			this.generateParentsGraphics(tableComponent, lastTableGraphic);
		}

		parentComponentGraphics = parentComponent.getGraphics();
		parentGraphic = parentComponentGraphics[tableComponentOffset + graphicIndex];

		return parentGraphic;
	};

	/**
	 * Генерирует необходимую графику родительских компонентов таблицы для размещения всей графики таблицы.
	 * @param tableComponent Компонент таблицы, от которой будут генерироваться родительские графики.
	 * @param lastGraphic Последняя графика таблицы.
	 */
	private generateParentsGraphics = (
		tableComponent: IComponent,
		lastGraphic: IGraphic,
	) => {
		const tableComponentOffset = tableComponent.getOffset();
		if (tableComponentOffset === null) {
			throw new ManipulatorError('table component offset not found');
		}

		const forExtendComponents = new Stack<IComponent>();

		let parentComponent = tableComponent.getParentComponent();

		while (parentComponent !== null) {
			forExtendComponents.push(parentComponent);
			parentComponent = parentComponent.getParentComponent();
		}

		const tablePageIndex = this.dependencies.componentTree.getGraphicPageNumber(lastGraphic) + 1;

		let forExtendComponent = forExtendComponents.pop();
		while (forExtendComponent !== undefined) {
			const lastGraphic = forExtendComponent.getLastGraphic();
			if (lastGraphic === null) {
				throw new ManipulatorError('last graphic not found');
			}
			const graphicPageIndex = this.dependencies.componentTree.getGraphicPageNumber(lastGraphic);
			const extendGraphicCount = tablePageIndex - graphicPageIndex;

			for (let i = 0; i < extendGraphicCount; i++) {
				this.dependencies.componentTree.mutateByExtendComponentToEnd(forExtendComponent);
			}

			forExtendComponent = forExtendComponents.pop();
		}
	};

	private getStartRows = (component: IComponent, rowsProperties: ITableRowProperty[]): number[] => {
		const startRows: number[] = [0];

		const tableGraphics = component.getGraphics();
		if (tableGraphics.length === 0) {
			throw new ManipulatorError('table graphics nor found');
		}

		const currentGraphicIndex = 0;
		const currentGraphic = tableGraphics[currentGraphicIndex];
		let currentPageIndex = this.dependencies.componentTree.getGraphicPageNumber(currentGraphic);
		let currentPageGraphic = this.dependencies.componentTree.getPageFromNumber(currentPageIndex);
		if (currentPageGraphic === null) {
			throw new ManipulatorError('first page graphic not found');
		}
		let currentPageTexture = currentPageGraphic.getTexture();

		const currentAvailableSpaceY = currentPageGraphic.getAvailableSpaceY();
		const positionRelativePage = this.dependencies.componentTree.getRelativePagePosition(currentGraphic);
		let currentAvailableSpace = currentAvailableSpaceY
			- (positionRelativePage.y - currentPageTexture.paddingBottom);

		let currentRowSpan = 1;

		rowsProperties.forEach((rowProperty, rowIndex) => {
			if (rowIndex === 0) {
				currentRowSpan = rowProperty.rowSpan;
			}

			if (currentRowSpan === 1) {
				currentAvailableSpace -= rowProperty.height;

				if (currentAvailableSpace < 0) {
					currentPageIndex++;
					currentPageGraphic = this.dependencies.componentTree.getPageFromNumber(currentPageIndex);
					if (currentPageGraphic === null) {
						currentPageGraphic = this.dependencies.componentTree.mutateAddPageToEnd();
					}
					currentPageTexture = currentPageGraphic.getTexture();
					currentAvailableSpace = currentPageGraphic.getAvailableSpaceY();
					currentAvailableSpace -= rowProperty.height + currentPageTexture.paddingBottom;

					startRows.push(rowIndex);
				}
				if (rowIndex + 1 < rowsProperties.length) {
					currentRowSpan = rowsProperties[rowIndex + 1].rowSpan;
				}
			} else if (currentRowSpan > 1) {
				currentRowSpan--;
			}
		});

		return startRows;
	};
}

export default TableOrganizer;
