import ManipulatorInterface from './ManipulatorInterface';
import ComponentInjector from '../component-injector/ComponentInjector';
import PropertiesBar from './bars/properties-bar/PropertiesBar';
import InterfacePanel from './InterfacePanel';
import TopBar from './bars/top-bar/TopBar';
import ToolBar from './bars/tool-bar/ToolBar';
import SketchPanel from './bars/top-bar/sketch-panel/SketchPanel';
import FigurePanel from './bars/top-bar/figure-panel/FigurePanel';
import PicturePanel from './bars/top-bar/picture-panel/PicturePanel';
import ModulesAction from './bars/tool-bar/panelModules/ModulesAction';
import ActionPanel from './bars/tool-bar/action-panel/ActionPanel';
import InjectComponentPanel from './bars/tool-bar/inject-component-panel/InjectComponentPanel';
import ZoomPanel from './bars/tool-bar/panelZoom/ZoomPanel';
import ListsPanel from './bars/tool-bar/panelLists/ListsPanel';
import PagePropertiesPanel from './bars/properties-bar/panel-page-properties/PagePropertiesPanel';
import TextPanel from './bars/properties-bar/panel-text/TextPanel';
import FramePanel from './bars/properties-bar/panel-object/frame/FramePanel';
import LayerPanel from './bars/properties-bar/panel-object/LayerPanel';
import IComponent from '../components/IComponent';
import IComponentContainingText from '../components/IComponentContainingText';
import TablePanel from './bars/properties-bar/table-panel/TablePanel';
import PageTextOverflowPanel from './bars/top-bar/PageTextOverflowPanel';

abstract class DesktopInterface<Dependencies> extends ManipulatorInterface<Dependencies> {
	protected readonly bars: InterfacePanel[];
	protected topBar: TopBar;
	protected toolBar: ToolBar;
	protected propertiesBar: PropertiesBar;

	protected sketchPanel: SketchPanel;
	protected figurePanel: FigurePanel;
	protected picturePanel: PicturePanel;
	protected tablePanel: TablePanel;
	protected pageTextOverflowPanel: PageTextOverflowPanel;

	protected actionPanel: ActionPanel;
	protected injectComponentPanel: InjectComponentPanel;
	protected zoomPanel: ZoomPanel;
	protected listsPanel: ListsPanel;

	protected textPanel: TextPanel;
	protected framePanel: FramePanel;
	protected layerPanel: LayerPanel;
	protected modulesPanel: ModulesAction;
	protected pagePropertiesPanel: PagePropertiesPanel;

	protected constructor() {
		super();
		this.bars = [];
		this.pageTextOverflowPanel = new PageTextOverflowPanel();
	}

	public getElements = (): DocumentFragment => {
		const fragment = document.createDocumentFragment();

		fragment.append(
			this.topBar.getElement(),
			this.toolBar.getElement(),
			this.propertiesBar.getElement(),
		);

		return fragment;
	};

	/**
	 * Приводит в не активное состояние все инструменты.
	 */
	public disableInjectingElements = () => {
		this.injectComponentPanel.disableTools();
	};

	/** Скрывает все панели интерфейса в конструкторе. */
	protected onHide = () => {
		this.bars.forEach(bar => bar.hide());
	};

	/** Показывает все панели интерфейса в конструкторе. */
	protected onShow = () => {
		this.bars.forEach(bar => bar.show());
	};

	/**
	 * Скрывает панели.
	 */
	protected hideAllPanels = () => {
		this.figurePanel.hide();
		this.tablePanel.hide();
		this.textPanel.hide();
		this.picturePanel.hide();
	};

	/**
	 * Возвращает результат проверки компонентов на принадлежность к типу компонентов,
	 * содержащих текст.
	 * @param components - компоненты на проверку.
	 */
	protected isContainingTextComponents = (components: IComponent[]) => components
		.every(component => this.isComponentContainingText(component));

	protected isComponentContainingText = (obj: any)
		: obj is IComponentContainingText => typeof obj.getEditors === 'function';
}

export default DesktopInterface;
