import React from 'react';
import { Title } from '../../../../../../ui/title';
import css from './modal.module.scss';
import { NewModal } from '../../../../../../ui/new-modal';
import { MainButton } from '../../../../../../ui/main-button/main-button';
import { ITemplateData, ITemplateTags } from '../../../../../../../entities/templates/types';
import { ChangeableTagBar } from '../../../../../../../widgets/changeable-tag-bar';
import { TemplateSearchTagBar } from './template-search-tag-bar/template-search-tag-bar';
import { useBindTemplateTag } from '../../../../../../../features/templates/tag/bind/api/useBindTemplateTag';
import { useCreateTemplateTag } from '../../../../../../../features/templates/tag/create/api/useCreateTemplateTag';

interface ChangeTemplateTagsModalProps {
	active: boolean,
	template: ITemplateData | undefined,
	tags: ITemplateTags | undefined,
	unbindTag: (tag: number) => void,
	setIsActive: (value: boolean) => void,
	type: 'TEAM_TEMPLATE' | 'PRIVATE_TEMPLATE',
}

export const ChangeTemplateTagsModal = ({
	type,
	tags,
	active,
	template,
	unbindTag,
	setIsActive,
}: ChangeTemplateTagsModalProps) => {
	const { onCreateTag } = useCreateTemplateTag();
	const bindTag = useBindTemplateTag(type, template?.id || '');

	const bindCreatedTag = async (name: string) => {
		const res = await onCreateTag(name);
		if (res) await bindTag(res);
	};

	return (
		<NewModal active={active} setActive={setIsActive} styles={css.wrapper}>
			<Title styles={css.title}>Изменение тегов шаблона</Title>
			<TemplateSearchTagBar type={type} template={template} onCreateTag={onCreateTag} />
			<ChangeableTagBar
				unbindTag={unbindTag}
				activeTags={tags?.fromTemplates}
				disabledTags={tags?.fromModules}
				onCreateTag={bindCreatedTag}
			/>
			<MainButton styles={css.btn} btnStyle="bright">Сохранить</MainButton>
		</NewModal>
	);
};
