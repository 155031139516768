import { moduleAPI } from '../../../../entities/modules/module/api/api';
import { tagAPI } from '../../../../entities/modules/tag/api/api';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { AnyComponentStructure } from '../../../../components/Sketch/Types';
import { setCreateModuleWindowClose, setWindowModuleOpen } from '../../../../entities/modules/module/model/slice';

export const useCreateModule = (
	preview: string,
	category: string,
	selectedTagsIds: string[],
	selectedLegalEntityId: string[],
	structure: AnyComponentStructure | null,
) => {
	const dispatch = useAppDispatch();

	const [createModule, { isLoading }] = moduleAPI.useCreateMutation();

	const onCreateModule = async (
		name: string,
		description: string,
	) => {
		if (!structure) return;

		try {
			const preparedTags = selectedTagsIds.map(id => Number(id));

			try {
				await createModule({
					name,
					tags: preparedTags,
					description,
					preview,
					rootComponent: structure,
					category: Number(category),
					legalEntities: selectedLegalEntityId,
				})
					.unwrap();
				dispatch(tagAPI.util?.invalidateTags(['tag']));
				dispatch(setCreateModuleWindowClose());
				dispatch(setWindowModuleOpen());
			} catch (err) {
				notificationError(
					ERROR_MESSAGE_LIST.ERROR,
					'Мы уже устраняем эту проблему!',
				);
			}
		} catch (err) {
			notificationError(
				'Что-то пошло не так...',
				'Мы уже устраняем эту проблему!',
			);
		}
	};

	return {
		onCreateModule,
		isLoading,
	};
};
