import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFile } from '../../../shared/api/api';
import { URI_FILE } from './consts';
import { IFileSaveRequest } from './types';

export const fileAPI = createApi({
	reducerPath: 'fileAPI',
	refetchOnReconnect: true,
	baseQuery: baseQueryFile,
	endpoints: builder => ({
		get: builder.query<string, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_FILE}/${id}`,
				method: 'GET',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		}),
		save: builder.mutation<{ id: string }, IFileSaveRequest>({
			query: (data) => ({
				url: URI_FILE,
				method: 'POST',
				body: { ...data },
			}),
		}),
	}),
});
