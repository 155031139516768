import React, { useState } from 'react';
import { Emoji } from 'emoji-picker-react';
import { useDispatch } from 'react-redux';
import css from './createRoleInput.module.scss';
import { ReactComponent as EmptyChoicedRoleIcon } from '../../images/empty-choiced-role.svg';
import ColorexColor from '../../../../../../libs/colorex/colors/ColorexColor';
import Utils from '../../../../../Sketch/utils/impl/Utils';
import ColorexComponent from '../../../../../../libs/colorex/ColorexComponent';
import { setCreateRoleBgColor } from '../../../../../../redux/reducers/usersReducer';
import ToolTipBlock from './ToolTipBlock';
import { HandleInputSizeI, useHandleInputSize } from '../../../../../../hooks/useHandleInputSize';
import { useAppSelector } from '../../../../../../shared/libs/redux';

interface CreateRoleProps {
	showEmojiWindow: () => void,
	createRoleName: string,
	setCreateRoleName: (value: string) => void
}

const CreateRoleInputData: HandleInputSizeI = {
	initialInputWidth: 230,
	fontSize: 18,
};
const CreateRoleInput = ({
	createRoleName,
	showEmojiWindow,
	setCreateRoleName,
}: CreateRoleProps) => {
	const dispatch = useDispatch();

	const choicedEmoji = useAppSelector(state => state.user.choicedEmoji);
	const createRoleBgColor = useAppSelector(state => state.user.createRoleBgColor);

	const [isRoleToolTipActive, setIsRoleToolTipActive] = useState(false);

	const {
		inputRef,
		handleInputChange,
	} = useHandleInputSize(setCreateRoleName, CreateRoleInputData);

	const onEmojiMouseEnter = () => {
		setIsRoleToolTipActive(true);
	};

	const onEmojiMouseLeave = () => {
		setIsRoleToolTipActive(false);
	};

	const setCurrentInputColor = (color: string) => {
		if (color === ColorexColor.White.toString()) {
			return Utils.Color.getAccentColorFromHex(ColorexColor.White.toString());
		}
		return Utils.Color.getAccentColorFromHex(color);
	};

	const handleCreateRoleBgColor = (color: string) => {
		dispatch(setCreateRoleBgColor(color));
	};

	const onActiveInput = () => {
		inputRef.current?.focus();
	};

	return (
		<div
			className={css.wrapper}
			onClick={onActiveInput}
			role="presentation"
		>
			<div
				className={css.emoji}
				onMouseEnter={onEmojiMouseEnter}
				onMouseLeave={onEmojiMouseLeave}
				onClick={showEmojiWindow}
				role="presentation"
			>
				<ToolTipBlock
					isRoleToolTipActive={isRoleToolTipActive}
				/>
				{/* Если выбрано эмодзи, показываем его, иначе показываем иконку по умолчанию */}
				<div className={css.bg}>
					{
						choicedEmoji
							? <Emoji unified={choicedEmoji} size={30} />
							: <EmptyChoicedRoleIcon />
					}
				</div>
			</div>
			<div className={css.input_wrapper}>
				<input
					ref={inputRef}
					className={css.input}
					value={createRoleName}
					onChange={e => handleInputChange(e.target.value)}
					placeholder="Введите название роли"
					type="text"
					maxLength={32}
					style={{
						backgroundColor: createRoleBgColor,
						color: setCurrentInputColor(createRoleBgColor),
					}}
				/>
			</div>
			<ColorexComponent
				defaultColor={ColorexColor.Black}
				onSelectColor={handleCreateRoleBgColor}
				containerClassName={css.colorex}
			/>
		</div>
	);
};

export default CreateRoleInput;
