import ISketchStructure from '../../Sketch/ISketchStructure';
import MultiPageManipulator from '../MultiPageManipulator';
import DynamicGraphicFactory from '../../Sketch/factories/graphic/DynamicGraphicFactory';
import IPagesComponentTree from '../../Sketch/component-tree/IPagesComponentTree';
import DynamicComponentFactory from '../../Sketch/factories/component/DynamicComponentFactory';

/**
 * Манипулятор компонентов, предназначенный только изменения структуры предложения на этапе его создания.
 */
class OfferConstructor
	extends MultiPageManipulator<IPagesComponentTree, DynamicGraphicFactory, DynamicComponentFactory, null, null> {
	constructor(manipulatorContainer: HTMLDivElement, structure: ISketchStructure) {
		super(manipulatorContainer);
		const { id, name, root } = structure;

		this.setID(id);
		this.setName(name);
		this.componentTree.load(root);
	}
}

export default OfferConstructor;
