import { useState } from 'react';
import css from './EditWorkshopModuleCategoriesDropdown.module.scss';
import { classNames } from '../../../../../shared/libs/classNames';
import { TagDropdownWrapper } from '../../../../../widgets/tag-dropdown-wrapper';
import { DropdownItem } from '../../../../../shared/ui';
import { workshopCategoryApi } from '../../../../../entities/workshop-category';
import { useOutSideClick } from '../../../../../hooks/mouse/useNewOutSideClick';
import { ReactComponent as ArrowIcon } from '../../../../../shared/images/arrow-close-black.svg';
import { IWorkshopModule } from '../../../../../entities/workshop-module/model/types/types';
import { useEditModuleCategories } from '../../../model/services/edit-module-categories/useEditModuleCategories';

interface EditWorkshopModuleCategoriesDropdownProps {
	className?: string,
	data?: IWorkshopModule,
}

export const EditWorkshopModuleCategoriesDropdown = ({
	className, data,
}: EditWorkshopModuleCategoriesDropdownProps) => {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const handleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

	const { data: categories } = workshopCategoryApi.useGetWorkshopCategoriesQuery({
		page: 1,
		limit: 127,
	});

	const { editModuleCategories } = useEditModuleCategories();

	const ref = useOutSideClick(() => setIsDropdownVisible(false));

	return (
		<div className={classNames(css.wrapper, {}, [className])} ref={ref}>
			<div role="presentation" className={css.main} onClick={handleDropdown}>
				<span>{data?.module?.category?.name}</span>
				<ArrowIcon />
			</div>
			<TagDropdownWrapper styles={css.dropdown} isDropdownVisible={isDropdownVisible}>
				{
					categories?.moduleCategories.map(category => {
						const categoryData = {
							...category,
							id: category.id.toString(),
						};

						const editModuleCategory = async () => {
							await editModuleCategories(data?.module.id, category.id);
						};

						return (
							<DropdownItem
								key={category.id}
								data={categoryData}
								onClick={editModuleCategory}
							/>
						);
					})
				}
			</TagDropdownWrapper>
		</div>
	);
};
