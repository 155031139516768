import React from 'react';
import OneInputModal from '../../../../../modals/common/oneInputModal/OneInputModal';
import { NewModal } from '../../../../../ui/new-modal';

interface EditTagWindowProps {
	isActive: boolean,
	inputTitle: string,
	maxLength?: number,
	setActive: (value: boolean) => void,
	onEditTag: (
		inputValue: string,
		setInputValue: (value: string) => void,
	) => void,
}

const EditTagWindow: React.FC<EditTagWindowProps> = ({
	isActive,
	setActive,
	onEditTag,
	maxLength,
	inputTitle,
}: EditTagWindowProps) => {
	EditTagWindow.defaultProps = {
		maxLength: 50,
	};

	return (
		(
			<NewModal
				active={isActive}
				setActive={setActive}
			>
				<OneInputModal
					maxLength={maxLength}
					btnText="Изменить"
					onBtnClick={onEditTag}
					title={inputTitle}
					placeholder="Введите новое название"
					isModalActive={isActive}
					onCloseModal={() => setActive(false)}
				/>
			</NewModal>
		)
	);
};

export default EditTagWindow;
