import OneInputModal from '../../../../components/modals/common/oneInputModal/OneInputModal';
import { NewModal } from '../../../../components/ui/new-modal';
import { setIsCreatingCategoryModalActive } from '../../../../redux/reducers/module/categoryReducer';
import { useCreateCategory } from '../api/useCreateCategory';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';

export const CreateCategoryModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useAppSelector(state => state.category.modalsActive.isCreatingCategoryModalActive);

	const handleModal = (value: boolean) => dispatch(setIsCreatingCategoryModalActive(value));

	const {
		isLoading,
		createCategory,
	} = useCreateCategory(() => handleModal(false));

	return (
		<NewModal
			active={isActive}
			setActive={handleModal}
		>
			<OneInputModal
				btnText="Добавить"
				maxLength={255}
				placeholder="Название категории"
				onBtnClick={createCategory}
				onCloseModal={() => handleModal(false)}
				title="Добавление категории"
				isDataLoading={isLoading}
				isModalActive={isActive}
			/>
		</NewModal>
	);
};
