import { Title } from '../../../components/ui/title';
import { TransactionHistoryItem } from './history-item/history-item';
import { ITransaction } from '../../../entities/tariff/api/types';
import css from './transaction-history.module.scss';
import { TableHeader } from '../../../components/pages/permissions/ui/permissions-table/table-header/table-header';

interface TransactionHistoryProps {
	data?: string[];
	transactions: ITransaction[];
}

const defaultData = ['Продолжительность', 'Активация', 'Действие'];

export const TransactionHistory = ({ transactions, data }: TransactionHistoryProps) => (
	<div className={css.wrapper}>
		<Title>История операций</Title>
		<table className={css.table}>
			<TableHeader data={data || defaultData} title="Тариф" />
			<tbody>
				{transactions.map((transaction, i) => {
					const isFirst = i === 0;
					const isLast = i === transactions.length - 1;
					const single = transactions.length === 1;

					return (
						<TransactionHistoryItem
							key={transaction.id}
							styles={`${isFirst && css.first_card} ${isLast && css.last_card} ${single && css.single}`}
							transaction={transaction}
						/>
					);
				})}
			</tbody>
		</table>
	</div>
);
