import IMutablePagesComponentTree from '../../../component-tree/IMutablePagesComponentTree';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IGraphic from '../../../graphic/IGraphic';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import IComponent from '../../../components/IComponent';

abstract class ComponentOrganizerMutation {
	abstract run(): void;
	abstract inspectPostMovePlace(): void;

	constructor(
		protected graphic: IGraphic,
		protected moveOffset: number,
		protected component: IComponent,
		protected componentTree: IMutablePagesComponentTree,
		// eslint-disable-next-line no-empty-function
	) {}

	/**
	 * Проверяет на существование графику, на которую должна быть перенесена целевая графика.
	 * При необходимости расширяет компоненты.
	 */
	protected inspectAvailableGraphicForMove = () => {
		const parentComponent = this.component.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component is null');
		}

		const componentOffset = this.component.getOffset();
		if (componentOffset === null) {
			throw new ManipulatorError('component offset is null', this.component.getStructure());
		}

		const graphics = this.component.getGraphics();
		const parentGraphics = parentComponent.getGraphics();

		let requireAppendGraphicCount = 0;
		if (this.moveOffset !== 0) {
			// Высчитываем количество необходимой родительской графики, для перемещения
			requireAppendGraphicCount = Math.abs(componentOffset + this.moveOffset)
				+ graphics.length - parentGraphics.length;
		}

		if (this.moveOffset > 0) {
			for (let i = 0; i < requireAppendGraphicCount; i++) {
				this.componentTree.mutateByExtendComponentToEnd(parentComponent);
			}
		} else {
			for (let i = 0; i < requireAppendGraphicCount; i++) {
				this.componentTree.mutateByExtendComponentToStart(parentComponent);
			}
		}
	};

	/**
	 * Возвращает новую позицию графика после смены офсета.
	 * @param graphic Перемещаемая графика.
	 * @param postMoveParentGraphicIndex Индекс родительской графики после перемещения графики.
	 */
	protected getPostMovePosition = (graphic: IGraphic, postMoveParentGraphicIndex: number): IDescartesPosition => {
		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic not found');
		}
		const parentComponent = parentGraphic.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}
		const parentGraphics = parentComponent.getGraphics();

		const postMoveParentGraphic = parentGraphics[postMoveParentGraphicIndex];
		if (postMoveParentGraphic === undefined) {
			throw new ManipulatorError('post move parent graphic');
		}
		const parentGlobalPosition = postMoveParentGraphic.getGlobalPosition();
		const graphicGlobalPosition = graphic.getGlobalPosition();

		const updatedPosition: IDescartesPosition = {
			x: 0,
			y: 0,
		};

		if (graphicGlobalPosition.x < parentGlobalPosition.x) {
			updatedPosition.x = -Math.abs(graphicGlobalPosition.x - parentGlobalPosition.x);
		} else {
			updatedPosition.x = Math.abs(graphicGlobalPosition.x - parentGlobalPosition.x);
		}

		if (graphicGlobalPosition.y < parentGlobalPosition.y) {
			updatedPosition.y = -Math.abs(graphicGlobalPosition.y - parentGlobalPosition.y);
		} else {
			updatedPosition.y = Math.abs(graphicGlobalPosition.y - parentGlobalPosition.y);
		}

		return updatedPosition;
	};
}

export default ComponentOrganizerMutation;
