import ManipulatorCommand from '../ManipulatorCommand';
import CommandType from '../CommandType';
import IComponentChangeBody from './IComponentChangeBody';
import IComponent from '../../../components/IComponent';
import { AnyComponentStructure } from '../../../Types';
import SketchComponentType from '../../../components/SketchComponentType';
import TableComponent from '../../../components/table/TableComponent';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IComponentTreeMutator from '../../../component-tree/IComponentTreeMutator';

interface IComponentChangeCommandProps {
	sketchID: string
	component: IComponent,
	structure: AnyComponentStructure,
	componentTree: IComponentTreeMutator,
}

class ComponentChangeCommand extends ManipulatorCommand<IComponentChangeBody> {
	public type: CommandType = CommandType.CHANGE_COMPONENT;

	private readonly sketchID: string;
	private readonly component: IComponent;
	private readonly structure: AnyComponentStructure;
	private readonly componentTree: IComponentTreeMutator;

	constructor(props: IComponentChangeCommandProps) {
		super();
		this.sketchID = props.sketchID;
		this.component = props.component;
		this.structure = { ...props.structure };
		this.componentTree = props.componentTree;
	}

	public exec = (): void => {
		this.component.setStructure(_ => this.structure);

		if (this.component.type === SketchComponentType.TABLE) {
			(this.component as TableComponent).renderCells();
		}

		let graphics = this.component.getGraphics();
		const graphicStructures = this.structure.graphics;
		if (graphicStructures === null) {
			throw new ManipulatorError('graphics not found');
		}
		const graphicCount = graphics.length;
		const structureCount = graphicStructures.length;
		if (graphicCount !== structureCount) {
			const diffGraphicCount = Math.abs(graphicCount - structureCount);

			if (graphicCount > structureCount) {
				const deletionGraphics = graphics.slice(graphics.length - diffGraphicCount);
				this.componentTree.executeMutations(tools => {
					deletionGraphics.forEach(graphic => {
						tools.mutator.mutateByRemoveGraphic(graphic);
					});
				});
			} else {
				const baseStructure = graphicStructures[0];
				if (baseStructure === undefined) {
					throw new ManipulatorError('base structure not found');
				}

				for (let i = 0; i < diffGraphicCount; i++) {
					this.componentTree.executeMutations(tools => {
						const graphic = tools.graphicFactory.createGraphic(baseStructure.type, this.component);
						graphic.setStructure(() => baseStructure);

						tools.mutator.mutateByAppendGraphic(this.component, graphic);
					});
				}
			}
		}

		graphics = this.component.getGraphics();
		graphics.forEach((graphic, index) => {
			if (this.structure.graphics === null) {
				throw new ManipulatorError('graphics in structure not found');
			}
			const structure = this.structure.graphics[index];
			graphic.setStructure(() => structure);
		});
	};

	protected buildBody = (): IComponentChangeBody => ({
		sketch: this.sketchID,
		structure: { ...this.structure },
	});
}

export default ComponentChangeCommand;
