export const URI_WORKSHOP_TEMPLATE = 'workshop/template';
export const URI_WORKSHOP_TEMPLATE_GALLERY = 'workshop/template/gallery';

export const URI_WORKSHOP_TEMPLATE_TAG = (
	template: string,
	tag: number,
) => `${URI_WORKSHOP_TEMPLATE}/${template}/tag/${tag}`;

export const URI_WORKSHOP_TEMPLATE_DUPLICATE = (
	template: string,
) => `${URI_WORKSHOP_TEMPLATE}/${template}/gallery`;

export const URI_WORKSHOP_INTEGRATE_TEMPLATE = (
	template: string,
	email: string,
) => `${URI_WORKSHOP_TEMPLATE}/${template}/integration/${email}`;
