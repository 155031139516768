import KeyboardObserver from '../../utils/observers/KeyboardObserver';
import PrivateTemplateUseCases from '../../use-cases/impl/PrivateTemplateUseCases';
import KeyCombination from '../../utils/KeyCombination';
import IComponentTree from '../../component-tree/IComponentTree';

class PrivateTemplateKeyboardObserver extends KeyboardObserver {
	constructor(
		private readonly componentTree: IComponentTree,
		private readonly useCases: PrivateTemplateUseCases,
	) {
		super();
		this.subscribeOnKeyUp(KeyCombination.CTRL_G, this.onCtrlG);
		this.subscribeOnKeyUp(KeyCombination.SHIFT_T, useCases.runInjectText);
		this.subscribeOnKeyUp(KeyCombination.SHIFT_K, useCases.runInjectPicture);
		this.subscribeOnKeyUp(KeyCombination.SHIFT_F, useCases.runInjectFigure);
		this.subscribeOnKeyUp(KeyCombination.CTRL_SHIFT_G, useCases.ungroupFocusComponents);
		this.subscribeOnKeyUp(KeyCombination.DELETE, this.onDelete);

		this.subscribeOnKeyUp(KeyCombination.ESCAPE, this.onEscape);

		this.subscribeOnKeyUp(KeyCombination.CTRL_Z, useCases.undo);
		this.subscribeOnKeyUp(KeyCombination.CTRL_SHIFT_Z, useCases.redo);

		this.subscribeOnKeyDown(KeyCombination.CTRL_C, this.onCopy);
		this.subscribeOnKeyDown(KeyCombination.CTRL_V, this.onPaste);

		this.subscribeOnKeyDown(KeyCombination.CTRL_X, this.onCut);
		this.subscribeOnKeyDown(KeyCombination.CTRL, this.onCtrlDown);
		this.subscribeOnKeyUp(KeyCombination.CTRL, this.onCtrlUp);
		this.subscribeOnKeyDown(KeyCombination.SHIFT, this.onShiftDown);
		this.subscribeOnKeyDown(KeyCombination.SHIFT, this.enableMaintainAspectRatioFocusComponents);
		this.subscribeOnKeyUp(KeyCombination.SHIFT, this.onShiftUp);
		this.subscribeOnKeyUp(KeyCombination.SHIFT, this.disableMaintainAspectRatioFocusComponents);
		this.subscribeOnKeyDown(KeyCombination.ALT, this.onAltUp);
		this.subscribeOnKeyUp(KeyCombination.ALT, this.onAltUp);
	}

	private onCtrlG = (ev: Event) => {
		ev.preventDefault();
		this.useCases.groupFocusComponents();
	};

	private onDelete = (ev: Event) => {
		ev.preventDefault();
		this.useCases.removeFocusComponentsFromHotkey();
	};

	private onEscape = () => {
		this.useCases.disableInjectingProcess();
		this.useCases.disableComponentsFocus();
	};

	public onCopy = (ev: Event) => {
		ev.preventDefault();
		this.useCases.copy();
	};

	public onPaste = (ev: Event) => {
		ev.preventDefault();
		this.useCases.pasteFromKeyboardHotkey();
	};

	public onCut = (ev: Event) => {
		ev.preventDefault();
	};

	private enableMaintainAspectRatioFocusComponents = () => {
		const focusComponents = this.componentTree.getFocusComponents();
		if (focusComponents !== null) {
			focusComponents.forEach((component) => {
				component.getGraphics().forEach((graphic) => {
					graphic.enableMaintainAspectRatio();
				});
			});
		}
	};

	private disableMaintainAspectRatioFocusComponents = () => {
		const focusComponents = this.componentTree.getFocusComponents();
		if (focusComponents !== null) {
			focusComponents.forEach((component) => {
				component.getGraphics().forEach((graphic) => {
					graphic.disableMaintainAspectRatio();
				});
			});
		}
	};
}

export default PrivateTemplateKeyboardObserver;
