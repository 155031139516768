import React, {
	useEffect, useMemo, useRef, useState, 
} from 'react';

export const useSlider = (buttonScrollDistance: number, children: React.ReactNode) => {
	const sliderRef = useRef<HTMLDivElement>(null);
	const sliderWrapperRef = useRef<HTMLDivElement>(null);

	const [scrollLeft, setScrollLeft] = useState<number>(0);

	const [controlsVisibility, setControlsVisibility] = useState({
		left: false,
		right: false,
	});

	const animateScroll = (newPosition: number) => {
		if (sliderRef.current) {
			sliderRef.current.style.transition = 'transform 0.5s ease';
			sliderRef.current.style.transform = `translateX(-${newPosition}px)`;

			setScrollLeft(newPosition);
		}
	};

	const childrenLength = useMemo(() => React.Children.toArray(children).length, [children]);

	useEffect(() => {
		const maxScroll = (sliderRef?.current?.scrollWidth || 0) - (sliderWrapperRef.current?.clientWidth || 0);

		if (maxScroll > 1) {
			setControlsVisibility({
				...controlsVisibility,
				right: true,
			});
		}
		if (maxScroll < 0 && sliderRef.current) {
			setControlsVisibility({
				left: false,
				right: false,
			});
			sliderRef.current.style.transform = 'translateX(0px)';
			setScrollLeft(0);
		}
	}, [childrenLength]);

	const handlePrev = () => {
		if (sliderRef.current) {
			const newPosition = scrollLeft - buttonScrollDistance;
			if (newPosition <= 0) {
				setControlsVisibility({
					right: true,
					left: false,
				});
			} else {
				setControlsVisibility({
					right: true,
					left: true,
				});
			}
			animateScroll(newPosition < 0 ? 0 : newPosition);
		}
	};

	const handleNext = () => {
		if (sliderWrapperRef.current && sliderRef.current) {
			const maxScroll = sliderRef.current.scrollWidth - sliderWrapperRef.current.clientWidth;
			const newPosition = scrollLeft + buttonScrollDistance;

			if (newPosition >= maxScroll) {
				setControlsVisibility({
					left: true,
					right: false,
				});
			} else {
				setControlsVisibility({
					left: true,
					right: true,
				});
			}

			animateScroll(newPosition >= maxScroll ? maxScroll + 1 : newPosition);
		}
	};

	return {
		handleNext,
		handlePrev,
		sliderRef,
		sliderWrapperRef,
		controlsVisibility,
	};
};
