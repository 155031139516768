import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import SocketMessageFactory from './SocketMessageFactory';
import IActionSender from '../mutations/IActionSender';
import Utils from '../utils/impl/Utils';
import SocketMessage from './SocketMessage';
import ISocketResponse from './response/ISocketResponse';
import SocketStatus from './response/SocketStatus';
import SocketQueue from './SocketQueue';
import { notificationError } from '../../Notifications/callNotifcation';
import { AnyManipulatorCommand } from '../Types';

/**
 * Реализация отправителя действий пользователя на удаленный сервер на базе сокет-соединения.
 */
class ManipulatorSocket implements IActionSender {
	private readonly CONNECTION_STRING = Utils.Backend.getSocketConnectionString();

	private readonly startSendListeners: VoidFunction[];
	private readonly sendErrorListeners: VoidFunction[];
	private readonly sendSuccessListeners: VoidFunction[];

	private readonly messageFactory: SocketMessageFactory;
	private readonly queueMessages: SocketQueue;
	private readonly sketchID: string;

	private socket: WebSocket;
	private isFirstConnect: boolean;
	private pendingMessageID: string | null;

	constructor(sketchID: string) {
		this.sketchID = sketchID;
		this.isFirstConnect = true;
		this.pendingMessageID = null;
		this.queueMessages = new SocketQueue();
		this.messageFactory = new SocketMessageFactory();

		this.startSendListeners = [];
		this.sendErrorListeners = [];
		this.sendSuccessListeners = [];

		this.connect();
	}

	/**
	 * Упаковывает и отправляет команды пользователя на изменения скетча по сокету.
	 * @param commands - команды на отправку.
	 */
	public sendCommands = (commands: AnyManipulatorCommand[]) => {
		const socketMessage = this.messageFactory.getActionMessage(commands);

		this.queueMessages.enqueue(socketMessage);

		switch (this.socket.readyState) {
		case this.socket.OPEN: {
			this.sendMessages();
			break;
		}
		case this.socket.CLOSED: {
			break;
		}
		case this.socket.CLOSING: {
			break;
		}
		case this.socket.CONNECTING: {
			break;
		}
		default: {
			throw new ManipulatorError('socket state not found');
		}
		}
	};

	public addStartSendListener = (listener: VoidFunction) => {
		this.startSendListeners.push(listener);
	};

	public addSendErrorListener = (listener: VoidFunction) => {
		this.sendErrorListeners.push(listener);
	};

	public addSendSuccessListener = (listener: VoidFunction) => {
		this.sendSuccessListeners.push(listener);
	};

	public destruct = () => {
		this.disconnect();
	};

	/**
	 * Установить соединение с сервером по сокету.
	 */
	private connect = () => {
		this.socket = new WebSocket(this.CONNECTION_STRING);
		this.socket.addEventListener('open', this.onSocketOpen);
		this.socket.addEventListener('error', this.onSocketError);
		this.socket.addEventListener('close', this.onSocketClose);
		this.socket.addEventListener('message', this.onSocketMessage);
	};

	/**
	 * Разорвать соединение с сервером.
	 */
	private disconnect = () => {
		this.socket.removeEventListener('open', this.onSocketOpen);
		this.socket.removeEventListener('error', this.onSocketError);
		this.socket.removeEventListener('close', this.onSocketClose);
		this.socket.removeEventListener('message', this.onSocketMessage);
		this.socket.close();
	};

	private onSocketOpen = () => {
		const authToken = this.getAuthToken();
		this.authConnection(authToken, this.sketchID);

		if (this.isFirstConnect) {
			this.isFirstConnect = false;
		}
	};

	private onSocketError = () => {
		this.callSendErrorListeners();
	};

	private onSocketClose = () => {
		this.connect();
	};

	private onSocketMessage = (event: MessageEvent) => {
		const message = JSON.parse(event.data) as ISocketResponse;
		if (message.id === this.pendingMessageID) {
			this.pendingMessageID = null;
		}

		this.sendMessages();

		if (message.status == SocketStatus.OK) {
			this.callSendSuccessListeners();
		} else {
			this.callSendErrorListeners();
			notificationError('Ошибка сохранения', 'Произошла ошибка при сохранении последнего действия. '
				+ 'Обязательно сообщите команде разработки - какие действия привели к возникновению ошибки, '
				+ 'чтобы она была скорее устранена.', true, 10000);
			throw new ManipulatorError(`message "${message.id}" error`);
		}
	};

	/**
	 * Авторизовывает подключение, тем самым привязывая все изменения к определенному скетчу.
	 * @param authToken - токен авторизации пользователя.
	 * @param sketchId - идентификатор скетча, к которому будут регистрироваться все изменения.
	 */
	private authConnection = (authToken: string, sketchId: string) => {
		const message = this.messageFactory.getAuthMessage(authToken, sketchId);
		this.send(message);
	};

	private getAuthToken = (): string => {
		const token = localStorage.getItem('authorization') !== null
			? localStorage.getItem('authorization')
			: sessionStorage.getItem('authorization');
		if (token === null) {
			throw new ManipulatorError('auth token is empty');
		}
		return token;
	};

	private sendMessages = () => {
		if (this.pendingMessageID !== null) {
			return;
		}
		if (!this.queueMessages.isEmpty()) {
			const message = this.queueMessages.dequeue();
			if (message === undefined) {
				return;
			}

			this.send(message);
		}
	};

	private send = (message: SocketMessage<any>) => {
		this.callStartSendListeners();
		this.pendingMessageID = message.getID();
		this.socket.send(message.getJSON());
	};

	private callStartSendListeners = () => {
		this.startSendListeners.forEach(listener => listener());
	};

	private callSendErrorListeners = () => {
		this.sendErrorListeners.forEach(listener => listener());
	};

	private callSendSuccessListeners = () => {
		this.sendSuccessListeners.forEach(listener => listener());
	};
}

export default ManipulatorSocket;
