import React from 'react';
import TransitionActionsWithCards, {
	ActionsWithCardsItem,
} from '../../../../../../../../ui/actionsWithCards/TransitionActionsWithCards';
import css from './context-menu.module.scss';
import actionsDependsPossible from '../../../../../../../../../helpers/actionsDependsPossible';
import { useAppDispatch } from '../../../../../../../../../shared/libs/redux';
import { ReactComponent as SendKpIcon } from '../../../../../../../../../shared/images/green-send-kp.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../../../shared/images/red-trash.svg';
import { ReactComponent as MoveIcon } from '../../../../../../../../../shared/images/move.svg';
import { ReactComponent as CopyLinkIcon } from '../../../../../../../../../shared/images/copy-link.svg';
import {
	useCleanTemplateSelection,
} from '../../../../../../../../../hooks/template-selection/useCleanTemplateSelection';
import { PossibleItem } from '../../../../my/item/TemplateMyItem';
import { setPrivateTemplateSelectedList } from '../../../../../../../../../entities/templates/private/model/slice';

const TEMPLATE_MY_POSSIBILITIES: PossibleItem = {
	move: true,
	copy: true,
	delete: true,
};

interface PrivateTemplateContextMenuProps {
	onDelete: () => void,
	onCopyLink: () => void,
	onSendOffer: () => void,
	onMoveTemplate: () => void,
	isActionMenuOpened: boolean,
	menuPosition: { x: number; y: number },
	menuRef: React.RefObject<HTMLDivElement>,
	setIsActionMenuOpened: (value: boolean) => void
}

export const PrivateTemplateContextMenu = ({
	menuRef,
	onDelete,
	onCopyLink,
	onSendOffer,
	menuPosition,
	onMoveTemplate,
	isActionMenuOpened,
	setIsActionMenuOpened,
}: PrivateTemplateContextMenuProps) => {
	const dispatch = useAppDispatch();

	const { cleanTemplateSelection } = useCleanTemplateSelection();

	// * При закрытии меню
	const onCloseMenu = () => {
		dispatch(setPrivateTemplateSelectedList([]));
		setIsActionMenuOpened(false);
		cleanTemplateSelection();
	};

	const actionsWithCardsItems: ActionsWithCardsItem[] = [
		{
			text: 'Отправить КП',
			smile: <SendKpIcon />,
			onClick: onSendOffer,
			cssClassSmile: css.sendOfferIcon,
			cssClassText: css.sendOfferText,
		},
		{
			text: 'Переместить в «Шаблоны команды»',
			smile: <MoveIcon />,
			onClick: onMoveTemplate,
		},
		{
			text: 'Скопировать ссылку',
			smile: <CopyLinkIcon />,
			onClick: onCopyLink,
		},
		{
			text: 'Удалить',
			smile: <TrashIcon />,
			onClick: onDelete,
			cssClassSmile: css.deleteOfferIcon,
			cssClassText: css.deleteOfferText,
		},
	];

	return (
		<div
			ref={menuRef}
			style={{
				position: 'absolute',
				left: menuPosition.x,
				top: menuPosition.y,
				display: 'inline-block',
				zIndex: 100,
			}}
		>
			<TransitionActionsWithCards
				ref={menuRef}
				onClose={onCloseMenu}
				isActive={isActionMenuOpened}
				cssClassMain={css.cards_actions}
				items={actionsDependsPossible(TEMPLATE_MY_POSSIBILITIES, actionsWithCardsItems)}
			/>
		</div>
	);
};
