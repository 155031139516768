import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import TestServerNotification from "./components/TestServerNotification";
import { App } from "./app/App";
import { setupListeners } from "@reduxjs/toolkit/query";

export const APP_VERSION = '0.5.1';

setupListeners(store.dispatch);

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<BrowserRouter>
				<TestServerNotification/>
				<App/>
			</BrowserRouter>
		</React.StrictMode>
	</Provider>,
	document.getElementById("root")
);

