import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { ModuleTag } from '../../../../entities/modules/tag/ui/module-tag';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopTagGroupActions, workshopTagGroupSlice } from '../../../../entities/workshop-tag-group';
import { workshopModuleTagApi } from '../../../../entities/workshop-tag';
import { useBindWorkshopTagToGroup } from '../../services/bind-tag-to-group/useBindWorkshopTagToGroup';
import { NewModal } from '../../../../components/ui/new-modal';
import MultipleSelectionBoard from '../../../../components/ui/multipleSelectionBoard/MultipleSelectionBoard';
import css from './BindWorkshopTagGroupModal.module.scss';

export const BindWorkshopTagGroupModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopTagGroupSlice.selectors.getIsBindingGroupModalActive);
	const selectedTags = useSelector(workshopTagGroupSlice.selectors.getBindingTags);

	const handleModal = (value: boolean) => {
		dispatch(workshopTagGroupSlice.actions.setIsBindingGroupModalActive(value));
	};

	const handleSelectedTags = (value: string[]) => {
		const tags = value.map(id => Number(id));

		dispatch(workshopTagGroupActions.setBindingTags(tags));
	};

	const { data: tagsData } = workshopModuleTagApi.useGetWorkshopTagsQuery({
		page: 1,
		limit: 127,
	});

	const { bindTags, requestData } = useBindWorkshopTagToGroup();

	const tagsForSelect = useMemo(() => tagsData?.moduleTags?.map(tag => (
		{
			id: tag.id.toString(),
			elem: <ModuleTag tag={tag} styles={css.tag} />,
		}
	)), [tagsData]);

	const selectedTagsIds = useMemo(() => selectedTags.map(id => id.toString()), [selectedTags]);

	const onBindTags = async () => {
		if (!requestData.isLoading && selectedTagsIds.length) {
			await bindTags();
		}
	};

	return (
		<NewModal active={isActive} setActive={handleModal}>
			<MultipleSelectionBoard
				items={tagsForSelect}
				onBinding={onBindTags}
				bindingIds={selectedTagsIds}
				setBindingIds={handleSelectedTags}
				btnText="Добавить теги в группу"
				notFoundText="Тегов не найдено..."
			/>
		</NewModal>
	);
};
