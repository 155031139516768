import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, providesList } from '../../../../shared/api/api';
import {
	ICreateModuleRequest,
	IGetModuleListResponse,
	IGetModuleResponse,
	IGetModulesListRequest,
	IUpdateModuleRequest,
} from './types';
import { URI_CREATE_WORKSHOP_MODULE, URI_MODULE } from './consts';
import { IModule } from '../model/types';

export const moduleAPI = createApi({
	reducerPath: 'moduleAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['modules'],
	endpoints: builder => ({
		create: builder.mutation<IModule, ICreateModuleRequest>({
			query: (data) => ({
				url: URI_MODULE,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['modules'],
		}),
		getAll: builder.query<IGetModuleListResponse, IGetModulesListRequest>({
			query: (data) => ({
				url: URI_MODULE,
				method: 'GET',
				params: data,
			}),
			providesTags: (result) => providesList(result?.modules, 'modules'),
		}),
		getModule: builder.query<IGetModuleResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_MODULE}/${id}`,
				method: 'GET',
			}),
			providesTags: ['modules'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_MODULE}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['modules'],
		}),
		update: builder.mutation<void, IUpdateModuleRequest>({
			query: ({
				id,
				...data
			}) => ({
				url: `${URI_MODULE}/${id}`,
				method: 'PUT',
				body: { ...data },
			}),
			invalidatesTags: ['modules'],
		}),
	}),
});
