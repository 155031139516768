import BlindZoneDetector from '../../mechanics/spatial-quadrants/area-mutators/BlindZoneDetector';
import SpatialTableCellArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';
import SpatialAreaType from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/SpatialAreaType';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import { AnySpatialArea } from '../../Types';

/**
 * Слушатель выделения ячеек внутри компонента таблицы.
 */
class CellSelectionHandler {
	private readonly blindZoneDetector: BlindZoneDetector;

	private initiatorArea: SpatialTableCellArea | null;
	private isMoveRun: boolean;

	constructor() {
		this.initiatorArea = null;
		this.blindZoneDetector = new BlindZoneDetector();
	}

	public start = (area: SpatialTableCellArea) => {
		this.isMoveRun = true;
		this.initiatorArea = area;
		this.blindZoneDetector.start();
	};

	public move = (offsetX: number, offsetY: number, area: AnySpatialArea | null) => {
		if (!this.isMoveRun || !this.initiatorArea || area === null || area.type !== SpatialAreaType.TABLE_CELL) {
			return;
		}

		const areaData = this.initiatorArea.getData();

		// Если находимся на стартовой ячейке - ничего выделять не надо
		if (areaData.areaCell === area?.getData().cell) {
			return;
		}

		this.blindZoneDetector.appendOffsets({
			width: 0,
			rotate: 0,
			height: 0,
			x: offsetX,
			y: offsetY,
		});

		// Если курсор находится в слепой зоне (когда мы не учитываем перемещения) - то ничего не делаем
		if (!this.blindZoneDetector.isBlindZoneOverstepping()) {
			return;
		}

		// Помечаем ячейку начальной при фокусировании
		areaData.areaCell.markStartFocus();

		// Записать данные текущей ячейки
		const { areaCell, graphic } = (area as SpatialTableCellArea).getData();
		const underCursorColumn = areaCell.getColumn();
		const underCursorRow = areaCell.getRow();

		// Записать данные стартовой ячейки
		const startRow = areaData.areaCell.getRow();
		const startColumn = areaData.areaCell.getColumn();

		// Получить все ячейки таблицы
		const cells = graphic.getCells();

		if (areaData.areaCell === areaCell) {
			for (let i = 0; i < cells.length; i++) {
				if (startRow === cells[i].getRow() && startColumn === cells[i].getColumn()) {
					cells[i].enableFocus();
				} else {
					cells[i].disableFocus();
				}
			}
			return;
		}
		for (let i = 0; i < cells.length; i++) {
			cells[i].disableFocus();
		}

		/* Нужно проверить каждую ячейку отталкиваясь от того, что у нас есть стартовая, текущая ячейка и также
		направление выделения ячеек (вверх, вниз, влево, вправо и без смещения по строке или столбцу). */

		// ВВЕРХ
		if (startRow > underCursorRow) {
			// Без смещения влево или вправо
			if (startColumn === underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow <= startRow
						&& cellRow >= underCursorRow
						&& cellColumn === startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
				return;
			}

			// Влево
			if (startColumn > underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow <= startRow
						&& cellRow >= underCursorRow
						&& cellColumn >= underCursorColumn
						&& cellColumn <= startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
				return;
			}

			// Вправо
			if (startColumn < underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow <= startRow
						&& cellRow >= underCursorRow
						&& cellColumn <= underCursorColumn
						&& cellColumn >= startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
				return;
			}
		}

		// ВНИЗ
		if (startRow < underCursorRow) {
			// Без смещения влево или вправо
			if (startColumn === underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow >= startRow
						&& cellRow <= underCursorRow
						&& cellColumn === startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
				return;
			}

			// Влево
			if (startColumn > underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow >= startRow
						&& cellRow <= underCursorRow
						&& cellColumn >= underCursorColumn
						&& cellColumn <= startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
				return;
			}

			// Вправо
			if (startColumn < underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow >= startRow
						&& cellRow <= underCursorRow
						&& cellColumn <= underCursorColumn
						&& cellColumn >= startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
				return;
			}
		}

		// ВЛЕВО, ВПРАВО без смещения вверх или вниз
		if (startRow === underCursorRow) {
			// Вправо
			if (startColumn < underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow === startRow
						&& cellColumn <= underCursorColumn
						&& cellColumn >= startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
				return;
			}

			// Влево
			if (startColumn > underCursorColumn) {
				cells.forEach((cell) => {
					const cellRow = cell.getRow();
					const cellColumn = cell.getColumn();
					if (
						cellRow === startRow
						&& cellColumn >= underCursorColumn
						&& cellColumn <= startColumn
						&& !cell.hasFocus()
					) {
						cell.enableFocus();
					}
				});
			}
		}
	};

	public stop = () => {
		if (!this.isMoveRun) {
			return;
		}

		this.isMoveRun = false;

		if (this.initiatorArea === null) {
			throw new ManipulatorError('initiator area is null');
		}
		if (!this.blindZoneDetector.isBlindZoneOverstepping()) {
			const areaData = this.initiatorArea.getData();
			const cells = areaData.component.getCells();

			cells.forEach(cell => {
				if (cell === areaData.areaCell) {
					if (!cell.hasFocus()) {
						cell.enableFocus();
						return;
					}
					return;
				}
				cell.disableFocus();
			});
		}

		this.initiatorArea = null;
		this.blindZoneDetector.stop();
	};
}

export default CellSelectionHandler;
