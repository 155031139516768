import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../../shared/libs/classNames';
import css from './CreateWorkshopModuleModal.module.scss';
import Utils from '../../../../../components/Sketch/utils/impl/Utils';
import Button, { buttonsTypes } from '../../../../../components/ui/button/button';
import { SelectModuleCategories } from '../select-categories/SelectModuleCategories';
import { SelectModuleTags } from '../select-tags/SelectModuleTags';
import { SetModuleName } from '../set-name/SetModuleName';
import { SelectModuleEntities } from '../select-entities/SelectModuleEntities';
import { useActionCreators } from '../../../../../shared';
import { NewModal } from '../../../../../components/ui/new-modal';
import { useCreateWorkshopModule } from '../../../model/services/create-module/useCreateWorkshopModule';
import { SetModuleDescription } from '../set-description/SetModuleDescription';
import { ReactComponent as OpenArrowIcon } from '../../../assets/open-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../../../assets/close-arrow.svg';
import {
	createWorkshopModuleActions,
	createWorkshopModuleSlice,
} from '../../../model/create-module/slice/createWorkshopModuleSlice';
import { useAppDispatch } from '../../../../../shared/libs/redux';

interface CreateWorkshopModuleModalProps {
	className?: string,
}

export const CreateWorkshopModuleModal = ({ className }: CreateWorkshopModuleModalProps) => {
	const dispatch = useAppDispatch();

	const isOpen = useSelector(createWorkshopModuleSlice.selectors.getIsWindowOpen);

	const selectedCategories = useSelector(createWorkshopModuleSlice.selectors.getSelectedCategoriesId);
	const name = useSelector(createWorkshopModuleSlice.selectors.getName);
	const preview = useSelector(createWorkshopModuleSlice.selectors.getPreview);
	const selectedTags = useSelector(createWorkshopModuleSlice.selectors.getSelectedTagsIds);
	const selectedLegalEntities = useSelector(createWorkshopModuleSlice.selectors.getSelectedLegalEntitiesIds);
	const isBtnActive = useSelector(createWorkshopModuleSlice.selectors.getIsBtnActive);

	const actions = useActionCreators(createWorkshopModuleActions);

	const selectTag = (id: string) => {
		actions.addSelectedTagId(id);
	};

	const unselectTag = (id: string) => {
		actions.removeSelectedTag(id);
	};

	const selectLegalEntity = (id: string) => {
		actions.addSelectedLegalEntity(id);
	};

	const unselectLegalEntity = (id: string) => {
		actions.removeSelectedLegalEntity(id);
	};

	const handleName = (value: string) => {
		actions.setName(value);
	};

	const selectCategory = (id: string) => {
		actions.setSelectedCategoriesIds([id]);
	};

	const unselectCategory = (id: string) => {
		actions.removeSelectedCategoryId(id);
	};

	const handleWindow = (value: boolean) => {
		actions.setIsWindowOpen(value);
	};

	const { onCreateModule, requestData } = useCreateWorkshopModule();

	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		if (!isOpen) {
			dispatch(createWorkshopModuleActions.cleanStateCreatingData());
		}
	}, [isOpen]);

	return (
		<NewModal active={isOpen} setActive={handleWindow}>
			<div className={classNames(css.createTemplateWrapper, {}, [className])}>
				<img
					src={Utils.Backend.getImageURI(preview)}
					alt="module"
					className={css.preview}
				/>
				<div className={css.wrapper}>
					<div className={css.content}>
						<div className={css.title}>
							Создать модуль
						</div>
						<SetModuleName name={name} handleName={handleName} />
						<SelectModuleCategories
							selectCategory={selectCategory}
							selectedCategories={selectedCategories}
							unselectCategory={unselectCategory}
						/>
						<SelectModuleTags
							selectedTags={selectedTags}
							selectTag={selectTag}
							unselectTag={unselectTag}
						/>
						<div
							className={css.more}
							onClick={() => setShowMore(!showMore)}
							role="presentation"
						>
							<div className={css.text}>Дополнительно</div>
							{
								showMore
									? <OpenArrowIcon />
									: <CloseArrowIcon />
							}
						</div>
						{
							showMore && (
								<>
									<SelectModuleEntities
										selectedLegalEntityId={selectedLegalEntities}
										selectLegalEntity={selectLegalEntity}
										unselectLegalEntity={unselectLegalEntity}
									/>
									<SetModuleDescription />
								</>
							)
						}
						<Button
							text="Создать модуль"
							onClick={onCreateModule}
							buttonType={buttonsTypes.BRIGHT}
							cssClass={css.btn}
							inActive={isBtnActive && !requestData.isLoading}
						/>
					</div>
				</div>
			</div>
		</NewModal>
	);
};
