import GraphicType from '../../graphic/GraphicType';
import IComponent from '../../components/IComponent';
import IGraphic from '../../graphic/IGraphic';
import Dependent from '../../utils/dependent/Dependent';
import IGraphicFactory from './IGraphicFactory';
import PageGraphic from '../../graphic/page/PageGraphic';
import PageHeader from '../../interface/page-header/PageHeader';
import GroupGraphic from '../../graphic/group/GroupGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import TableGraphic from '../../graphic/table/TableGraphic';
import TextGraphic from '../../graphic/text/TextGraphic';
import FigureGraphic from '../../graphic/figure/FigureGraphic';
import ModuleGraphic from '../../graphic/module/ModuleGraphic';
import IMultiPageUseCases from '../../use-cases/IMultiPageUseCases';

/**
 * Фабрика для сборки графики компонентов.
 */
abstract class GraphicFactory<Dependencies> extends Dependent<Dependencies> implements IGraphicFactory {
	protected constructor() {
		super();
	}

	public abstract createGraphic: <GraphicT extends IGraphic>(
		type: GraphicType,
		parentComponent: IComponent,
	) => GraphicT;

	public getClearGraphic = (type: GraphicType): IGraphic => {
		switch (type) {
		case GraphicType.GROUP:
			return new GroupGraphic();
		case GraphicType.PICTURE:
			return new PictureGraphic();
		case GraphicType.TABLE:
			return new TableGraphic();
		case GraphicType.TEXT:
			return new TextGraphic();
		case GraphicType.FIGURE:
			return new FigureGraphic();
		case GraphicType.MODULE:
			return new ModuleGraphic();
		case GraphicType.PAGE:
			return new PageGraphic();
		default: throw new ManipulatorError('error type graphic');
		}
	};

	// protected injectPageHeaders = (pageGraphic: PageGraphic, useCases: IMultiPageUseCases) => {
	// 	const pageHeaderTop = new PageHeader();
	// 	const pageHeaderBottom = new PageHeader();
	//
	// 	pageHeaderTop.connectDependencies({
	// 		pageGraphic,
	// 		useCases,
	// 	});
	// 	pageHeaderTop.injectDependencies();
	// 	pageGraphic.connectTopPageHeader(pageHeaderTop);
	//
	// 	pageHeaderBottom.connectDependencies({
	// 		pageGraphic,
	// 		useCases,
	// 	});
	// 	pageHeaderBottom.injectDependencies();
	// 	pageHeaderBottom.enableLocationBottom();
	// 	pageGraphic.connectBottomPageHeader(pageHeaderBottom);
	// };

	protected injectTopPageHeader = (pageGraphic: PageGraphic, useCases: IMultiPageUseCases) => {
		const pageHeader = new PageHeader();

		pageHeader.connectDependencies({
			pageGraphic,
			useCases,
		});
		pageHeader.injectDependencies();
		pageHeader.show();

		pageGraphic.connectTopPageHeader(pageHeader);
	};

	protected injectBottomPageHeader = (pageGraphic: PageGraphic, useCases: IMultiPageUseCases) => {
		const pageHeader = new PageHeader();

		pageHeader.connectDependencies({
			pageGraphic,
			useCases,
		});
		pageHeader.injectDependencies();
		pageHeader.enableLocationBottom();
		pageHeader.hide();

		pageGraphic.connectBottomPageHeader(pageHeader);
	};
}

export default GraphicFactory;
