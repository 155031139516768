import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import IComponent from '../../components/IComponent';
import ManipulatorError from '../manipulator-error/ManipulatorError';
import API from '../../../../api/API';

class Generate {
	public ComponentPreview = (component: IComponent, callback: (fileID: string) => void) => {
		const firstGraphic = component.getFirstGraphic();
		if (firstGraphic === null) {
			throw new ManipulatorError('first graphic not found');
		}

		const graphicElement = firstGraphic.getFrameElement();
		graphicElement.style.position = 'absolute';
		graphicElement.style.left = '-5000px';
		graphicElement.style.top = '-5000px';

		document.body.append(graphicElement);
		setTimeout(() => {
			html2canvas(graphicElement)
				.then((canvas) => canvas.toDataURL('image/png', 1.0))
				.then((previewBytes) => {
					const blob = new Blob([previewBytes]);
					graphicElement.remove();
					API.file.create({
						name: `preview-${new Date().toISOString()}_${this.UUID4()}`,
						bytes: previewBytes,
					}, blob.size)
						.then(response => {
							callback(response.id);
						})
						.catch(error => {
							throw new ManipulatorError(error.message);
						});
				});
		}, 0);
	};

	public UUID4 = (): string => uuidv4();
}

export default Generate;
