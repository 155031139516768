import { createApi } from '@reduxjs/toolkit/query/react';
import {
	ICreateWorkshopModuleRequest,
	IGetAvailableModuleTagsResponse,
	IGetWorkshopModulesRequest,
	IGetWorkshopModulesResponse,
	IPublishWorkshopModuleToGalleryRequest,
	ISimpleWorkshopModule, IUnpublishWorkshopModuleRequest,
	IUpdateWorkshopModuleRequest,
	IWorkshopModule,
} from '../model/types/types';
import { baseQuery } from '../../../shared/api/api';

export const URI_WORKSHOP_MODULE = '/workshop/module';

export const workshopModuleApi = createApi({
	reducerPath: 'workshopModuleApi',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['workshopModule'],
	endpoints: builder => ({
		createWorkshopModule: builder.mutation<ISimpleWorkshopModule, ICreateWorkshopModuleRequest>({
			query: (data) => ({
				url: URI_WORKSHOP_MODULE,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['workshopModule'],
		}),
		getWorkshopModules: builder.query<IGetWorkshopModulesResponse, IGetWorkshopModulesRequest>({
			query: (data) => ({
				url: URI_WORKSHOP_MODULE,
				method: 'GET',
				params: data,
			}),
			providesTags: ['workshopModule'],
		}),
		getWorkshopModule: builder.query<IWorkshopModule, {moduleId: string}>({
			query: ({ moduleId }) => ({
				url: `${URI_WORKSHOP_MODULE}/${moduleId}`,
				method: 'GET',
			}),
			providesTags: ['workshopModule'],
		}),
		deleteWorkshopModule: builder.mutation<void, { moduleId: string }>({
			query: ({ moduleId }) => ({
				url: `${URI_WORKSHOP_MODULE}/${moduleId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['workshopModule'],
		}),
		getAvailableModuleTags: builder.query<IGetAvailableModuleTagsResponse, {moduleId?: string}>({
			query: ({ moduleId }) => ({
				url: `${URI_WORKSHOP_MODULE}/${moduleId}/tag/available`,
				method: 'GET',
			}),
			providesTags: ['workshopModule'],
		}),
		update: builder.mutation<void, IUpdateWorkshopModuleRequest>({
			query: ({
				moduleId,
				...data
			}) => ({
				url: `${URI_WORKSHOP_MODULE}/${moduleId}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['workshopModule'],
		}),
		publishToGallery: builder.mutation<void, IPublishWorkshopModuleToGalleryRequest>({
			query: ({
				moduleId,
				...data
			}) => ({
				url: `${URI_WORKSHOP_MODULE}/${moduleId}/gallery`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['workshopModule'],
		}),
		unpublishFromGallery: builder.mutation<void, IUnpublishWorkshopModuleRequest>({
			query: (data) => ({
				url: `${URI_WORKSHOP_MODULE}/gallery`,
				method: 'DELETE',
				body: data,
			}),
			invalidatesTags: ['workshopModule'],
		}),
	}),
});
