import { configureStore } from '@reduxjs/toolkit';
import { reducers } from '../reducers/reducer';
import { accountAPI } from '../../entities/user/account/api/api';
import { authAPI } from '../../entities/user/auth/api/api';
import { apis } from '../../shared/api/apis';

const apisData = apis.map(el => el.middleware);

const setupStore = () => configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	})
		.concat(accountAPI.middleware, authAPI.middleware, ...apisData),
});

const store = setupStore();

export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store;
