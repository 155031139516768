import ElementContainer from '../../../utils/ElementContainer';
import HTMLElementName from '../../../utils/HTMLElementName';
import ReplicationIndicatorState from './ReplicationIndicatorState';
import HTMLGenerator from '../../../utils/HTMLGenerator';
import Utils from '../../../utils/impl/Utils';
import SvgCollection from '../../../utils/SvgCollection';

class ReplicationIndicator extends ElementContainer<HTMLElement> {
	private readonly ROOT_ELEMENT_CLASS = 'socket-indicator';

	private readonly errorContent: HTMLElement;
	private readonly processContent: HTMLElement;
	private readonly successContent: HTMLElement;

	private readonly DELAY_MS = 700;

	private currentValue: ReplicationIndicatorState;

	constructor(workAreaElement: HTMLDivElement) {
		super(HTMLElementName.DIV);
		this.setRootElementClassName(this.ROOT_ELEMENT_CLASS);
		workAreaElement.append(this.rootElement);

		this.currentValue = ReplicationIndicatorState.INITIALIZING;

		this.successContent = this.getSuccessContent();
		this.processContent = this.getProcessContent();
		this.errorContent = this.getErrorContent();
	}

	public showReplicationInProcess = () => {
		this.currentValue = ReplicationIndicatorState.IN_PROCESS;
		this.render();
	};

	public showReplicationSuccess = () => {
		this.updateReplicationState(ReplicationIndicatorState.SUCCESS);
	};

	public showReplicationError = () => {
		this.updateReplicationState(ReplicationIndicatorState.ERROR);
	};

	private updateReplicationState = (newState: ReplicationIndicatorState) => {
		if (this.currentValue === ReplicationIndicatorState.IN_PROCESS) {
			setTimeout(() => {
				this.currentValue = newState;
				this.render();
			}, this.DELAY_MS);
		} else {
			this.currentValue = newState;
			this.render();
		}
	};

	private render = () => {
		this.clearElements();
		switch (this.currentValue) {
		case ReplicationIndicatorState.SUCCESS: {
			this.rootElement.append(this.successContent);
			break;
		}
		case ReplicationIndicatorState.IN_PROCESS: {
			this.rootElement.append(this.processContent);
			break;
		}
		case ReplicationIndicatorState.ERROR: {
			this.rootElement.append(this.errorContent);
			break;
		}
		}
	};

	private clearElements = () => {
		this.errorContent.remove();
		this.processContent.remove();
		this.successContent.remove();
	};

	private getSuccessContent = (): HTMLElement => {
		const container = HTMLGenerator.getDiv({
			className: 'socket-indicator_success',
		});
		Utils.DOM.injectSVG(container, SvgCollection.GREEN_SAVED_CIRCLE);
		const textElement = HTMLGenerator.getSpan({
			text: 'Сохранено',
		});
		container.append(textElement);
		return container;
	};

	private getProcessContent = (): HTMLElement => {
		const container = HTMLGenerator.getDiv({
			className: 'socket-indicator_process',
		});
		Utils.DOM.injectSVG(container, SvgCollection.LOADER);
		const textElement = HTMLGenerator.getSpan({
			text: 'Сохраняется',
		});
		container.append(textElement);
		return container;
	};

	private getErrorContent = (): HTMLElement => {
		const container = HTMLGenerator.getDiv({
			className: 'socket-indicator_canceled',
		});
		Utils.DOM.injectSVG(container, SvgCollection.CANCELED);
		const textElement = HTMLGenerator.getSpan({
			text: 'Отменено',
		});
		container.append(textElement);
		return container;
	};
}

export default ReplicationIndicator;
