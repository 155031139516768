import React, { useEffect } from 'react';
import Button, { buttonsTypes } from '../../../ui/button/button';
import css from './cookie.module.scss';
import { setAccountIsCookieAccepted } from '../../../../redux/reducers/personalAccountReducer';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';

const Cookie = () => {
	const isCookieAccepted = useAppSelector(state => state.personalAccount.isCookieAccepted);

	const dispatch = useAppDispatch();
	const isCookie = sessionStorage.getItem('isCookieAccepted') === 'true';

	const setIsCookieAccepted = (isCookie: boolean) => {
		dispatch(setAccountIsCookieAccepted(isCookie));
	};

	const setCookie = () => {
		sessionStorage.setItem('isCookieAccepted', 'true');
		setIsCookieAccepted(true);
	};

	useEffect(() => {
		if (!isCookie) {
			setIsCookieAccepted(false);
		} else {
			setIsCookieAccepted(true);
		}
	}, [isCookie]);

	return (
		!isCookieAccepted
			? (
				<div className={css.wrapper}>
					<img
						className={css.picture}
						src={require('../../../../image/cookie.png')}
						alt=""
					/>
					<div className={css.text}>
						Мы используем куки, чтобы Вам было комфортно пользоваться сервисом
					</div>
					<Button
						onClick={setCookie}
						text="Понятно"
						buttonType={buttonsTypes.BRIGHT}
					/>
				</div>
			)
			: null
	);
};

export default Cookie;
