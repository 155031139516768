import InterfacePanel from '../../../../InterfacePanel';
import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import SvgCollection from '../../../../../utils/SvgCollection';
import IComponent from '../../../../../components/IComponent';
import ButtonsContainer from '../../../../buttons/ButtonsContainer';
import NumberInputField from '../../../../buttons/NumberInputField';
import SquareButton from '../../../../buttons/SquareButton';
import SketchComponentType from '../../../../../components/SketchComponentType';
import IBaseUseCases from '../../../../../use-cases/base/IBaseUseCases';
import BorderRadiusSelector from './BorderRadiusSelector';
import ICornerRadius from '../../../../../graphic/figure/ICornerRadius';
import Utils from '../../../../../utils/impl/Utils';
import IFigureTexture from '../../../../../graphic/figure/IFigureTexture';
import IPictureTexture from '../../../../../graphic/picture/IPictureTexture';
import ManipulatorError from '../../../../../utils/manipulator-error/ManipulatorError';

interface ICreateNumberFieldProps {
	minValue: number,
	maxValue: number,
	icon: SvgCollection,
	inputClassName: string,
	container: ButtonsContainer,
	changeValueListener?: (value: number) => void,
}

interface ICreateButtonProps {
	hint: string,
	icon: SvgCollection,
	buttonClassName: string,
	container: ButtonsContainer,
	onClickListener: VoidFunction,
}

/** Панель, отображающая характеристики фреймов в фокусе. */
class FramePanel extends InterfacePanel {
	private readonly HEADER_TEXT = 'Объекты';
	private readonly CSS_ELEMENT_CLASS_NAME = 'propertyBar__object-panel object-panel';
	private readonly NUMBER_FIELD_CLASS_NAME = 'object-panel__inputs-item';
	private readonly LABEL_CLASSNAME = 'object-panel__label';

	// Элементы ввода значения
	private positionXInput: NumberInputField;
	private positionYInput: NumberInputField;
	private rotateInput: NumberInputField;
	private widthInput: NumberInputField;
	private heightInput: NumberInputField;

	// Функциональные кнопки
	private readonly leftAlignment: SquareButton;
	private readonly horizontalCenterAlignment: SquareButton;
	private readonly rightAlignment: SquareButton;
	private readonly topAlignment: SquareButton;
	private readonly verticalCenterAlignment: SquareButton;
	private readonly alignBottom: SquareButton;
	private readonly expand: SquareButton;
	private readonly reflectVertical: SquareButton;
	private readonly reflectHorizonte: SquareButton;
	private readonly rotateLeft: SquareButton;
	private readonly rotateRight: SquareButton;
	private readonly replacePictureButton: SquareButton;
	private readonly pictureCrop: SquareButton;
	private readonly layout: SquareButton;
	private radiusAnglesButton: SquareButton;
	private readonly cropPictureButton: SquareButton;
	private readonly borderRadiusSelector: BorderRadiusSelector;
	private readonly cornerRadiusContainer: ButtonsContainer;

	private readonly dynamicButtons: SquareButton[] = [];

	constructor(useCases: IBaseUseCases) {
		super();

		this.setRootElementClassName(this.CSS_ELEMENT_CLASS_NAME);
		this.setHeader(this.HEADER_TEXT);

		// const positionInputsContainer = new ButtonsContainer('object-panel__inputs-list');

		// // Добавление поля ввода для X координаты
		// this.positionXInput = this.createNumberInput({
		// 	maxValue: 2000,
		// 	minValue: -2000,
		// 	icon: SvgCollection.x,
		// 	inputClassName: 'object-panel__x',
		// 	container: positionInputsContainer,
		// 	changeValueListener: value => {
		// 		userController.updateFocusComponentConfiguration(prev => ({
		// 			...prev,
		// 			x: value,
		// 		}));
		// 	},
		// });
		//
		// // Добавление поля ввода для Y координаты
		// this.positionYInput = this.createNumberInput({
		// 	maxValue: 2000,
		// 	minValue: -2000,
		// 	icon: SvgCollection.y,
		// 	inputClassName: 'object-panel__y',
		// 	container: positionInputsContainer,
		// 	changeValueListener: value => {
		// 		userController.updateFocusComponentConfiguration(prev => ({
		// 			...prev,
		// 			y: value,
		// 		}));
		// 	},
		// });
		//
		// // Добавление поля ввода для изменения поворота
		// this.rotateInput = this.createNumberInput({
		// 	maxValue: 360,
		// 	minValue: -360,
		// 	icon: SvgCollection.rotate,
		// 	inputClassName: 'object-panel__rotate',
		// 	container: positionInputsContainer,
		// });
		//
		// // Добавление поля ввода для изменения ширины
		// this.widthInput = this.createNumberInput({
		// 	maxValue: 2000,
		// 	minValue: 1,
		// 	icon: SvgCollection.width,
		// 	inputClassName: 'object-panel__width',
		// 	container: positionInputsContainer,
		// 	changeValueListener: value => {
		// 		userController.updateFocusComponentConfiguration(prev => ({
		// 			...prev,
		// 			width: value,
		// 		}));
		// 	},
		// });
		//
		// // Добавление поля ввода для изменения ширины
		// this.heightInput = this.createNumberInput({
		// 	maxValue: 2000,
		// 	minValue: 1,
		// 	icon: SvgCollection.height,
		// 	inputClassName: 'object-panel__height',
		// 	container: positionInputsContainer,
		// 	changeValueListener: value => {
		// 		userController.updateFocusComponentConfiguration(prev => ({
		// 			...prev,
		// 			height: value,
		// 		}));
		// 	},
		// });
		// Добавление поля ввода для изменения ширины
		// this.widthInput = this.createNumberInput({
		// 	maxValue: 2000,
		// 	minValue: 1,
		// 	icon: SvgCollection.width,
		// 	inputClassName: 'object-panel__width',
		// 	container: positionInputsContainer,
		// 	changeValueListener: value => {
		// 		userController.updateFocusComponentConfiguration(prev => ({
		// 			...prev,
		// 			width: value,
		// 		}));
		// 	},
		// });

		// Добавление поля ввода для изменения высоты
		// this.heightInput = this.createNumberInput({
		// 	maxValue: 2000,
		// 	minValue: 1,
		// 	icon: SvgCollection.height,
		// 	inputClassName: 'object-panel__height',
		// 	container: positionInputsContainer,
		// 	changeValueListener: value => {
		// 		useCases.updateFocusComponentConfiguration(prev => ({
		// 			...prev,
		// 			height: value,
		// 		}));
		// 	},
		// });

		const alignmentButtonsContainer = new ButtonsContainer('object-panel__buttons-list');

		// Добавление кнопки заполнения компонентов всей ширины
		this.expand = this.createButton({
			icon: SvgCollection.boom,
			container: alignmentButtonsContainer,
			buttonClassName: 'object-panel__boom',
			hint: 'Расширить компонент по ширине',
			onClickListener: useCases.stretchFocusComponentToWidth,
		});

		// Добавление кнопки обновления изображения в компоненте изображения
		this.replacePictureButton = this.createButton({
			icon: SvgCollection.IMG_LOAD,
			container: alignmentButtonsContainer,
			hint: 'Заменить изображение',
			buttonClassName: 'object-panel__rotate-img-load',
			onClickListener: useCases.loadPictureToFocusComponentWithAdaptPicture,
		});

		// Добавление кнопки для обрезки изображения
		this.cropPictureButton = this.createButton({
			icon: SvgCollection.CROP_IMAGE,
			container: alignmentButtonsContainer,
			hint: 'Обрезать изображение',
			buttonClassName: 'img-panel__crop-btn',
			onClickListener: useCases.resetFocusPictureSize,
		});

		this.cornerRadiusContainer = new ButtonsContainer('object-panel__corner-radius');

		const label = HTMLGenerator.getParagraph({
			className: this.LABEL_CLASSNAME,
			text: 'Радиус углов',
		});
		this.cornerRadiusContainer.getElement().append(label);

		this.borderRadiusSelector = new BorderRadiusSelector({
			fnOnInput: (values: ICornerRadius) => {
				useCases.setFocusComponentsBorderRadius(values);
			},
		});

		this.radiusAnglesButton = this.createButton({
			icon: SvgCollection.CORNER_RADIUS,
			container: this.cornerRadiusContainer,
			buttonClassName: 'object-panel__radius-angles-button',
			hint: 'Изменить радиус углов',
			onClickListener: this.borderRadiusSelector.toggle,
		});
		this.radiusAnglesButton.addClickListener(() => {
			this.borderRadiusSelector.openWindowAtPosition();
		});
		this.radiusAnglesButton.appendShowHintRule(() => !this.borderRadiusSelector.isDropdownShow());
		this.radiusAnglesButton.getElement().append(this.borderRadiusSelector.getElement());

		this.rootElement.append(
			this.cornerRadiusContainer.getElement(),
			alignmentButtonsContainer.getElement(),
		);

		this.collectDynamicButtons();
	}

	public sync = (focusComponents: IComponent[]) => {
		if (focusComponents.length === 0) {
			return;
		}

		this.dynamicButtons.forEach(button => button.hide());

		const someComponentType = Utils.Component.getSomeComponentType(focusComponents);

		if (someComponentType === null) {
			throw new ManipulatorError(('some component type was null'));
		}

		const allComponents: IComponent[] = focusComponents
			.map(component => [component, ...component.getComponentAll()]).flat();

		const textures = allComponents
			.map(component => component.getGraphics()
				.map(graphic => graphic.getTexture())
				.flat())
			.flat();

		if (textures.every(texture => texture === null)) {
			return;
		}

		const filteredTextures = textures.filter(texture => texture !== null);
		const sameProperties = Utils.Object.checkFieldsEquality<any>(...filteredTextures);

		switch (someComponentType) {
		case SketchComponentType.PICTURE:
			if ((sameProperties as IPictureTexture).radius !== null) {
				this.borderRadiusSelector.setValue((sameProperties as IPictureTexture).radius);
			}
			this.cornerRadiusContainer.show();
			this.dynamicButtons.forEach(button => button.show());
			break;
		case SketchComponentType.FIGURE:
			if ((sameProperties as IFigureTexture).radius !== null) {
				this.borderRadiusSelector.setValue((sameProperties as IFigureTexture).radius);
			}
			this.cornerRadiusContainer.show();
			break;
		default:
			this.cornerRadiusContainer.hide();
			break;
		}
	};

	private collectDynamicButtons() {
		this.dynamicButtons.push(
			this.replacePictureButton,
			this.cropPictureButton,
		);
	}

	private createButton = (props: ICreateButtonProps): SquareButton => props.container.appendSquareButton(button => {
		button.appendClassName(`object-panel__buttons-item ${props.buttonClassName}`);
		button.setIcon(props.icon);
		button.setHint(props.hint);
		button.addClickListener(props.onClickListener);
	});

	private createNumberInput = (props: ICreateNumberFieldProps): NumberInputField => props.container
		.appendNumberInput(field => {
			field.setIcon(props.icon);
			field.setMinValue(props.minValue);
			field.setMaxValue(props.maxValue);
			field.appendClassName(this.NUMBER_FIELD_CLASS_NAME);
			field.appendInputClassName(props.inputClassName);
			field.setValidateError('Заполните поле', 'object-panel__error object-panel__error');
			props.changeValueListener && field.addChangeValueListener(props.changeValueListener);
		});

	private setHeader = (text: string) => {
		const headerElement = HTMLGenerator.getH4({
			className: 'object-panel__title',
			text,
		});
		this.rootElement.append(headerElement);
	};
}

export default FramePanel;
