import TableCell from './cells/TableCell';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';

/**
 * Карта значений ID ячеек.
 *
 * Пример.
 * Характеристики ячейки (формат и последовательность используется только для визуального представления
 * и не отражает действительную структуру ячейки): [id, строка, колонка, объединение строк, объединение колонок].
 *
 * Существует три ячейки: [0x01, 0, 0, 2, 1], [0x02, 0, 1, 2, 3], [0x03, 2, 0, 1, 4].
 * В таком случае карта будет выглядеть следующим образом:
 *
 *	index		0		1		2		3
 *	  	|-----------------------------------|
 *	0 	|	0x01	0x02	0x02	0x02	|
 *	1 	|	0x01	0x02	0x02	0x02	|
 *	2 	|	0x03	0x03	0x03	0x03	|
 *	  	|-----------------------------------|
 */
export type TableGridMap = string[][]

/**
 * Вспомогательная сущность для компонента таблицы.
 * Выполняет задачу конвертации позиций ячеек в CSS Grid карту (`TableGridMap`).
 */
class TableGridAreas {
	private map: TableGridMap;

	public loadCells = (cells: TableCell[], columns: number) => {
		this.fillMap(cells, columns);
	};

	/**
	 * Возвращает коллекцию крайних левых идентификаторов ячеек.
	 */
	public getLeftExtremeIDList = (): string[] => {
		const list: string[] = [];

		if (this.map === undefined) {
			throw new ManipulatorError('map not initialized');
		}

		this.map.forEach(row => {
			const id = row[0];
			if (id === undefined) {
				throw new ManipulatorError('left extreme table id not found');
			}
			list.push(id);
		});

		return list;
	};

	public getMap = (): TableGridMap => this.map.map(row => [...row]);

	private clearMap = () => {
		this.map = [];
	};

	private fillMap = (cells: TableCell[], columns: number) => {
		this.clearMap();

		// Если есть объединения - запишем, чтобы при проходе их пропустить
		let colSpan;

		cells.forEach(cell => {
			const cellId = cell.getID();
			const row = cell.getRow();
			const column = cell.getColumn();
			// количество строк в ячейке
			const rowsSpan = cell.getRowSpan();
			// количество колонок в ячейке
			const columnsSpan = cell.getColumnSpan();
			colSpan = columnsSpan > 1 ? columnsSpan : 1;

			for (let currentRow = row; currentRow < row + rowsSpan; currentRow++) {
				for (let currentColumn = column; currentColumn < column + columnsSpan; currentColumn++) {
					if (this.map[currentRow] === undefined) {
						this.map[currentRow] = [];
					}
					if (!this.map[currentRow][currentColumn]) {
						this.map[currentRow][currentColumn] = cellId;
					} else if (!(currentColumn + 1 >= columns)) {
						this.map[currentRow][currentColumn + 1] = this.map[currentRow][currentColumn];
						cell.setColumnSpan(columnsSpan + 1);
					}
				}
			}
		});

		const isValid = this.validateMap(columns);
		if (!isValid) {
			throw new ManipulatorError('table map not valid');
		}
	};

	/**
	 * Проверяет карту на корректное заполнение.
	 * @param columns - количество колонок в таблице.
	 */
	private validateMap = (columns: number): boolean => {
		for (let row = 0; row < this.map.length; row++) {
			for (let column = 0; column < columns; column++) {
				const cell = this.map[row][column];
				if (cell === undefined) {
					// console.log(`position: row(${row}) column(${column})`);
					// console.log(this.map);
					return false;
				}
			}
		}
		return true;
	};
}

export default TableGridAreas;
