import { useState } from 'react';
import { useSelector } from 'react-redux';
import css from './SearchWorkshopModuleTagBar.module.scss';
import { classNames } from '../../../../shared/libs/classNames';
import { ITagData } from '../../../../entities/modules/tag/api/types';
import { SearchTagBar } from '../../../search-tag-bar';
import { workshopModuleApi, workshopModuleSlice } from '../../../../entities/workshop-module';
import { workshopModuleTagApi } from '../../../../entities/workshop-tag';
import { workshopTagAPI } from '../../../../entities/templates/workshop/tag/api/api';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { useCreateWorkshopModuleTag } from '../../model/services/useCreateWorkshopModuleTag';
import { useBindWorkshopModuleTag } from '../../model/services/useBindWorkshopModuleTag';

export const SearchWorkshopModuleTagBar = () => {
	const [search, setSearch] = useState('');

	const moduleId = useSelector(workshopModuleSlice.selectors.getBindingModuleId);

	const { data: availableTagsData } = workshopModuleApi.useGetAvailableModuleTagsQuery({
		moduleId,
	});

	const { data: moduleData } = workshopModuleApi.useGetWorkshopModuleQuery({
		moduleId: moduleId || '',
	});

	const { onCreateTag } = useCreateWorkshopModuleTag();
	const { onBindTag } = useBindWorkshopModuleTag(moduleId);

	return (
		<SearchTagBar
			search={search}
			bindTag={onBindTag}
			createTag={onCreateTag}
			setSearch={setSearch}
			tags={availableTagsData?.moduleTags}
			templateTags={(moduleData?.module?.tags || []) as ITagData[]}
		/>
	);
};
