import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import css from './blockHeader.module.scss';
import { dropDownTransitionClasses } from '../../../../../../styles/transitionStyles/transitionStyles';
import { ReactComponent as CloseArrowIcon } from './images/close-arrow.svg';
import { ReactComponent as OpenActiveArrowIcon } from './images/open-active-arrow.svg';
import AddBtn from '../../../../../ui/addBtn/AddBtn';

interface BlockHeaderProps {
	title: string;
	btnText: string,
	onBtnClick?: () => void,
	children: React.ReactNode,
	cssClass?: string,
	btnClass?: string,
}

const BlockHeader: React.FC<BlockHeaderProps> = ({
	title,
	btnText,
	children,
	cssClass,
	btnClass,
	onBtnClick,
}: BlockHeaderProps) => {
	BlockHeader.defaultProps = {
		cssClass: undefined,
		btnClass: undefined,
	};

	const [isOpen, setIsOpen] = useState(false);

	const dropDownRef = useRef(null);

	const handleIsOpen = () => {
		setIsOpen(isOpen => !isOpen);
	};

	return (
		<div className={css.wrapper}>
			<div
				className={cx(css.header, isOpen && css.open_header)}
				onClick={handleIsOpen}
				role="presentation"
			>
				<div className={css.title}>{title}</div>
				{
					isOpen
						? <OpenActiveArrowIcon />
						: <CloseArrowIcon />
				}
			</div>
			<CSSTransition
				in={isOpen}
				classNames={dropDownTransitionClasses}
				nodeRef={dropDownRef}
				timeout={200}
				unmountOnExit
			>
				<div ref={dropDownRef} className={cssClass}>
					{children}
					<AddBtn btnText={btnText} onBtnClick={onBtnClick} styles={btnClass} />
				</div>
			</CSSTransition>
		</div>
	);
};

export default BlockHeader;
