import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateWorkshopModuleScheme } from '../types/CreateWorkshopModuleScheme';

const initialState: CreateWorkshopModuleScheme = {
	selectedTagsIds: [],
	selectedCategoriesIds: [],
	selectedLegalEntitiesIds: [],
	name: '',
	description: '',
	preview: '',
	structure: null,
	isWindowOpen: false,
};

export const createWorkshopModuleSlice = createSlice({
	name: 'createWorkshopModule',
	initialState,
	selectors: {
		getIsWindowOpen: (state) => state.isWindowOpen || false,
		getStructure: (state) => state.structure || null,
		getPreview: (state) => state.preview || '',
		getName: (state) => state.name || '',
		getDescription: (state) => state.description || '',
		getDescriptionCount: (state) => state.description.length || 0,
		getSelectedTagsIds: (state) => state.selectedTagsIds || [],
		getSelectedCategoriesId: (state) => state.selectedCategoriesIds || [],
		getSelectedLegalEntitiesIds: (state) => state.selectedLegalEntitiesIds || [],
		getIsBtnActive: (state) => state.name.length > 0
			&& state.selectedTagsIds.length > 0
			&& state.selectedCategoriesIds.length > 0,
	},
	reducers: {
		removeSelectedLegalEntity: (state, action: PayloadAction<string>) => {
			state.selectedLegalEntitiesIds = state.selectedLegalEntitiesIds.filter(el => el !== action.payload);
		},
		addSelectedLegalEntity: (state, action: PayloadAction<string>) => {
			state.selectedLegalEntitiesIds = [...state.selectedLegalEntitiesIds, action.payload];
		},

		removeSelectedCategoryId: (state, action: PayloadAction<string>) => {
			state.selectedCategoriesIds = state.selectedCategoriesIds.filter(el => el !== action.payload);
		},
		setSelectedCategoriesIds: (state, action: PayloadAction<string[]>) => {
			state.selectedCategoriesIds = action.payload;
		},

		addSelectedTagId: (state, action: PayloadAction<string>) => {
			state.selectedTagsIds = [...state.selectedTagsIds, action.payload];
		},
		removeSelectedTag: (state, action: PayloadAction<string>) => {
			state.selectedTagsIds = state.selectedTagsIds.filter(el => el !== action.payload);
		},

		setName: (state, action: PayloadAction<string>) => {
			state.name = action.payload;
		},

		setPreview: (state, action: PayloadAction<string>) => {
			state.preview = action.payload;
		},

		setStructure: (state, action: PayloadAction<any>) => {
			state.structure = action.payload;
		},

		setIsWindowOpen: (state, action: PayloadAction<boolean>) => {
			state.isWindowOpen = action.payload;
		},

		setDescription: (state, action: PayloadAction<string>) => {
			state.description = action.payload;
		},

		cleanStateCreatingData: (state) => {
			state.name = '';
			state.description = '';
			state.selectedTagsIds = [];
			state.selectedCategoriesIds = [];
			state.selectedLegalEntitiesIds = [];
			state.preview = '';
			state.structure = null;
		},
	},
});

export const { actions: createWorkshopModuleActions } = createWorkshopModuleSlice;
export const { reducer: createWorkshopModuleReducer } = createWorkshopModuleSlice;
