import SketchPDFGenerator from './SketchPDFGenerator';
import { AnyComponentStructure } from '../../Sketch/Types';

const savePDF = (structure: AnyComponentStructure, name: string, callback: VoidFunction | null) => {
	const tempContainer = document.createElement('div');
	document.body.append(tempContainer);

	const generator = new SketchPDFGenerator(tempContainer, structure, name, callback);

	setTimeout(async () => {
		await generator.savePDF();
		tempContainer.remove();
	}, 0);
};

export default savePDF;
