import IComponentTree from '../../component-tree/IComponentTree';
import Dependent from '../dependent/Dependent';
import SizePanel from '../../frame/SizePanel';
import GraphicType from '../../graphic/GraphicType';

interface ISizePanelDependencies {
	componentTree: IComponentTree,
}

class ComponentFocusObserver extends Dependent<ISizePanelDependencies> {
	private sizePanels: SizePanel[];
	constructor(private manipulatorContainer: HTMLElement) {
		super();
		this.sizePanels = [];
	}

	private disableSizePanels = () => {
		if (!this.sizePanels.length) return;
		for (let i = 0; i < this.sizePanels.length; i++) {
			this.sizePanels[i].disable();
		}
		this.sizePanels = [];
	};

	public sync = () => {
		this.disableSizePanels();
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) return;

		for (let i = 0; i < focusComponents.length; i++) {
			const graphics = focusComponents[i].getGraphics();
			if (!focusComponents[i].isEnableEditMode()) {
				for (let j = 0; j < graphics.length; j++) {
					const position = graphics[j].getGlobalPosition();
					const width = graphics[j].getRealWidth();
					let height = graphics[j].getRealHeight();
					if (graphics[j].type === GraphicType.GROUP) {
						height = graphics[j].getFrameConfiguration().height;
					}
					const sizePanel = new SizePanel();
					sizePanel.setSize(width, height);
					const { topTriggersElement, bottomTriggersElement, sizeElement } = sizePanel.getElements();
					topTriggersElement.style.left = `${position.x}px`;
					topTriggersElement.style.width = `${width}px`;
					topTriggersElement.style.top = `${position.y}px`;
					topTriggersElement.style.zIndex = '1000';
					topTriggersElement.style.position = 'absolute';

					bottomTriggersElement.style.left = `${position.x}px`;
					bottomTriggersElement.style.width = `${width}px`;
					bottomTriggersElement.style.top = `${position.y + height}px`;
					bottomTriggersElement.style.zIndex = '1000';
					bottomTriggersElement.style.position = 'absolute';

					sizeElement.style.left = `${position.x + width / 2}px`;
					sizeElement.style.top = `${position.y + height + 5}px`;
					sizeElement.style.position = 'absolute';
					sizeElement.style.zIndex = '1000';
					this.manipulatorContainer.append(topTriggersElement);
					this.manipulatorContainer.append(bottomTriggersElement);
					this.manipulatorContainer.append(sizeElement);
					this.sizePanels.push(sizePanel);
				}
			}
		}
	};
}

export default ComponentFocusObserver;
