import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWorkshopModuleScheme } from '../types/WorkshopModuleScheme';

const initialState: IWorkshopModuleScheme = {
	moduleSearch: undefined,
	tags: undefined,
	category: undefined,
	isModulesModalActive: false,
	moduleWindowFunc: null,
	onCloseModuleModal: null,

	deletingModuleId: undefined,
	isDeletingModuleModalActive: false,

	bindingModuleId: undefined,
	isBindingTagModuleModalActive: false,

	isEditModuleModalActive: false,

	publishingModuleId: undefined,
	isPublishingModuleModalActive: false,
};

export const workshopModuleSlice = createSlice({
	name: 'workshopModule',
	initialState,
	selectors: {
		getIsEditModuleModalActive: (state) => state.isEditModuleModalActive || false,
		getIsModulesModalActive: (state) => state.isModulesModalActive || false,
		getDeletingModuleId: (state) => state.deletingModuleId || undefined,
		getIsDeletingModuleModalActive: (state) => state.isDeletingModuleModalActive || false,
		getTags: (state) => state.tags || [],
		getCategory: (state) => state.category || undefined,
		getModuleSearch: (state) => state.moduleSearch || '',
		getBindingModuleId: (state) => state.bindingModuleId || undefined,
		getIsBindingTagModuleModalActive: (state) => state.isBindingTagModuleModalActive || false,

		getPublishingModuleId: (state) => state.publishingModuleId || undefined,
		getIsPublishingModuleModalActive: (state) => state.isPublishingModuleModalActive || false,

		getOnModuleClick: (state) => state.moduleWindowFunc || null,
	},
	reducers: {
		setModuleSearch(state, action: PayloadAction<string>) {
			state.moduleSearch = action.payload;
		},
		setTags(state, action: PayloadAction<number[]>) {
			state.tags = action.payload;
		},
		setCategory(state, action: PayloadAction<number | undefined>) {
			state.category = action.payload;
		},
		setIsModulesModalActive(state, action: PayloadAction<boolean>) {
			state.isModulesModalActive = action.payload;
		},
		setModuleFunc: (state, action: PayloadAction<(moduleId: string) => void>) => {
			state.moduleWindowFunc = action.payload;
		},
		setCloseModuleModalFunc: (state, action: PayloadAction<() => void>) => {
			state.onCloseModuleModal = action.payload;
		},

		setDeletingModuleId(state, action: PayloadAction<string | undefined>) {
			state.deletingModuleId = action.payload;
		},
		setIsDeletingModuleModalActive(state, action: PayloadAction<boolean>) {
			state.isDeletingModuleModalActive = action.payload;
		},

		setBindingModuleId(state, action: PayloadAction<string | undefined>) {
			state.bindingModuleId = action.payload;
		},
		setIsBindingTagModuleModalActive(state, action: PayloadAction<boolean>) {
			state.isBindingTagModuleModalActive = action.payload;
		},

		setIsEditModuleModalActive(state, action: PayloadAction<boolean>) {
			state.isEditModuleModalActive = action.payload;
		},

		setPublishingModuleId(state, action: PayloadAction<string | undefined>) {
			state.publishingModuleId = action.payload;
		},
		setIsPublishingModuleModalActive(state, action: PayloadAction<boolean>) {
			state.isPublishingModuleModalActive = action.payload;
		},
	},
});

export const {
	actions: workshopModuleActions,
	reducer: workshopModuleReducer,
	selectors: workshopModuleSelectors,
} = workshopModuleSlice;
