import { read, WorkBook } from 'xlsx';
import Utils from './Utils';

class File {
	/**
	 * Возвращает структуру XLSX файла в callback.
	 * @param callback
	 */
	public getXLSXData = (callback: (data: WorkBook) => void) => {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = '.xlsx, .ods';
		input.click();
		input.onchange = () => {
			if (!input.files) return;
			const file = input.files[0];
			const reader = new FileReader();
			reader.onloadend = () => {
				if (!reader.result) return;
				const workbook = read(reader.result, {
					type: 'buffer',
					cellStyles: true,
					// raw: true,
					// bookDeps: true,
					// dense: true,
					// bookVBA: true,
					// bookProps: true,
					// cellFormula: true,
					// cellHTML: true,
					// cellNF: true,
					// cellText: true,
					// cellDates: true,
					// sheetStubs: true,
					// bookFiles: true,
					// bookVBA: true,
					// WTF: true,
				});
				callback(workbook);
			};
			reader.readAsArrayBuffer(file); // Загружает файл в reader
		};
	};

	/**
	 * Вовзращает в callback имя, изображение в формате base64 и его размер в байтах.
	 * @param callback
	 */
	public getPictureBase64FromFile = (callback: (name: string, base64: string, size: number) => void) => {
		const input = document.createElement('input');

		// Разбиваем строку ({filepath}/{filename}), чтобы получить название файла
		let filename = input.value.split(/(\\|\/)/g).pop();
		filename = filename === undefined ? Utils.Generate.UUID4() : filename;
		input.type = 'file';
		input.accept = 'image/*';
		input.onchange = () => {
			if (!input.files) {
				throw new Error('files not found');
			}
			const file = input.files[0];
			if (file === undefined) {
				throw new Error('file is undefined');
			}
			const reader = new FileReader();
			reader.onloadend = () => {
				if (!reader.result) {
					throw new Error('error result');
				}
				// Запускаем функцию, которую передавали в параметрах с полученными данными
				callback(filename as string, reader.result.toString(), file.size);
			};
			reader.readAsDataURL(file);
		};
		input.click();
	};
}

export default File;
