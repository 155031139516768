import InterfacePanel from '../../InterfacePanel';
import HTMLGenerator from '../../../utils/HTMLGenerator';
import ManipulatorInterfaceBar from '../ManipulatorInterfaceBar';
import Utils from '../../../utils/impl/Utils';
import SvgCollection from '../../../utils/SvgCollection';
import TemplateMenu from '../../menu/template-menu/TemplateMenu';
import { notificationTrialBlock } from './notification-trial-block';
import { Tariff } from '../../../../../entities/tariff/model/data';
import store from '../../../../../redux/store/store';

/**
 * Верхняя панель интерфейса.
 */
class TopBar extends ManipulatorInterfaceBar {
	public readonly menu: TemplateMenu;

	private readonly panelsContainer: HTMLElement;
	private readonly backButton: HTMLElement;
	private readonly PANELS_CONTAINER_CLASS_NAME = 'sketch-manipulator__header top-bar show';
	private readonly topPanelModules: HTMLElement;
	private readonly trialTariffNotification: HTMLElement;

	constructor(
		onBackPage: VoidFunction,
		...panels: InterfacePanel[]
	) {
		super();
		this.setRootElementClassName(this.PANELS_CONTAINER_CLASS_NAME);

		this.panelsContainer = HTMLGenerator.getDiv({ className: 'top-bar__modes-wrapper' });

		/* Элемент - кнопка назад */
		this.backButton = HTMLGenerator.getDiv({
			className: 'top-bar__back-btn',
			fnClick: onBackPage,
		});
		Utils.DOM.injectSVG(this.backButton, SvgCollection.backButton);
		this.rootElement.append(this.backButton);

		/* Элемент с заменяемыми панелями в зависимости от компонента в фокусе */
		this.panelsContainer.append(...panels.map(panel => panel.getElement()));
		this.rootElement.append(this.panelsContainer);

		/* Элемент - кнопка модулей */
		this.menu = new TemplateMenu();
		this.topPanelModules = HTMLGenerator.getDiv({ className: 'top-bar__modules' });
		this.topPanelModules.addEventListener('click', (ev) => {
			if (ev.target === this.topPanelModules
				|| this.topPanelModules.contains(ev.target as HTMLElement)
			) {
				this.menu.toggle();
			} else {
				this.menu.close();
			}
		});
		this.topPanelModules.append(this.menu.getElement());

		Utils.DOM.injectSVG(this.topPanelModules, SvgCollection.topPanelModules);

		// Элемент блок с уведомлением о пробном тарифе
		this.trialTariffNotification = notificationTrialBlock(
			'Скачивание PDF недоступно в пробной версии',
			'Перейти к тарифам',
			'https://wakadoo.pro/tarif',
		);

		const state = store.getState();
		const userTariff = state.personalAccount.account.tariff;
		if	(userTariff === null || userTariff.name === Tariff.TRIAL) {
			this.rootElement.append(this.trialTariffNotification);
			this.rootElement.classList.add('top-bar-with-note');
		} else {
			this.rootElement.append(this.topPanelModules);
		}

		this.registerPanels(...panels);
		this.onClickObserver();
	}

	public appendMenuItem = (icon: SvgCollection, text: string, listener: VoidFunction): TemplateMenu => {
		this.menu.appendMenuItem(icon, text, listener);
		return this.menu;
	};

	private onClickObserver = () => {
		document.body.addEventListener('click', this.onClickOutsideElement);
	};

	private onClickOutsideElement = (event: Event) => {
		if (
			event.target !== this.topPanelModules
			&& !this.topPanelModules.contains(event.target as HTMLElement)
		) {
			this.menu.close();
			event.stopPropagation();
		}
	};
}

export default TopBar;
