import GraphicFactory from './GraphicFactory';
import IComponent from '../../components/IComponent';
import GraphicType from '../../graphic/GraphicType';
import PageGraphic from '../../graphic/page/PageGraphic';
import GroupGraphic from '../../graphic/group/GroupGraphic';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import TableGraphic from '../../graphic/table/TableGraphic';
import TextGraphic from '../../graphic/text/TextGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import FigureGraphic from '../../graphic/figure/FigureGraphic';
import IGraphic from '../../graphic/IGraphic';
import PageHeader from '../../interface/page-header/PageHeader';
import PageBackgroundImageState from '../../graphic/page/PageBackgroundImageState';
import IPagesComponentTree from '../../component-tree/IPagesComponentTree';
import ModuleGraphic from '../../graphic/module/ModuleGraphic';

interface IStaticGraphicFactoryDependencies {
	componentTree: IPagesComponentTree,
}

/**
 * Фабрика для сборки не изменяемой графики.
 */
class StaticGraphicFactory extends GraphicFactory<IStaticGraphicFactoryDependencies> {
	// eslint-disable-next-line no-useless-constructor
	constructor() {
		super();
	}

	public createGraphic = <GraphicT extends IGraphic>(
		type: GraphicType,
		parentComponent: IComponent,
	): GraphicT => {
		let graphic: IGraphic;

		switch (type) {
		case GraphicType.PAGE: {
			graphic = new PageGraphic();
			break;
		}
		case GraphicType.GROUP: {
			graphic = new GroupGraphic();
			break;
		}
		case GraphicType.PICTURE: {
			graphic = new PictureGraphic();
			break;
		}
		case GraphicType.TABLE: {
			graphic = new TableGraphic();
			break;
		}
		case GraphicType.TEXT: {
			graphic = new TextGraphic();
			break;
		}
		case GraphicType.FIGURE: {
			graphic = new FigureGraphic();
			break;
		}
		case GraphicType.MODULE: {
			graphic = new ModuleGraphic();
			break;
		}
		default: {
			throw new ManipulatorError(`graphic type "${type}" not found`);
		}
		}

		graphic.setParentComponent(parentComponent);

		return graphic as GraphicT;
	};
}

export default StaticGraphicFactory;
