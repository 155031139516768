enum SketchComponentType {
	PICTURE = 'COMPONENT_PICTURE',
	TABLE = 'COMPONENT_TABLE',
	TEXT = 'COMPONENT_TEXT',
	FIGURE = 'COMPONENT_FIGURE',
	GROUP = 'COMPONENT_GROUP',
	PAGES_CONTAINER = 'COMPONENT_PAGES_CONTAINER',
	MODULE = 'COMPONENT_MODULE',
}

export default SketchComponentType;
