import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { useUnbindWorkshopTagFromGroup } from '../../services/unbind-tag-from-group/useUnbindWorkshopTagFromGroup';
import { workshopTagGroupActions, workshopTagGroupSlice } from '../../../../entities/workshop-tag-group';
import { NewModal } from '../../../../components/ui/new-modal';
import ConfirmModal from '../../../../components/modals/common/confirmModal/ConfirmModal';

export const UnbindWorkshopTagGroupModal = () => {
	const dispatch = useAppDispatch();

	const { unbindTagFromGroup, requestData } = useUnbindWorkshopTagFromGroup();

	const isActive = useSelector(workshopTagGroupSlice.selectors.getIsUnbindingGroupModalActive);

	const handleModal = (value: boolean) => {
		dispatch(workshopTagGroupActions.setIsUnbindingGroupModalActive(value));
	};

	const closeModal = () => handleModal(false);

	return (
		<NewModal active={isActive} setActive={handleModal}>
			<ConfirmModal
				text="Вы действительно хотите удалить тег из группы тегов без возможности восстановления?"
				onDelete={unbindTagFromGroup}
				isModalActive={isActive}
				onCloseModal={closeModal}
				disabled={requestData.isLoading}
			/>
		</NewModal>
	);
};
