import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import css from './createTableModal.module.scss';
import Button, { buttonsTypes } from '../../../ui/button/button';
import CreateInput from '../moduleWindow/createInput/CreateInput';
import { NewModal } from '../../../ui/new-modal';
import { setIsCreateTableModalOpen } from '../../../../entities/modules/module/model/slice';
import { useAppSelector } from '../../../../shared/libs/redux';

const CreateTableModal: React.FC = () => {
	const dispatch = useDispatch();

	const isCreateTableModalOpen = useAppSelector(state => state.moduleWindow.modalsActive.isCreateTableModalOpen);

	const createTable = useAppSelector(state => state.moduleWindow.onCreateTable);
	const closeTableModal = useAppSelector(state => state.moduleWindow.onCloseTableModal);

	const [rowCount, setRowCount] = useState('');
	const [columnCount, setColumnCount] = useState('');

	const handleCreateTableModal = (value: boolean) => {
		if (!value && closeTableModal) {
			closeTableModal();
			setRowCount('');
			setColumnCount('');
		}
		dispatch(setIsCreateTableModalOpen(value));
	};

	const onCreateTable = () => {
		if (createTable) {
			createTable(Number(columnCount), Number(rowCount));
			handleCreateTableModal(false);
		}
	};

	const handleValue = (
		value: string,
		setFunc: (value: string) => void,
	) => {
		const number = Number(value);

		if (number <= 30 && number > 0) {
			setFunc(number.toString());
		} else if (value === '') {
			setFunc('');
		}
	};

	return (
		<NewModal
			active={isCreateTableModalOpen}
			setActive={handleCreateTableModal}
		>
			<div className={css.wrapper}>
				<div className={css.title}>
					Введите значение для таблицы
				</div>
				<div className={css.input_block}>
					<CreateInput
						subTitle="Число строк"
						onChange={(value: string) => handleValue(value, setRowCount)}
						text={rowCount}
						maxLength={2}
						defaultValue="12"
						cssClass={css.input}
					/>
					<div className={css.icon}>
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17 7.5L7 17.5M7 7.5L17 17.5"
								stroke="#EDEDED"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<CreateInput
						subTitle="Число столбцов"
						onChange={(value: string) => handleValue(value, setColumnCount)}
						text={columnCount}
						maxLength={2}
						defaultValue="12"
						cssClass={css.input}
					/>
				</div>
				<Button
					text="Создать таблицу"
					buttonType={buttonsTypes.BRIGHT}
					onClick={onCreateTable}
					inActive={!!(columnCount && rowCount)}
					cssClass={css.button}
				/>
			</div>
		</NewModal>
	);
};

export default CreateTableModal;
