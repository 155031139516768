import React, { forwardRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import css from './actionsWithCards.module.scss';
import { popUpTransitionClasses } from '../../../styles/transitionStyles/transitionStyles';

export interface ActionsWithCardsItem {
	text: string,
	cssClassText?: string
	cssClassSmile?: string
	cssClassMain?: string
	smile: React.ReactNode,
	onClick: () => void,
}

export interface ActionsWithCardsProps {
	cssClassMain?: string,
	items: ActionsWithCardsItem[],
	onClose: () => void,
	isActive: boolean,
}

const TransitionActionsWithCards = forwardRef<HTMLDivElement, ActionsWithCardsProps>(({
	items,
	cssClassMain,
	onClose,
	isActive,
}, ref) => {
	const onClick = (e: React.MouseEvent) => e.stopPropagation();

	return (
		<CSSTransition
			nodeRef={ref}
			in={isActive}
			timeout={200}
			unmountOnExit
			classNames={popUpTransitionClasses}
		>
			<div
				onClick={onClick}
				role="presentation"
				ref={ref}
				className={cx(css.changeCardInfo, cssClassMain)}
			>
				{
					items.map(item => (
						<div
							className={cx(css.item, cssClassMain)}
							onClick={item.onClick}
							key={item.text}
							role="presentation"
						>
							<div
								className={cx(css.smileWrapper, item.cssClassSmile)}
							>
								{item.smile}
							</div>
							<div
								className={cx(css.text, item.cssClassText)}
							>
								{item.text}
							</div>
						</div>
					))
				}
			</div>
		</CSSTransition>
	);
});

export default TransitionActionsWithCards;
