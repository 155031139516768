import store from '../../../../redux/store/store';
import { onCloseModuleWindow, onOpenCreateTemplateWindow } from '../../../../redux/thunk-creators/moduleWindow';
import XLSXTableImporter from '../../mechanics/import-table/implements/XLSXTableImporter';
import MultiPageUseCases, { IMultiPageUseCasesDependencies } from '../multi-page/MultiPageUseCases';
import Utils from '../../utils/impl/Utils';
import IImportTableStructure from '../../mechanics/import-table/IImportTableStructure';
import ModuleBuilder from '../../factories/ModuleBuilder';
import IModuleUseCases from '../IModuleUseCases';
import { moduleAPI } from '../../../../entities/modules/module/api/api';
import TableOrganizer from '../../mechanics/component-organizer/TableOrganizer';
import MultiPageTableReplacer from '../../mechanics/replace-table/MultiPageTableReplacer';
import TableComponent from '../../components/table/TableComponent';
import SketchComponentType from '../../components/SketchComponentType';
import { templateGalleryModuleApi } from '../../../../entities/template-gallery-module';

interface IPrivateTemplateUseCasesDependencies extends IMultiPageUseCasesDependencies {
	moduleBuilder: ModuleBuilder,
	tableOrganizer: TableOrganizer,
}

class PrivateTemplateUseCases
	extends MultiPageUseCases<IPrivateTemplateUseCasesDependencies>
	implements IModuleUseCases {
	private readonly XLSXTableImporter: XLSXTableImporter;
	private readonly XLSXTableReplacer: MultiPageTableReplacer;

	constructor() {
		super();
		this.XLSXTableImporter = new XLSXTableImporter();
		this.XLSXTableReplacer = new MultiPageTableReplacer();

		this.addPostInjectDependenciesListener(dependencies => {
			this.XLSXTableReplacer.connectDependencies({
				spatialTree: dependencies.spatialTree,
				XLSXTableImporter: this.XLSXTableImporter,
				tableOrganizer: dependencies.tableOrganizer,
				sketchStabilizer: dependencies.sketchStabilizer,
				componentFocusObserver: dependencies.componentFocusObserver,
			});
			this.XLSXTableReplacer.injectDependencies();
			this.addPostApplyToGraphicListener(component => {
				if (component.type === SketchComponentType.TABLE) {
					setTimeout(this.dependencies.tableOrganizer.reorganize
						.bind(this, component as TableComponent), 0);
					setTimeout(this.dependencies.tableOrganizer.reorganize
						.bind(this, component as TableComponent), 100);
					setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 100);
					setTimeout((component as TableComponent).renderCells.bind(this), 100);
				}
			});
		});
	}

	/**
	 * Создаёт модуль из компонентов в фокусе. При создании модуля из одного uniter компонента (например это компонент
	 * группы) этот компонент конвертируется в компонент модуля, т.к. никакого смысла в группе нет в данном случае.
	 */
	public createModule = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) {
			return;
		}

		const moduleComponent = this.dependencies.moduleBuilder.build(...focusComponents);

		Utils.Generate.ComponentPreview(moduleComponent, fileID => {
			onOpenCreateTemplateWindow(showPreview => {
				showPreview(fileID);
			}, moduleComponent.getUniqueStructure());
		});
	};

	public injectModule = (moduleID: string) => {
		const res = store.dispatch(moduleAPI.endpoints?.getModule.initiate({ id: moduleID }));

		res.unwrap()
			.then(res => {
				this.dependencies.componentInjector.injectModule(res.structure);
				onCloseModuleWindow();
			});
	};

	public injectGalleryModule = (moduleID: string) => {
		const res = store
			.dispatch(templateGalleryModuleApi.endpoints?.getTemplateGalleryModule.initiate({ moduleId: moduleID }));

		res.unwrap()
			.then(res => {
				this.dependencies.componentInjector.injectModule(res.rootComponent);
				onCloseModuleWindow();
			});
	};

	public replaceTablesFromXLSX = () => {
		this.dependencies.sketchStabilizer.startUserAction();
		const tables = this.dependencies.componentTree
			.getUniformFocusComponents<TableComponent>(SketchComponentType.TABLE);
		if (tables === null) {
			return;
		}

		this.XLSXTableReplacer.replaceTable(tables);
	};

	/**
	 * Запускает процесс выбора XLSX файла пользователем и создания таблиц из его структуры.
	 * В случае, если в файле несколько листов, то будет создано несколько таблиц.
	 */
	public createTableFromXLSX = () => {
		this.XLSXTableImporter.getStructure((tables: IImportTableStructure[]) => {
			// Всегда вставляем таблицу из первого листа.
			const { rowCount, columnCount, cells } = tables[0];
			this.dependencies.sketchStabilizer.startUserAction();
			this.dependencies.componentInjector.injectTable(rowCount, columnCount, cells);
			this.dependencies.componentInjector
				.addSingleUseInjectListener(this.dependencies.sketchStabilizer.stopUserAction);
		});
	};
}

export default PrivateTemplateUseCases;
