import { AnyComponentStructure, LayerSequences } from '../Types';
import IComponentFactory from './component/IComponentFactory';
import IGraphicFactory from './graphic/IGraphicFactory';
import IComponent from '../components/IComponent';
import Dependent from '../utils/dependent/Dependent';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import SketchComponentType from '../components/SketchComponentType';
import TableComponent from '../components/table/TableComponent';

interface IComponentBuilderDependencies {
	componentFactory: IComponentFactory,
	graphicFactory: IGraphicFactory,
}

/**
 * Сборщик компонентов. Имеет методы для формирования вложенных компонентов и изменения их.
 * Алгоритм использования:
 * 1. Загрузка структуры компонента (`scanComponent()`). В памяти будет храниться собранный
 * компонент и его структура.
 * 2. Взаимодействие с новым компонентом (`getComponent()`, `getStructure()`, `getLayerSequences()` и т.д.).
 *
 * Если необходимо получить новый компонент с той же структурой, необходимо обновить внутренний компонент
 * методом `regenerate()`.
 */
class ComponentBuilder extends Dependent<IComponentBuilderDependencies> {
	private component: IComponent | null;
	// Порядок слоев только той графики, которая находится внутри ComponentBuilder в данный момент.
	private layerSequences: LayerSequences;
	private structure: AnyComponentStructure | null;

	constructor() {
		super();
		this.component = null;
		this.structure = null;
		this.layerSequences = [];
	}

	/**
	 * Очищает память сборщика.
	 */
	public clear = () => {
		this.structure = null;
		this.component = null;
		this.layerSequences = [];
	};

	/**
	 * Сканирует компонент и сохраняет в памяти его структуру. Сохраняет исходный порядок слоев.
	 * @param component Сканируемый компонент.
	 * @param globalLayerSequences Последовательность слоев.
	 */
	public scanComponent = (component: IComponent, globalLayerSequences: LayerSequences) => {
		this.clear();
		this.initializeFromComponent(component, globalLayerSequences);
	};

	/**
	 * Сканирует структуру и сохраняет её в памяти. Сохраняет исходный порядок слоев.
	 * @param structure
	 */
	public scanStructure = (structure: AnyComponentStructure) => {
		this.clear();
		this.initializeFromStructure(structure);
	};

	/**
	 * Возвращает порядок графики. Не содержит информацию о расположении графики, только лишь о порядке. Графика только
	 * та, которая внутри ComponentBuilder в данный момент
	 */
	public getLayerSequences = (): LayerSequences => {
		if (this.structure === null) {
			throw new ManipulatorError('structure not found');
		}
		if (this.component === null) {
			throw new ManipulatorError('component not found');
		}

		return this.layerSequences.map(pageSequence => [...pageSequence]);
	};

	/**
	 * Перезаписывает внутренний компонент новым с той же структурой, но уникальными идентификаторами.
	 */
	public regenerate = () => {
		if (this.component === null || this.structure === null) {
			throw new ManipulatorError('component or structure not found');
		}

		const uniqueStructure = this.component.getUniqueStructure();
		const currentStructure = this.structure;
		const updatedLayerSequences = this.getUpdatedLayerSequences(
			currentStructure,
			this.layerSequences,
			uniqueStructure,
		);

		this.structure = uniqueStructure;
		this.build();
		this.layerSequences = updatedLayerSequences;
	};

	/**
	 * Возвращает структуру сгенерированного компонента.
	 */
	public getStructure = (): AnyComponentStructure => {
		if (this.structure === null) {
			throw new ManipulatorError('structure not load');
		}
		if (this.component === null) {
			throw new ManipulatorError('component not load');
		}
		return this.component.getStructure();
	};

	/**
	 * Возвращает уникальную структуру сгенерированного компонента.
	 */
	public getUniqueStructure = (): AnyComponentStructure => {
		if (this.structure === null) {
			throw new ManipulatorError('structure not load');
		}
		if (this.component === null) {
			throw new ManipulatorError('component not load');
		}
		return this.component.getUniqueStructure();
	};

	/**
	 * Возвращает собранный компонент.
	 */
	public getComponent = (): IComponent => {
		if (this.component === null) {
			throw new ManipulatorError('component not found');
		}

		return this.component;
	};

	/**
	 * Возвращает коллекцию всех компонентов, включая корневой.
	 */
	public getComponentAll = (): IComponent[] => {
		if (this.component === null) {
			throw new ManipulatorError('component not found');
		}

		return [this.component, ...this.component.getComponentAll()];
	};

	/**
	 * Собирает компонент по сохраненной структуре.
	 */
	private build = () => {
		if (this.structure === null) {
			throw new ManipulatorError('structure not load');
		}

		const component = this.generateComponent(this.structure);
		this.structure.components?.forEach(componentStructure => {
			this.recursiveGenerateComponent(component, componentStructure);
		});

		this.component = component;
	};

	/**
	 * Рекурсивно генерирует компонент по структуре.
	 * @param parent Родительский компонент.
	 * @param structure Структура для генерации компонента.
	 */
	private recursiveGenerateComponent = (parent: IComponent, structure: AnyComponentStructure) => {
		const component = this.generateComponent(structure);
		parent.appendComponent(component);
		this.fillFrameElement(parent, component);
		structure.components?.forEach(componentStructure => {
			this.recursiveGenerateComponent(component, componentStructure);
		});
	};

	private fillFrameElement = (parentComponent: IComponent, component: IComponent) => {
		const componentGraphics = component.getGraphics();
		const componentStructure = component.getStructure();
		const parentGraphics = parentComponent.getGraphics();

		componentGraphics.forEach(graphic => {
			if (componentStructure.offset === null) {
				throw new ManipulatorError('component offset is null');
			}

			const offset = graphic.getOffset();
			const parentGraphic = parentGraphics[componentStructure.offset + offset];
			if (parentGraphic === undefined) {
				throw new ManipulatorError('parent graphic not found');
			}

			const graphicElement = graphic.getFrameElement();
			const parentElement = parentGraphic.getGraphicElement();

			parentElement.append(graphicElement);
		});

		if (component.type === SketchComponentType.TABLE) {
			(component as TableComponent).renderCells();
		}
	};

	/**
	 * Генерирует дерево компонентов.
	 * @param structure Структура для генерации компонентов.
	 */
	private generateComponent = (structure: AnyComponentStructure): IComponent => {
		const component = this.dependencies.componentFactory.getClearComponent(structure.type);
		component.setStructure(() => structure);

		structure.graphics?.forEach(graphicStructure => {
			const graphic = this.dependencies.graphicFactory.getClearGraphic(graphicStructure.type);
			graphic.setStructure(() => graphicStructure);
			component.appendGraphic(graphic);
		});

		return component;
	};

	/**
	 * Инициализирует строителя на основе компонента. Сохраняет в памяти структуру и последовательность слоев
	 * нового компонента. Хранит независимую копию компонента.
	 * @param component Исходный компонент.
	 * @param globalLayerSequences Глобальная последовательность слоев.
	 */
	private initializeFromComponent = (
		component: IComponent,
		globalLayerSequences: LayerSequences,
	): LayerSequences => {
		const components: IComponent[] = [component, ...component.getComponentAll()];
		const affectedGraphics: string[] = components
			.map(component => component.getGraphics())
			.flat()
			.map(graphic => graphic.getID());

		this.structure = component.getUniqueStructure();

		this.build();
		if (this.component === null) {
			throw new ManipulatorError('component not build');
		}

		const updatedGraphics: string[] = [this.component, ...this.component.getComponentAll()]
			.map(component => component.getGraphics())
			.flat()
			.map(graphic => graphic.getID());

		const layerSequences: LayerSequences = [];

		for (let i = 0; i < affectedGraphics.length; i++) {
			const graphicID = affectedGraphics[i];

			let isFound = false;
			for (let j = 0; j < globalLayerSequences.length; j++) {
				const pageSequence = globalLayerSequences[j];

				const findIndex = pageSequence.indexOf(graphicID);
				if (findIndex !== -1) {
					isFound = true;
				}

				if (layerSequences[j] === undefined) {
					layerSequences[j] = [];
				}
				layerSequences[j][findIndex] = updatedGraphics[i];
			}

			if (!isFound) {
				throw new ManipulatorError('graphic in layer sequences not found');
			}
		}
		this.cleanupLayerSequence(layerSequences);

		this.layerSequences = layerSequences;

		return layerSequences;
	};

	/**
	 * Устраняет пустоты в последовательностях и первые пустые элементы в коллекции последовательностей.
	 * Пример:
	 * [
	 * 		[],
	 * 		[],
	 * 		[undefined, "xxxxx", undefined, undefined, "yyyyy", undefined, "zzzzz"],
	 * 		[],
	 * 		["xxxxx", undefined, "yyyyy", undefined, "zzzzz"],
	 * 		[],
	 * 		[undefined, "xxxxx", "yyyyy", undefined, "zzzzz", undefined, undefined],
	 * ] => [
	 * 		["xxxxx", "yyyyy", "zzzzz"],
	 * 		[],
	 * 		["xxxxx", "yyyyy", "zzzzz"],
	 * 		[],
	 * 		["xxxxx", "yyyyy", "zzzzz"],
	 * ]
	 * @param layerSequences Последовательность для обработки.
	 */
	private cleanupLayerSequence = (layerSequences: LayerSequences) => {
		let isRemoveSequence = true;
		return layerSequences.filter(sequence => {
			if (isRemoveSequence) {
				isRemoveSequence = false;
				return sequence.length !== 0;
			}
			return true;
		})
			.forEach((_, index) => {
				if (layerSequences[index] === undefined) {
					return;
				}
				layerSequences[index] = layerSequences[index].filter(elem => elem !== undefined);
			});
	};

	/**
	 * Инициализирует строителя на основе компонента.
	 * Сохраняет в памяти структуру и последовательность слоев нового компонента.
	 * @param structure Сканируемая структура.
	 */
	private initializeFromStructure = (structure: AnyComponentStructure) => {
		this.structure = structure;
		this.build();

		if (this.component === null) {
			throw new ManipulatorError('component is not build');
		}

		const layerSequences: LayerSequences = [];
		this.recursiveCalculateSequences(layerSequences, structure, 0);
		this.cleanupLayerSequence(layerSequences);

		this.layerSequences = layerSequences;
	};

	/**
	 * Формирует последовательность слоев графики по структуре корневого компонента.
	 * @param accum Аккумулирующая коллекция последовательностей слоев.
	 * @param structure Структура корневого компонента.
	 * @param sumComponentOffset Суммарный сдвиг компонента на текущую итерацию.
	 */
	private recursiveCalculateSequences = (
		accum: LayerSequences,
		structure: AnyComponentStructure,
		sumComponentOffset: number,
	) => {
		if (structure.graphics !== null) {
			structure.graphics.forEach((graphic, index) => {
				if (accum[sumComponentOffset + index] === undefined) {
					accum[sumComponentOffset + index] = [];
				}
				if (graphic.frame === null) {
					throw new ManipulatorError('frame configuration is null');
				}
				accum[sumComponentOffset + index][graphic.frame.layer] = graphic.id;
			});
		}

		structure.components?.forEach(component => {
			if (component.offset === null) {
				throw new ManipulatorError('structure offset is null');
			}
			this.recursiveCalculateSequences(accum, component, sumComponentOffset + component.offset);
		});
	};

	/**
	 * Возвращает обновленную последовательность слоев из структуры `source` на основании последовательности
	 * из `prototype`. Структуры должны быть идентичны.
	 * @param prototypeStructure Структура, по которой будет генерироваться новая последовательность.
	 * @param prototypeLayers Последовательность слоев, на которой будет основываться новая последовательность.
	 * @param sourceStructure Структура, идентификаторы который будут в новой последовательности.
	 * @private
	 */
	private getUpdatedLayerSequences(
		prototypeStructure: AnyComponentStructure,
		prototypeLayers: LayerSequences,
		sourceStructure: AnyComponentStructure,
	): LayerSequences {
		const resultSequences: LayerSequences = [];
		const prototypeGraphics: string[] = [];
		const sourceGraphics: string[] = [];

		this.recursiveAccumulationGraphicID(prototypeGraphics, prototypeStructure);
		this.recursiveAccumulationGraphicID(sourceGraphics, sourceStructure);
		if (prototypeGraphics.length !== sourceGraphics.length) {
			throw new ManipulatorError('different structures');
		}

		for (let i = 0; i < prototypeLayers.length; i++) {
			if (prototypeLayers[i] !== undefined) {
				for (let j = 0; j < prototypeLayers[i].length; j++) {
					const graphicIDIndex = prototypeGraphics.indexOf(prototypeLayers[i][j]);
					if (graphicIDIndex === -1) {
						throw new ManipulatorError('graphic id not found');
					}

					if (resultSequences[i] === undefined) {
						resultSequences[i] = [];
					}

					if (sourceGraphics[graphicIDIndex] === undefined) {
						throw new ManipulatorError('graphic id not found');
					}
					resultSequences[i][j] = sourceGraphics[graphicIDIndex];
				}
			}
		}
		return resultSequences;
	}

	private recursiveAccumulationGraphicID(accum: string[], componentStructure: AnyComponentStructure) {
		if (componentStructure.graphics) {
			accum.push(...componentStructure.graphics.map(graphic => graphic.id));
		}
		componentStructure.components?.forEach(component => {
			this.recursiveAccumulationGraphicID(accum, component);
		});
	}
}

export default ComponentBuilder;
