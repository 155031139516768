import React, { useMemo, useState } from 'react';
import { setIsAddRoleModalActive, setUntiengUserEmail } from '../../../../redux/reducers/usersReducer';
import MultipleSelectionBoard from '../../../ui/multipleSelectionBoard/MultipleSelectionBoard';
import Role from '../../../pages/personalAccount/personalData/role/Role';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import css from './multipleUserSelectionWindowWrapper.module.scss';
import { roleAPI } from '../../../../entities/role/api/api';
import { employeeAPI } from '../../../../entities/employee/api/api';
import { accountRolesAPI } from '../../../../entities/user/role/api/api';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';
import { teamTemplateAPI } from '../../../../entities/templates/team/api/api';
import { NewModal } from '../../../ui/new-modal';

const UserRolesSelection = () => {
	const dispatch = useAppDispatch();

	const email = useAppSelector(state => state.user.untiengUserEmail);
	const isActive = useAppSelector(state => state.user.modalsActive.isAddRoleModalActive);

	const { data: roles } = accountRolesAPI.useAvailableRolesQuery({
		email: email || '',
	}, { skip: !email?.length });

	const [bind] = roleAPI.useBindMutation();

	const [bindingIds, setBindingIds] = useState<string[]>([]);

	const handleAddRoleModal = (value: boolean) => dispatch(setIsAddRoleModalActive(value));

	// * При добавлении роли пользователю
	const onAddRole = async () => {
		if (bindingIds && email) {
			try {
				await bind({
					roles: bindingIds,
					email,
				})
					.unwrap();
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				dispatch(accountAPI.util?.invalidateTags(['account']));
				dispatch(employeeAPI.util?.invalidateTags(['employee']));
				dispatch(teamTemplateAPI.util?.invalidateTags(['team']));
				if (bindingIds.length > 1) {
					notificationSuccess('Вы успешно добавили роли пользователю!');
				} else {
					notificationSuccess('Вы успешно добавили роль пользователю!');
				}
			} catch (e) {
				notificationError('Произошла ошибка при добавлении роли пользователю...');
			} finally {
				dispatch(setIsAddRoleModalActive(false));
				dispatch(setUntiengUserEmail(null));
			}
		}
	};

	const items = useMemo(() => roles?.roles?.map(role => {
		const {
			id,
			name,
			background,
			emoji,
		} = role;

		return (
			{
				id,
				elem: (
					<Role
						emoji={emoji}
						text={name}
						bgColor={background}
						id={id}
						cssClass={css.role}
					/>
				),
			}
		);
	}), [roles]);

	return (
		<NewModal
			active={isActive}
			setActive={handleAddRoleModal}
		>
			<MultipleSelectionBoard
				btnText="Добавить"
				onBinding={onAddRole}
				items={items}
				bindingIds={bindingIds}
				setBindingIds={setBindingIds}
				notFoundText="Доступных ролей не найдено..."
				isNeedClean={isActive}
			/>
		</NewModal>
	);
};

export default UserRolesSelection;
