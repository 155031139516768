import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from '../createTagWindow/createTagWindow.module.scss';
import CreateInput from '../../createInput/CreateInput';
import Button, { buttonsTypes } from '../../../../../ui/button/button';
import { RootState } from '../../../../../../redux/reducers/reducer';
import CreateInputArrow from '../../createInputArrow/CreateInputArrow';
import { setIsCreatingTagGroupModalActive } from '../../../../../../redux/reducers/module/tagGroupReducer';
import { notificationError, notificationSuccess } from '../../../../../Notifications/callNotifcation';
import useCatchEnterClick from '../../../../../../hooks/useCatchEnterClick';
import { tagsGroupModalsActive } from '../../../../../../redux/selectors/module/tagGroup';
import { tagGroupAPI } from '../../../../../../entities/modules/tag-group/api/api';
import { tagAPI } from '../../../../../../entities/modules/tag/api/api';
import { NewModal } from '../../../../../ui/new-modal';
import { workshopModuleTagApi } from '../../../../../../entities/workshop-tag';

const CreateTagGroupWindow = () => {
	const isCreatingTagGroupModalActive = useSelector((
		state: RootState,
	) => tagsGroupModalsActive(state).isCreatingTagGroupModalActive);

	const dispatch = useDispatch();

	const [inputValue, setInputValue] = useState('');

	const [choicedTagId, setChoicedTagId] = useState<string[]>([]);

	// const { data: tagsData } = tagAPI.useGetAllQuery({
	// 	page: 1,
	// 	limit: 127,
	// });
	const { data: tags } = workshopModuleTagApi.useGetWorkshopTagsQuery({
		page: 1,
		limit: 127,
		search: '',
	});

	const [create] = tagGroupAPI.useCreateMutation();

	// * При добавлении элемента в список выбранных тегов
	const onAddChoicedTag = (id: string) => {
		setChoicedTagId(choicedId => [...choicedId, id]);
	};

	// * При удалении элемента из списка выбранных тегов
	const onRemoveChoicedTag = (id: string) => {
		setChoicedTagId(choicedId => choicedId.filter(item => item !== id));
	};

	const handleCreatingTagGroupModal = (value: boolean) => {
		dispatch(setIsCreatingTagGroupModalActive(value));
		if (!value) {
			setInputValue('');
			setChoicedTagId([]);
		}
	};

	const createTagGroup = async () => {
		const tags = choicedTagId.map(Number);

		try {
			await create({
				name: inputValue,
				tags,
			})
				.unwrap();
			setInputValue('');
			setChoicedTagId([]);
			dispatch(setIsCreatingTagGroupModalActive(false));
			notificationSuccess('Группа тегов создана успешно!', '', true);
		} catch (e) {
			notificationError('При создании группы тегов произошла ошибка...');
		}
	};

	// const preparedData = useMemo(() => tagsData?.moduleTags?.map(tagGroup => (
	// 	{
	// 		name: tagGroup.name,
	// 		id: tagGroup.id.toString(),
	// 	}
	// )), [tagsData]);
	const preparedData = useMemo(() => tags?.moduleTags?.map(tagGroup => (
		{
			name: tagGroup.name,
			id: tagGroup.id.toString(),
		}
	)), [tags]);

	useCatchEnterClick(createTagGroup, !!inputValue.length);

	return (
		<NewModal
			active={isCreatingTagGroupModalActive}
			setActive={handleCreatingTagGroupModal}
		>
			<div className={css.wrapper}>
				<div className={css.title}>
					Создать группу тегов
				</div>
				<CreateInput
					maxLength={20}
					text={inputValue}
					subTitle="Введите название"
					defaultValue="Название группы тегов"
					onChange={setInputValue}
				/>
				<CreateInputArrow
					text="Теги"
					inputData={preparedData}
					choicedItemsId={choicedTagId}
					onAddChoicedItem={onAddChoicedTag}
					onRemoveChoicedItem={onRemoveChoicedTag}
				/>
				<Button
					text="Создать"
					onClick={createTagGroup}
					buttonType={buttonsTypes.BRIGHT}
					cssClass={css.btn}
					inActive={!!inputValue.length}
				/>
			</div>
		</NewModal>
	);
};

export default CreateTagGroupWindow;
