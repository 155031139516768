import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useDeleteWorkshopTemplate = () => {
	const [deleteTemplate, { isLoading }] = workshopTemplateAPI.useDeleteWorkshopTemplateMutation();

	const onDeleteTemplate = async (templates: string[]) => {
		try {
			await deleteTemplate({ templates })
				.unwrap();
			notificationSuccess('Шаблон успешно удален!');
		} catch (e) {
			notificationError('Произошла ошибка при удалении шаблона...');
		}
	};

	return {
		isLoading,
		onDeleteTemplate,
	};
};
