import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { classNames } from '../../../../../shared/libs/classNames';
import { ITemplateModuleCategory } from '../../../../../entities/template-module-category';
import { TemplateModuleLikeableCard } from '../../../../../features/manage-template-module-category';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { templateModuleActions, templateModuleSelectors } from '../../../../../entities/template-module';
import css from '../bar/TemplateModuleCategoriesBar.module.scss';

interface TemplateModuleCategoriesListProps {
	className?: string,
	data?: ITemplateModuleCategory[]
}

export const TemplateModuleCategoriesList = ({ className, data }: TemplateModuleCategoriesListProps) => {
	const dispatch = useAppDispatch();

	const currentCategory = useSelector(templateModuleSelectors.getSelectedCategoryId);

	const handleCurrentCategory = (id: number) => {
		if (currentCategory === id) {
			dispatch(templateModuleActions.setSelectedCategoryId(undefined));
		} else {
			dispatch(templateModuleActions.setSelectedCategoryId(id));
		}
	};

	const sortedCategories = useMemo(
		() => [...(data || [])]
			.sort((a, b) => Number(a?.sequenceNumber || 0) - Number(b?.sequenceNumber || 0))
			.sort(a => (a.favorite ? -1 : 1)),
		[data],
	);

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			{
				data
					? (
						<div className={css.categories}>
							{
								sortedCategories?.map(category => {
									const { id } = category;

									return (
										<TemplateModuleLikeableCard
											key={category.id}
											data={category}
											onCategoryClick={handleCurrentCategory}
											isActive={id === currentCategory}
										/>
									);
								})
							}
						</div>
					) : <div className={css.not_found}><span>Категорий не найдено</span></div>
			}
		</div>
	);
};
