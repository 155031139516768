import React from 'react';
import cx from 'classnames';
import css from './slider.module.scss';
import { SwipeButton } from './swipe-button/swipe-button';
import { useSlider } from '../libs/useSlider';
import { TSwipeIconSize } from '../model/types';

interface SliderProps {
	height: number;
	children: React.ReactNode;
	controlSize: TSwipeIconSize;
	buttonScrollDistance?: number;
}

export const Slider = ({
	height,
	children,
	controlSize,
	buttonScrollDistance = 100,
}: SliderProps) => {
	const {
		sliderRef,
		sliderWrapperRef,
		controlsVisibility,
		handleNext,
		handlePrev,
	} = useSlider(buttonScrollDistance, children);

	return (
		<div className={css.slider_wrapper}>
			<div className={cx(css.left_btn_wrapper, !controlsVisibility.left && css.hidden_controls)}>
				<SwipeButton onClick={handlePrev} type="left" size={controlSize} />
			</div>
			<div
				className={cx(css.slider)}
				style={{ height: `${height}px` }}
				ref={sliderWrapperRef}
			>
				<div className={css.slider_content} ref={sliderRef}>
					{children}
				</div>
			</div>
			<div className={cx(css.right_btn_wrapper, !controlsVisibility.right && css.hidden_controls)}>
				<SwipeButton onClick={handleNext} type="right" size={controlSize} />
			</div>
		</div>
	);
};
