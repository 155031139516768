import React from 'react';
import css from './picture.module.scss';

interface TemplatePictureProps {
    preview: string | null,
}

export const TemplatePicture = ({ preview }: TemplatePictureProps) => (
	preview
		? (
			<div className={css.picture_wrapper}>
				<img src={`/api/file/${preview}`} alt="preview" />
			</div>
		)
		: (
			<div className={css.without_preview}>
				<img src="/logo.svg" alt="Пустой шаблон" />
			</div>
		)
);
