import PageGraphic from '../../graphic/page/PageGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import Dependent from '../../utils/dependent/Dependent';
import IComponent from '../../components/IComponent';
import IGraphic from '../../graphic/IGraphic';
import IMutablePagesComponentTree from '../../component-tree/IMutablePagesComponentTree';
import IFrameArea from '../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import Utils from '../../utils/impl/Utils';
import IDetectedGraphicOutlier from './IDetectedGraphicOutlier';
import ComponentOrganizerMutation from './mutations/ComponentOrganizerMutation';
import ComponentOrganizerGraphicMutation from './mutations/ComponentOrganizerGraphicMutation';
import ComponentFocusObserver from '../../utils/observers/ComponentFocusObserver';
import IGraphicFactory from '../../factories/graphic/IGraphicFactory';
import TableOrganizer from './TableOrganizer';
import TableComponent from '../../components/table/TableComponent';
import SketchComponentType from '../../components/SketchComponentType';
import GraphicType from '../../graphic/GraphicType';

interface IComponentOrganizerDependencies {
	graphicFactory: IGraphicFactory,
	focusObserver: ComponentFocusObserver,
	componentTree: IMutablePagesComponentTree,
}

/**
 * Механика, отвечающая за разделение компонентов в зависимости от позиции их графики,
 * чтобы компоненты всегда были привязаны к нужной странице с корректными координатами.
 */
class ComponentOrganizer extends Dependent<IComponentOrganizerDependencies> {
	private readonly tableOrganizer: TableOrganizer;

	constructor() {
		super();
		this.tableOrganizer = new TableOrganizer();
		this.addPostInjectDependenciesListener(dependencies => {
			this.tableOrganizer.connectDependencies({
				componentTree: dependencies.componentTree,
				graphicFactory: dependencies.graphicFactory,
			});
			this.tableOrganizer.injectDependencies();
		});
	}

	/**
	 * Производит проверку выхода графики за границу страницы по вертикальным и горизонтальным ребрам страницы.
	 * Если первая графика компонента вышла за пределы страницы - переносится весь компонент на ту страницу,
	 * на которую попала первая графика.
	 *
	 * Запускает процесс проверки только для компонентов, расположенных на листьях дерева.
	 */
	public sync = () => {
		const rootComponent = this.dependencies.componentTree.getRootComponent();
		const components = rootComponent.getComponents();
		if (components === null) {
			return;
		}

		const mutations: ComponentOrganizerMutation[] = [];

		components.forEach(component => {
			this.recursiveInspectPageOutside(component, mutations);
		});

		this.dependencies.componentTree.executeMutations(_ => {
			mutations.forEach(mutation => mutation.inspectPostMovePlace());
			mutations.forEach(mutation => mutation.run());
			this.dependencies.componentTree.mutateByRemoveEmptyUniterGraphics();

			this.dependencies.componentTree.syncUniterComponentsSize();

			this.reorganizeTables();
		});

		setTimeout(this.dependencies.focusObserver.sync, 0);
	};

	/**
	 * Рекурсивно проверяет компонент на выход за пределы страниц.
	 * @param component Проверяемый компонент.
	 * @param mutations
	 */
	private recursiveInspectPageOutside = (component: IComponent, mutations: ComponentOrganizerMutation[]) => {
		const childComponents = component.getComponents();

		if (childComponents === null) {
			this.inspectPageOutsideComponent(component, mutations);
			return;
		}

		childComponents.forEach(child => this.recursiveInspectPageOutside(child, mutations));
	};

	/**
	 * Проверяет компонент на выход за пределы страницы.
	 * @param component Проверяемый компонент.
	 * @param mutations
	 */
	private inspectPageOutsideComponent = (component: IComponent, mutations: ComponentOrganizerMutation[]) => {
		const graphics = component.getGraphics();

		if (component.type === SketchComponentType.TABLE) {
			const firstGraphic = component.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('first graphic not found');
			}

			const mutation = this.generateMutationByGraphic(component, firstGraphic);
			if (mutation === null) {
				return;
			}
			mutations.push(mutation);
			return;
		}

		graphics.forEach(graphic => {
			const mutation = this.generateMutationByGraphic(component, graphic);
			if (mutation === null) {
				return;
			}
			mutations.push(mutation);
		});
	};

	private generateMutationByGraphic = (
		component: IComponent,
		graphic: IGraphic,
	): ComponentOrganizerMutation | null => {
		const outlier = this.inspectGraphicVerticalOutside(component, graphic);
		if (outlier === null) {
			return null;
		}
		if (graphic.type === GraphicType.TABLE) {
			return new ComponentOrganizerGraphicMutation(
				graphic,
				outlier.moveOffset,
				component,
				this.dependencies.componentTree,
			);
		}
		return new ComponentOrganizerGraphicMutation(
			graphic,
			outlier.moveOffset,
			component,
			this.dependencies.componentTree,
		);
	};

	/**
	 * Проверка компонента с одной графикой на выход за пределы страницы.
	 * @param component Проверяемый компонент.
	 * @param graphic Проверяемая графика.
	 */
	private inspectGraphicVerticalOutside = (
		component: IComponent,
		graphic: IGraphic,
	): IDetectedGraphicOutlier | null => {
		const graphicDependPage = this.getDependGraphicToPage(graphic);
		if (graphicDependPage === null) {
			return null;
		}

		const currentPage = this.dependencies.componentTree.getPageFromGraphic(graphic);
		if (graphicDependPage === currentPage) {
			return null;
		}

		const moveOffset = this.getMoveGraphicOffset(currentPage, graphicDependPage);

		return { component, graphic, moveOffset };
	};

	/**
	 * Возвращает страницу, к которой в настоящий момент визуально относится графика.
	 * @param graphic Проверяемая графика.
	 */
	private getDependGraphicToPage = (graphic: IGraphic): PageGraphic | null => {
		// Получаем список всех страниц
		const pages = this.dependencies.componentTree.getPages();

		// Инициализирует Map'у для хранения страницы и площади компонента на ней
		const pageCrossedArea = new Map<PageGraphic, number>();
		pages.forEach(page => {
			// Получаем позицию и реальные размеры страницы
			const pagePosition = page.getGlobalPosition();
			const pageWidth = page.getRealWidth();
			const pageHeight = page.getRealHeight();

			// Получаем позицию и конфигурацию графики
			const graphicPosition = graphic.getGlobalPosition();
			const graphicConfiguration = graphic.getFrameConfiguration();

			const pageFrameArea: IFrameArea = {
				x: pagePosition.x,
				y: pagePosition.y,
				width: pageWidth,
				height: pageHeight,
				rotate: 0,
			};
			const graphicFrameArea: IFrameArea = {
				x: graphicPosition.x,
				y: graphicPosition.y,
				width: graphicConfiguration.width,
				height: graphicConfiguration.height,
				rotate: 0,
			};

			const crossedArea = Utils.Geometry.intersectArea(pageFrameArea, graphicFrameArea);
			pageCrossedArea.set(page, crossedArea);
		});

		const isNotCrossed = Array.from(pageCrossedArea.values()).every(area => area === 0);
		if (isNotCrossed) {
			return null;
		}

		let maxCrossPage: PageGraphic = pages[0];
		let maxCrossArea = 0;
		pageCrossedArea.forEach((area, page) => {
			if (area > maxCrossArea) {
				maxCrossPage = page;
				maxCrossArea = area;
			}
		});
		return maxCrossPage;
	};

	/**
	 * Возвращает сдвиг офсета для графики от текущего значения для перемещения на страницу `graphicDependPage`.
	 * @param currentPage Текущая страница, на которой расположена графика.
	 * @param graphicDependPage Страница, на которую должна попасть графика после изменения офсета.
	 */
	private getMoveGraphicOffset = (currentPage: PageGraphic, graphicDependPage: PageGraphic): number => {
		if (currentPage === graphicDependPage) {
			throw new ManipulatorError('page not change');
		}

		const pages = this.dependencies.componentTree.getPages();
		const currentPageIndex = pages.indexOf(currentPage);
		if (currentPageIndex === -1) {
			throw new ManipulatorError('current page not found');
		}
		const graphicDependPageIndex = pages.indexOf(graphicDependPage);
		if (graphicDependPageIndex === -1) {
			throw new ManipulatorError('graphic depend not found');
		}

		return graphicDependPageIndex - currentPageIndex;
	};

	private reorganizeTables = () => {
		const tables = this.dependencies.componentTree.getUniformComponents<TableComponent>(SketchComponentType.TABLE);
		if (tables === null) {
			return;
		}

		for (let i = 0; i < tables.length; i++) {
			const table = tables[i];
			setTimeout(this.tableOrganizer.reorganize.bind(this, table), 0);
			setTimeout(this.tableOrganizer.reorganize.bind(this, table), 100);
		}
	};
}

export default ComponentOrganizer;
