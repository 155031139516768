import { isEmpty } from 'lodash';
import GraphicComponent from '../GraphicComponent';
import SketchComponentType from '../SketchComponentType';
import TableGraphic from '../../graphic/table/TableGraphic';
import TableCell from '../../graphic/table/cells/TableCell';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import TableGridAreas, { TableGridMap } from '../../graphic/table/TableGridAreas';
import IComponentContainingText from '../IComponentContainingText';
import Editor from '../../mechanics/mext/editor';
import SpatialTableCellArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';
import { AnySpatialArea } from '../../Types';
import ITableComponentTexture from './ITableComponentTexture';
import Utils from '../../utils/impl/Utils';
import ITableRowProperty from './ITableRowProperty';

/**
 * Компонент для отображения таблиц.
 */
class TableComponent
	extends GraphicComponent<ITableComponentTexture, TableGraphic>
	implements IComponentContainingText {
	public readonly type: SketchComponentType = SketchComponentType.TABLE;

	private readonly DEFAULT_ROW_HEIGHT = 32;
	private readonly DEFAULT_ROW_MARGIN = 20;

	private tableCells: TableCell[] | null;
	private gridAreas: TableGridAreas;
	private postCellInputExternalListeners: VoidFunction[];

	private columnCount: number;

	private borderColor: string;
	private columnMultipliers: number[];
	private rowsMultipliers: number[] | null;

	constructor() {
		super();
		this.columnCount = 0;
		this.borderColor = '';
		this.tableCells = null;
		this.rowsMultipliers = [];
		this.columnMultipliers = [];
		this.gridAreas = new TableGridAreas();
		this.postCellInputExternalListeners = [];
	}

	/**
	 * Устанавливает множители ширины колонок.
	 * @param multipliers множители.
	 */
	public setColumnMultipliers = (multipliers: number[]) => {
		const graphics = this.getGraphics();
		this.columnMultipliers = multipliers;
		graphics.forEach(graphic => graphic.setColumnMultipliers(multipliers));
	};

	public getEditors = (): Editor[] => {
		if (this.tableCells === null) {
			throw new ManipulatorError('the table cells not initialized');
		}

		return this.tableCells.map(cell => cell.getEditor());
	};

	/**
	 * Устанавливает цвет обводки ячеек и таблицы всей зависимой графике.
	 * @param color цвет обводки.
	 */
	public setBorderColor = (color: string) => {
		this.setTexture(prev => ({
			...prev,
			borderColor: color,
		}));
	};

	/**
	 * Возвращает все пространственные области таблицы.
	 * Перегрузка вызвана добавлением областей ячеек.
	 */
	public override getSpatialAreas = (): AnySpatialArea[] => {
		const areas: AnySpatialArea[] = [];
		const graphics = this.getGraphics();
		const cellAreas = this.getCellAreas();

		areas.push(
			...graphics.map(graphic => graphic.getSpatialAreas()).flat(),
			...cellAreas,
		);

		return areas;
	};

	/**
	 * Возвращает множители ширины колонок.
	 */
	public getColumnMultipliers = (): number[] => {
		if (this.columnMultipliers === null) {
			throw new ManipulatorError('the column multipliers not initialized');
		}
		return [...this.columnMultipliers];
	};

	/**
	 * Возвращает множители ширины строк.
	 */
	public getRowsMultipliers = (): number[] => {
		if (this.rowsMultipliers === null) {
			throw new ManipulatorError('the rows multipliers not initialized');
		}
		return [...this.rowsMultipliers];
	};

	/**
	 * Возвращает базовую ширину колонки таблицы.
	 * Используется для вычисления фактической ширины колонки по множителю.
	 */
	public getDefaultColumnWidth = (): number => {
		if (this.columnCount === null) {
			throw new ManipulatorError('the column count not initialized');
		}

		const { graphics } = this.getStructure();
		if (graphics === null) {
			throw new ManipulatorError('graphics can not be null');
		}

		const firstGraphic = graphics[0];
		if (firstGraphic === undefined) {
			throw new ManipulatorError('first graphic not found');
		}

		const instanceGraphics = this.getGraphics();
		const firstInstanceGraphic = instanceGraphics[0];
		if (firstInstanceGraphic === undefined) {
			throw new ManipulatorError('first instance graphic not found');
		}

		const frameConfiguration = firstInstanceGraphic.getFrameConfiguration();

		return frameConfiguration.width / this.columnCount;
	};

	/**
	 * Возвращает базовую высоту строки таблицы.
	 * Используется для вычисления фактической высоты строки по множителю.
	 */
	public getDefaultRowHeight = (): number => this.DEFAULT_ROW_HEIGHT;

	public getColumnCount = (): number => {
		if (this.columnCount === null) {
			throw new ManipulatorError('the column count not initialized');
		}
		return this.columnCount;
	};

	public getRowCount = (): number => {
		const graphics = this.getGraphics() as TableGraphic[];

		let count = 0;
		for (let i = 0; i < graphics.length; i++) {
			count += graphics[i].getRowsCount();
		}

		return count;
	};

	public getBorderColor = () => this.borderColor;

	/**
	 * Возвращает словарь, в котором key - идентификатор ячейки, а value - сам объект ячейки.
	 * @param cells - структуры ячеек, по которым будет генерироваться словарь.
	 */
	public getMapIdCell = (cells: TableCell[]): Map<string, TableCell> => {
		const map = new Map<string, TableCell>();
		cells.forEach(cell => map.set(cell.getID(), cell));
		return map;
	};

	public getFocusCells = (): TableCell[] | null => {
		if (this.tableCells === null) {
			return null;
		}

		const focusCells = this.tableCells.filter(cell => cell.hasFocus());
		return focusCells.length === 0 ? null : focusCells;
	};

	/**
	 * Находит наибольший индекс колонки (индекс самой правой колонки) и возвращает все ячейки с этим индексом либо
	 * null если ячеек в фокусе нет.
	 */
	public getRightmostColumnIndex = (): number | null => {
		const focusCells = this.getFocusCells();
		if (focusCells === null) return null;
		let rightmostIndex = 0;
		focusCells.forEach(cell => {
			const cellColumn = cell.getColumn();
			const cellColumnSpan = cell.getColumnSpan();
			const index = cellColumn + cellColumnSpan - 1;

			rightmostIndex = index > rightmostIndex ? index : rightmostIndex;
		});
		return rightmostIndex;
	};

	/**
	 * Находит наименьший индекс колонки (индекс самой левой колонки) и возвращает все ячейки с этим индексом либо
	 * null если ячеек в фокусе нет.
	 */
	public getLeftmostColumnIndex = (): number | null => {
		const focusCells = this.getFocusCells();
		if (focusCells === null) return null;
		let leftmostIndex = Number.MAX_SAFE_INTEGER;
		focusCells.forEach(cell => {
			const cellColumn = cell.getColumn();
			leftmostIndex = cellColumn < leftmostIndex ? cellColumn : leftmostIndex;
		});

		return leftmostIndex;
	};

	/**
	 * Находит наибольший индекс строки (индекс самой нижней строки) и возвращает все ячейки с этим индексом либо
	 * null если ячеек в фокусе нет.
	 */
	public getLowestIndex = (): number | null => {
		let lowestIndex = 0;
		const focusCells = this.getFocusCells();
		if (focusCells === null) return null;
		focusCells.forEach(cell => {
			const cellRowSpan = cell.getTexture().rowSpan;
			const cellRow = cell.getTexture().row + cellRowSpan - 1;
			lowestIndex = cellRow > lowestIndex ? cellRow : lowestIndex;
		});

		return lowestIndex;
	};

	public getHighestIndex = (): number | null => {
		let highetsIndex = Number.MAX_SAFE_INTEGER;
		const focusCells = this.getFocusCells();
		if (focusCells === null) return null;
		focusCells.forEach(cell => {
			const cellRow = cell.getTexture().row;
			highetsIndex = cellRow < highetsIndex ? cellRow : highetsIndex;
		});

		return highetsIndex;
	};

	/**
	 * Синхронизирует высоту фреймов в соответствии с высотой каждой графики.
	 */
	public syncGraphicsHeight = () => {
		const graphics = this.getGraphics() as TableGraphic[];
		graphics.forEach(graphic => {
			if (!graphic.isConnected()) {
				return;
			}
			const realHeight = graphic.getRealHeight();
			graphic.setFrameConfiguration(prev => ({
				...prev,
				height: realHeight,
			}));
		});
	};

	/**
	 * Синхронизирует реальную высоту строки с высотой в памяти по минимальной высоте контента внутри строки.
	 */
	public syncRealRowHeight = () => {
		if (this.rowsMultipliers === null) {
			throw new ManipulatorError('rows multipliers not initialized');
		}

		const cellsFromRow: TableCell[][] = this.getCellsFromRow();
		const rowCount = this.getRowCount();

		if (this.rowsMultipliers.length !== rowCount) {
			throw new ManipulatorError('invalid row heights count');
		}

		const defaultHeight = this.getDefaultRowHeight();
		const rowsMultipliers = [...this.rowsMultipliers];

		let isChanged = false;

		for (let i = 0; i < rowCount; i++) {
			// Если у строки нет ячеек, то пропускаем (это возможно при наличии у ячеек выше rowSpan)
			if (cellsFromRow[i] !== undefined) {
				const realRowHeight = this.calculateRealRowHeight(i);
				if (rowsMultipliers[i] * defaultHeight !== realRowHeight) {
					rowsMultipliers[i] = realRowHeight / defaultHeight;
					isChanged = true;
				}
			}
		}

		if (isChanged) {
			this.setRowMultipliers(rowsMultipliers);
		}
	};

	/**
	 * Возвращает коллекцию высот каждой строки таблицы в пикселях.
	 */
	public getRowProperties = (): ITableRowProperty[] => {
		const list: ITableRowProperty[] = [];
		const cells = this.getCells();
		const mapIdCell = this.getMapIdCell(cells);
		const leftExtremeIdList = this.gridAreas.getLeftExtremeIDList();

		leftExtremeIdList.forEach(id => {
			const cell = mapIdCell.get(id);
			if (cell === undefined) {
				throw new ManipulatorError('cell not found');
			}
			const height = cell.getHeight();
			const rowSpan = cell.getRowSpan();
			list.push({
				height,
				rowSpan,
			});
		});

		return list;
	};

	/**
	 * Обновляет ячейки в соответствии с исходной структурой.
	 */
	public loadCells = (cellTextures: ITableCellTexture[], columnCount: number) => {
		const cells: TableCell[] = [];

		cellTextures.forEach(cellTexture => {
			const cell = new TableCell();

			cell.setID(cellTexture.id);
			cell.setRow(cellTexture.row);
			cell.setColumn(cellTexture.column);
			cell.setContent(cellTexture.content);
			cell.setRowSpan(cellTexture.rowSpan);
			cell.setBackground(cellTexture.background);
			cell.setColumnSpan(cellTexture.columnSpan);

			cell.addPostChangeModelListener(this.postCellInputListener);

			cells.push(cell);
		});

		this.tableCells = [...cells];
		this.columnCount = columnCount;
		this.gridAreas.loadCells(cells, columnCount);
	};

	/**
	 * Перерисовывает ячейки в каждой графике.
	 */
	public renderCells = () => {
		const graphics = this.getGraphics() as TableGraphic[];
		graphics.forEach(graphic => {
			const rowMultipliers = this.calculateRowMultipliersForGraphic(graphic);

			graphic.setBorderColor(this.borderColor);
			graphic.setColumnMultipliers(this.columnMultipliers);
			graphic.setRowsMultipliers(rowMultipliers);
			graphic.renderCellLayer();
		});
	};

	public getCells = (): TableCell[] => {
		if (this.tableCells === null) {
			throw new ManipulatorError('the table cells not initialized');
		}
		return [...this.tableCells];
	};

	/**
	 * Перемещает фокус ячейки на ячейку выше.
	 * Переключает фокус на ячейку, отсчитывая от самой правой.
	 */
	public moveFocusCellUp = () => {
		const targetCell = this.getStartFocusCell();
		if (targetCell === null) {
			return;
		}

		const leftCell = this.getTopCell(targetCell);
		if (leftCell === null) {
			return;
		}

		this.disableCellFocus();
		leftCell.enableFocus();
		leftCell.markStartFocus();

		const cellEditor = leftCell.getEditor();
		cellEditor.focus();
	};

	/**
	 * Перемещает фокус ячейки таблицы на ячейку ниже.
	 */
	public moveFocusCellDown = () => {
		const targetCell = this.getStartFocusCell();
		if (targetCell === null) {
			return;
		}

		const leftCell = this.getBottomCell(targetCell);
		if (leftCell === null) {
			return;
		}

		this.disableCellFocus();
		leftCell.enableFocus();
		leftCell.markStartFocus();

		const cellEditor = leftCell.getEditor();
		cellEditor.focus();
	};

	/**
	 * Перемещает фокус ячейки таблицы на ячейку левее.
	 */
	public moveFocusCellLeft = () => {
		const targetCell = this.getStartFocusCell();
		if (targetCell === null) {
			return;
		}

		const leftCell = this.getLeftCell(targetCell);
		if (leftCell === null) {
			return;
		}

		this.disableCellFocus();
		leftCell.enableFocus();
		leftCell.markStartFocus();

		const cellEditor = leftCell.getEditor();
		cellEditor.focus();
	};

	/**
	 * Перемещает фокус ячейки таблицы на ячейку правее.
	 */
	public moveFocusCellRight = () => {
		const targetCell = this.getStartFocusCell();
		if (targetCell === null) {
			return;
		}

		const leftCell = this.getRightCell(targetCell);
		if (leftCell === null) {
			return;
		}

		this.disableCellFocus();
		leftCell.enableFocus();
		leftCell.markStartFocus();

		const cellEditor = leftCell.getEditor();
		cellEditor.focus();
	};

	public getGridAreas = (): TableGridMap => this.gridAreas.getMap();

	public getTexture = (): ITableComponentTexture => ({
		columnCount: this.columnCount,
		borderColor: this.borderColor,
		columnMultipliers: this.columnMultipliers,
		rowMultipliers: this.rowsMultipliers,
		cells: this.tableCells === null
			? []
			: this.tableCells.map(cell => cell.getTexture()),
	});

	public getUniqueTexture = (): ITableComponentTexture => {
		if (
			this.tableCells === null
			|| this.columnCount === null
			|| this.columnMultipliers === null
			|| this.borderColor === null
		) {
			throw new ManipulatorError('table not initialized');
		}

		return {
			columnCount: this.columnCount,
			borderColor: this.borderColor,
			columnMultipliers: this.columnMultipliers,
			rowMultipliers: this.rowsMultipliers,
			cells: this.tableCells.map(cell => cell.getTexture()).map(texture => ({
				...texture,
				id: Utils.Generate.UUID4(),
				content: {
					...texture.content,
					id: Utils.Generate.UUID4(),
				},
			})),
		};
	};

	public setTexture = (fn: (prev: ITableComponentTexture) => ITableComponentTexture) => {
		const current = this.getTexture();
		const {
			cells, columnMultipliers, columnCount, borderColor, rowMultipliers,
		} = fn(current);

		this.rowsMultipliers = rowMultipliers;
		this.columnCount = columnCount;
		this.borderColor = borderColor;
		this.columnMultipliers = columnMultipliers;

		this.loadCells(cells, columnCount);
		this.renderCells();

		if (rowMultipliers === null) {
			setTimeout(this.initializeRowMultipliers, 0);
		}
	};

	/**
	 * Включает фокус у ячейки по её идентификатору и снимает его со всех остальных.
	 * В случае отсутствия ячейки метод возвращает `false`.
	 * @param cellID Идентификатор ячейки.
	 */
	public focusOnlyCellByID = (cellID: string): boolean => {
		if (this.tableCells === null) {
			return false;
		}

		const targetCell = this.tableCells.find(cell => cell.getID() === cellID);
		if (targetCell === undefined) {
			return false;
		}

		this.tableCells.forEach(cell => {
			if (cell === targetCell) {
				cell.enableFocus();
				cell.enableMutationMode();
				cell.enableTextFocus();
				return;
			}
			cell.disableFocus();
		});
		return true;
	};

	public setRowMultipliers = (multipliers: number[]) => {
		this.rowsMultipliers = multipliers;
		const graphics = this.getGraphics();
		graphics.forEach(graphic => {
			const rowMultipliers = this.calculateRowMultipliersForGraphic(graphic);
			graphic.setRowsMultipliers(rowMultipliers);
		});
	};

	/**
	 * Устанавливает количество строк для графики, в которой находится указанная строка.
	 * @param rowIndex - номер строки, необходимый для нахождения нужной графики
	 * @param count - количество строк, которое необходимо установить графике
	 */
	public setRowCount = (rowIndex: number, count: number) => {
		const graphics = this.getGraphics();
		graphics.forEach(graphic => {
			const startRow = graphic.getStartRow();
			const rowCount = graphic.getRowsMultipliers().length;
			if (rowIndex >= startRow && rowIndex <= rowCount + startRow - 1) {
				graphic.setRowCount(count);
			}
		});
	};

	public addPostCellInputListener = (listener: VoidFunction) => {
		this.postCellInputExternalListeners.push(listener);
	};

	/**
	 * Вычисляет и возвращает массив множителей строк для переданной графики таблицы.
	 * @param graphic графика таблицы, для которой необходимо вычислить множитель строк
	 * @return number[] - возвращает массив множителей строк для графики таблицы
	 */
	public calculateRowMultipliersForGraphic = (graphic: TableGraphic) : number[] | null => {
		const texture = graphic.getTexture();
		const { startRow, rowCount } = texture;
		if (this.rowsMultipliers === null) {
			return null;
		}
		return this.rowsMultipliers.slice(startRow, startRow + rowCount);
	};

	private getCellAreas = (): SpatialTableCellArea[] => {
		if (this.tableCells === null) {
			throw new ManipulatorError('table cell not initialized');
		}

		const areas: SpatialTableCellArea[] = [];
		const graphicCellsMap = this.getGraphicCellsMap();

		graphicCellsMap.forEach((graphic, cell) => {
			areas.push(new SpatialTableCellArea(this, cell, graphic));
		});

		return areas;
	};

	/**
	 * Возвращает карту графики с ячейки, где ключ - ячейка, а значение - графика, где она визуализируется.
	 */
	private getGraphicCellsMap = (): Map<TableCell, TableGraphic> => {
		const map: Map<TableCell, TableGraphic> = new Map();
		const graphics = this.getGraphics();

		graphics.forEach(graphic => {
			const cells = graphic.getCells();
			cells.forEach(cell => {
				map.set(cell, graphic);
			});
		});

		return map;
	};

	/**
	 * В случае наличия выделенных ячеек возвращает ячейку, с которой началось выделение.
	 */
	private getStartFocusCell = (): TableCell | null => {
		const focusCells = this.getFocusCells();
		if (focusCells === null) {
			return null;
		}

		const startFocusCell = focusCells.find(cell => cell.getIsStartFocus());
		return startFocusCell === undefined ? null : startFocusCell;
	};

	/**
	 * Снимает фокус со всех ячеек.
	 */
	private disableCellFocus = () => {
		const focusCells = this.getFocusCells();
		if (focusCells === null) {
			return;
		}

		focusCells.forEach(cell => cell.disableFocus());
	};

	/**
	 * Возвращает левую ячейку от исходной.
	 * @param targetCell Исходная ячейка.
	 */
	private getLeftCell = (targetCell: TableCell): TableCell | null => {
		const { column, row } = targetCell.getTexture();
		const gridAreas = this.getGridAreas();

		const cellID = gridAreas[row][column - 1];
		if (cellID === undefined) {
			return null;
		}
		return this.getCellFromID(cellID);
	};

	/**
	 * Возвращает правую ячейку от исходной.
	 * @param targetCell Исходная ячейка.
	 */
	private getRightCell = (targetCell: TableCell): TableCell | null => {
		const { column, columnSpan, row } = targetCell.getTexture();
		const gridAreas = this.getGridAreas();

		const cellID = gridAreas[row][column + columnSpan];
		if (cellID === undefined) {
			return null;
		}
		return this.getCellFromID(cellID);
	};

	/**
	 * Возвращает верхнюю ячейку от исходной.
	 * @param targetCell Исходная ячейка.
	 */
	private getTopCell = (targetCell: TableCell): TableCell | null => {
		const { column, row } = targetCell.getTexture();
		if (row === 0) {
			return null;
		}

		const gridAreas = this.getGridAreas();

		const cellID = gridAreas[row - 1][column];
		if (cellID === undefined) {
			return null;
		}
		return this.getCellFromID(cellID);
	};

	/**
	 * Возвращает нижнюю ячейку от исходной.
	 * @param targetCell Исходная ячейка.
	 */
	private getBottomCell = (targetCell: TableCell): TableCell | null => {
		const { column, row, rowSpan } = targetCell.getTexture();
		if (row + rowSpan >= this.getRowCount()) {
			return null;
		}

		const gridAreas = this.getGridAreas();

		const cellID = gridAreas[row + rowSpan][column];
		if (cellID === undefined) {
			return null;
		}
		return this.getCellFromID(cellID);
	};

	/**
	 * Возвращает ячейку по её идентификатору.
	 * @param id Идентификатор ячейки.
	 */
	private getCellFromID = (id: string): TableCell | null => {
		if (this.tableCells === null) {
			throw new ManipulatorError('cells not found');
		}

		const cell = this.tableCells.find(cell => cell.getID() === id);
		return cell === undefined ? null : cell;
	};

	private postCellInputListener = () => {
		setTimeout(this.syncGraphicsHeight.bind(this), 0);
		setTimeout(this.syncRealRowHeight.bind(this), 0);
		this.callPostCellInputExternalListeners();
	};

	private callPostCellInputExternalListeners = () => {
		this.postCellInputExternalListeners.forEach(listener => listener());
	};

	/**
	 * Вычисляет и возвращает высоту строки на основе контента внутри, включая отступ.
	 * @param row Строк, а высоту которой необходимо вычислить
	 */
	private calculateRealRowHeight = (row: number) : number => {
		const cellsFromRow: TableCell[][] = this.getCellsFromRow();
		const height = Math.max(...cellsFromRow[row].map(cell => {
			const element = cell.getElement();
			const children = element.children[element.children.length - 1];
			return children.scrollHeight;
		}));
		return height + this.DEFAULT_ROW_MARGIN;
	};

	/**
	 * Вычисляет и возвращает массив ячеек по строкам.
	 * @return TableCell[][] Возвращает массив ячеек для каждой строки
	 */
	private getCellsFromRow = () : TableCell[][] => {
		const cells = this.getCells();

		const cellsFromRow: TableCell[][] = [];
		cells.forEach((cell => {
			const rowSpan = cell.getRowSpan();
			// if (rowSpan !== 1) {
			// 	return;
			// }
			const row = cell.getRow();

			if (cellsFromRow[row] === undefined) {
				cellsFromRow[row] = [];
			}

			cellsFromRow[row].push(cell);
		}));
		return cellsFromRow;
	};

	/**
	 * Инициализирует множители высоты строк, если изначально таблица была сохранена без них,
	 * например в старых версиях шаблонов, в которых ещё не был предусмотрен функционал высоты строк.
	 */
	private initializeRowMultipliers = () => {
		const defaultHeight = this.getDefaultRowHeight();
		const rowsProperties = this.getRowProperties();
		const multipliers: number[] = [];

		rowsProperties.forEach(property => multipliers.push(property.height / defaultHeight));

		this.rowsMultipliers = multipliers;

		this.renderCells();
	};
}

export default TableComponent;
