import SpatialQuadrantsNode from './SpatialQuadrantsNode';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import IFrameArea from './spatial-area/IFrameArea';
import { AnySpatialArea } from '../../../Types';
import Dependent from '../../../utils/dependent/Dependent';

/** Древовидное пространственное представление области, оптимизированное для нахождения пересечений
 * координат с объектом. Дерево квадрантов (также квадродерево, 4-дерево, англ. quadtree) —
 * дерево, в котором у каждого внутреннего узла ровно 4 потомка. Деревья квадрантов часто используются
 * для рекурсивного разбиения двухмерного пространства по 4 квадранта (области). Области представляют
 * собой квадраты, прямоугольники или имеют произвольную форму. Аналогичное разбиение пространства
 * известно как Q-дерево.
 * Сложность поиска в среднем случае - O(logMn), в наихудшем - 	O(n).
 */
class SpatialQuadrantsTree<Dependencies> extends Dependent<Dependencies> {
	private readonly rootNode: SpatialQuadrantsNode;

	constructor() {
		super();
		this.rootNode = new SpatialQuadrantsNode(0, 0, 0, 0);
	}

	/** Согласует структуру дерева с реальным состоянием областей. */
	protected reconciliate = (controlArea: IFrameArea, objects: AnySpatialArea[]) => {
		this.clear();

		this.rootNode.setWidth(controlArea.width);
		this.rootNode.setHeight(controlArea.height);

		const frames: IFrameArea[] = [];
		for (let i = 0; i < objects.length; i++) {
			const frameArea = objects[i].getGlobalFrameArea();
			if (frameArea.width !== 0 && frameArea.height !== 0) {
				frames.push(frameArea);
			} else {
				console.warn(`invalid areas were missed: type-${objects[i].type}`);
			}
		}

		const minQuadrantSize = this.getMinQuadrantSize(frames);

		for (let i = 0; i < frames.length; i++) {
			this.splitQuadrant(this.rootNode, frames[i], objects[i], minQuadrantSize);
		}
	};

	/** Очищает дерево от всех узлов, кроме корневого. */
	protected clear = () => {
		this.rootNode.clear();
	};

	protected getNodes = (): SpatialQuadrantsNode[] => {
		const nodes: SpatialQuadrantsNode[] = [];
		this.callEveryNode(node => nodes.push(node));
		return nodes;
	};

	protected getAreas = (): AnySpatialArea[] => this.rootNode.objects;

	/** Возвращает все пересекаемые области в данной позиции. */
	protected getCrossAreas = (position: IDescartesPosition): AnySpatialArea[] | null => {
		const controlNode = this.getDeepestNode(this.rootNode, position);
		if (controlNode === null || controlNode.objects.length === 0) {
			return null;
		}

		const areas: AnySpatialArea[] = [];
		for (let i = 0; i < controlNode.objects.length; i++) {
			const obj = controlNode.objects[i];
			const area = obj.getGlobalFrameArea();
			const isCross = this.isCrossAreaPoint(area, position);
			if (isCross) {
				areas.push(obj);
			}
		}
		if (areas.length === 0) {
			return null;
		}
		return areas;
	};

	private isCrossAreaPoint = (area: IFrameArea, position: IDescartesPosition) => {
		if (position.x < area.x || position.x > area.x + area.width) {
			return false;
		}
		return !(position.y < area.y || position.y > area.y + area.height);
	};

	private getDeepestNode = (
		node: SpatialQuadrantsNode,
		position: IDescartesPosition,
	): SpatialQuadrantsNode | null => {
		if (node.leftTopQuadrant && node.leftTopQuadrant.isCrossPoint(position)) {
			return this.getDeepestNode(node.leftTopQuadrant, position);
		}
		if (node.rightTopQuadrant && node.rightTopQuadrant.isCrossPoint(position)) {
			return this.getDeepestNode(node.rightTopQuadrant, position);
		}
		if (node.rightBottomQuadrant && node.rightBottomQuadrant.isCrossPoint(position)) {
			return this.getDeepestNode(node.rightBottomQuadrant, position);
		}
		if (node.leftBottomQuadrant && node.leftBottomQuadrant.isCrossPoint(position)) {
			return this.getDeepestNode(node.leftBottomQuadrant, position);
		}

		return node;
	};

	private callEveryNode = (fn: (node: SpatialQuadrantsNode) => void) => {
		this.callNode(this.rootNode, fn);
	};

	private callNode = (node: SpatialQuadrantsNode, fn: (node: SpatialQuadrantsNode) => void) => {
		fn(node);
		if (node.leftTopQuadrant !== null) {
			this.callNode(node.leftTopQuadrant, fn);
		}
		if (node.rightTopQuadrant !== null) {
			this.callNode(node.rightTopQuadrant, fn);
		}
		if (node.rightBottomQuadrant !== null) {
			this.callNode(node.rightBottomQuadrant, fn);
		}
		if (node.leftBottomQuadrant !== null) {
			this.callNode(node.leftBottomQuadrant, fn);
		}
	};

	private getMinQuadrantSize = (frameAreas: IFrameArea[]): number => {
		let min = Number.MAX_VALUE;
		for (let i = 0; i < frameAreas.length; i++) {
			const area = frameAreas[i];
			if (min > area.width) {
				min = area.width;
			}
			if (min > area.height) {
				min = area.height;
			}
		}
		return min;
	};

	private splitQuadrant = (
		quadrant: SpatialQuadrantsNode,
		area: IFrameArea,
		obj: AnySpatialArea,
		minQuadrantSize: number,
	) => {
		const isCross = this.isCrossQuadrantArea(quadrant, area);

		if (!isCross) {
			return;
		}

		quadrant.addObject(obj);

		const isSplit = quadrant.split(minQuadrantSize);
		if (!isSplit) {
			return;
		}
		if (quadrant.leftTopQuadrant !== null && this.isCrossQuadrantArea(quadrant.leftTopQuadrant, area)) {
			this.splitQuadrant(quadrant.leftTopQuadrant, area, obj, minQuadrantSize);
		}
		if (quadrant.rightTopQuadrant !== null && this.isCrossQuadrantArea(quadrant.rightTopQuadrant, area)) {
			this.splitQuadrant(quadrant.rightTopQuadrant, area, obj, minQuadrantSize);
		}
		if (quadrant.rightBottomQuadrant !== null && this.isCrossQuadrantArea(quadrant.rightBottomQuadrant, area)) {
			this.splitQuadrant(quadrant.rightBottomQuadrant, area, obj, minQuadrantSize);
		}
		if (quadrant.leftBottomQuadrant !== null && this.isCrossQuadrantArea(quadrant.leftBottomQuadrant, area)) {
			this.splitQuadrant(quadrant.leftBottomQuadrant, area, obj, minQuadrantSize);
		}
	};

	private isCrossQuadrantArea = (quadrant: SpatialQuadrantsNode, area: IFrameArea): boolean => {
		const rect1 = quadrant.getArea();
		const rect2 = area;

		// Получить точки четырех углов треугольника
		const corners1 = this.getCorners(rect1);
		const corners2 = this.getCorners(rect2);

		// Повернуть углы первого прямоугольника, если он имеет угол
		if (rect1.rotate !== 0) {
			this.rotateCorners(corners1, rect1.rotate, { x: rect1.x, y: rect1.y });
		}

		// Повернуть углы второго прямоугольника, если он имеет угол
		if (rect2.rotate !== 0) {
			this.rotateCorners(corners2, rect2.rotate, { x: rect2.x, y: rect2.y });
		}

		// Проверить, находится ли какой-либо из углов первого прямоугольника внутри второго прямоугольника
		for (let i = 0; i < corners1.length; i++) {
			const corner = corners1[i];
			if (this.isPointInsideRectangle(corner, rect2)) {
				return true;
			}
		}

		// Проверить, находится ли какой-либо из углов второго прямоугольника внутри первого прямоугольника
		for (let i = 0; i < corners2.length; i++) {
			const corner = corners2[i];
			if (this.isPointInsideRectangle(corner, rect1)) {
				return true;
			}
		}

		// Проверить, пересекаются ли ребра первого прямоугольника с любыми ребрами второго прямоугольника
		for (let i = 0; i < corners1.length; i++) {
			const corner1 = corners1[i];
			const corner2 = corners1[(i + 1) % corners1.length];
			for (let j = 0; j < corners2.length; j++) {
				const corner3 = corners2[j];
				const corner4 = corners2[(j + 1) % corners2.length];
				if (this.doLinesIntersect(corner1, corner2, corner3, corner4)) {
					return true;
				}
			}
		}

		return false;
	};

	private getCorners = (rect: IFrameArea): IDescartesPosition[] => {
		const corners = [];
		corners.push({ x: rect.x, y: rect.y });
		corners.push({ x: rect.x + rect.width, y: rect.y });
		corners.push({ x: rect.x + rect.width, y: rect.y + rect.height });
		corners.push({ x: rect.x, y: rect.y + rect.height });
		return corners;
	};

	private rotateCorners = (corners: IDescartesPosition[], angle: number, center: IDescartesPosition): void => {
		const cos = Math.cos(angle);
		const sin = Math.sin(angle);

		for (let i = 0; i < corners.length; i++) {
			const corner = corners[i];
			const x = corner.x - center.x;
			const y = corner.y - center.y;
			corner.x = x * cos - y * sin + center.x;
			corner.y = x * sin + y * cos + center.y;
		}
	};

	private isPointInsideRectangle = (point: IDescartesPosition, rect: IFrameArea): boolean => point.x >= rect.x
		&& point.x <= rect.x + rect.width && point.y >= rect.y && point.y <= rect.y + rect.height;

	private doLinesIntersect = (
		a: IDescartesPosition,
		b: IDescartesPosition,
		c: IDescartesPosition,
		d: IDescartesPosition,
	): boolean => {
		// Вычислить направление каждой линии
		const direction1 = { x: b.x - a.x, y: b.y - a.y };
		const direction2 = { x: d.x - c.x, y: d.y - c.y };

		// Вычислить знаменатель уравнения, чтобы найти точку пересечения двух прямых
		const denominator = direction1.x * direction2.y - direction2.x * direction1.y;

		// Если знаменатель равен 0, то прямые параллельны и не пересекаются
		if (denominator === 0) {
			return false;
		}

		// Вычислить числитель уравнения, чтобы найти точку пересечения двух прямых
		const numerator1 = (c.x - a.x) * direction2.y - (c.y - a.y) * direction2.x;
		const numerator2 = (c.x - a.x) * direction1.y - (c.y - a.y) * direction1.x;

		// Вычислить точку пересечения двух прямых
		const intersection1 = {
			x: a.x + (numerator1 / denominator) * direction1.x,
			y: a.y + (numerator1 / denominator) * direction1.y,
		};
		const intersection2 = {
			x: c.x + (numerator2 / denominator) * direction2.x,
			y: c.y + (numerator2 / denominator) * direction2.y,
		};

		// Проверить, находится ли точка пересечения внутри обоих сегментов линии
		return this.isPointInsideLineSegment(intersection1, a, b) && this.isPointInsideLineSegment(intersection1, c, d);
	};

	private isPointInsideLineSegment = (
		point: IDescartesPosition,
		start: IDescartesPosition,
		end: IDescartesPosition,
	): boolean => point.x >= Math.min(start.x, end.x) && point.x <= Math.max(start.x, end.x)
		&& point.y >= Math.min(start.y, end.y) && point.y <= Math.max(start.y, end.y);
}

export default SpatialQuadrantsTree;
