import React, { useMemo } from 'react';
import { classNames } from '../../../../../shared/libs/classNames';
import CreateInputArrow from '../../../../../components/pages/features/moduleWindow/createInputArrow/CreateInputArrow';
import { workshopCategoryApi } from '../../../../../entities/workshop-category';

interface SelectModuleCategoriesProps {
	className?: string,
	selectedCategories: string[],
	selectCategory: (id: string) => void,
	unselectCategory: (id: string) => void,
}

export const SelectModuleCategories = ({
	className, selectedCategories, unselectCategory, selectCategory,
}: SelectModuleCategoriesProps) => {
	const { data: categories } = workshopCategoryApi.useGetWorkshopCategoriesQuery({
		page: 1,
		limit: 127,
	});

	const selectData = useMemo(
		() => categories?.moduleCategories
			?.map(category => ({ ...category, id: category.id.toString() }))
			?.sort((a, b) => Number(a?.sequenceNumber || 0) - Number(b?.sequenceNumber || 0)),
		[categories],
	);

	return (
		<div className={classNames('', {}, [className])}>
			<CreateInputArrow
				text="Категория"
				inputData={selectData}
				choicedItemsId={selectedCategories}
				onAddChoicedItem={selectCategory}
				onRemoveChoicedItem={unselectCategory}
			/>
		</div>
	);
};
