import SocketType from './SocketType';
import ISocketMessageStructure from './ISocketMessageStructure';
import Utils from '../utils/impl/Utils';
import { SocketID } from '../Types';

class SocketMessage<TypeBody> {
	private readonly id: SocketID;
	private readonly type: SocketType;
	private readonly body: TypeBody;
	private readonly structure: ISocketMessageStructure<TypeBody>;

	constructor(type: SocketType, body: TypeBody) {
		this.type = type;
		this.body = body;
		this.id = Utils.Generate.UUID4();
		this.structure = {
			id: this.id,
			type,
			body,
		};
	}

	public getID = (): string => this.id;
	public getBody = (): TypeBody => this.body;
	public getType = (): SocketType => this.type;
	public getJSON = (): string => JSON.stringify(this.structure);
}

export default SocketMessage;
