import DynamicMultiPageManipulator from '../DynamicMultiPageManipulator';
import WorkshopTemplateSpatialAreaTree
	from '../../Sketch/mechanics/spatial-quadrants/spatial-tree/implementations/WorkshopTemplateSpatialAreaTree';
import WorkshopTemplateUseCases from '../../Sketch/use-cases/impl/WorkshopTemplateUseCases';
import { ITemplateData } from '../../../entities/templates/types';
import { AnyComponentStructure } from '../../Sketch/Types';
import SketchState from '../../Sketch/mutations/SketchState';
import CursorView from '../../Sketch/cursor/CursorView';
import LoadingPage from '../../Sketch/page/LoadingPage';
import ActionStore from '../../Sketch/mutations/ActionStore';
import ModuleBuilder from '../../Sketch/factories/ModuleBuilder';
import InjectorFactory from '../../Sketch/factories/InjectorFactory';
import TableGridMutator from '../../Sketch/graphic/table/TableGridMutator';
import ComponentBuilder from '../../Sketch/factories/ComponentBuilder';
import PasteCoordinator from '../../Sketch/mechanics/PasteCoordinator';
import ManipulatorSocket from '../../Sketch/replication/ManipulatorSocket';
import ComponentInjector from '../../Sketch/component-injector/ComponentInjector';
import ComponentOrganizer from '../../Sketch/mechanics/component-organizer/ComponentOrganizer';
import ReplicationIndicator from '../../Sketch/interface/bars/replication-indicator/ReplicationIndicator';
import SketchStructureStabilizer from '../../Sketch/mechanics/mutation-observer/SketchStructureStabilizer';
import ComponentFocusObserver from '../../Sketch/utils/observers/ComponentFocusObserver';
import WorkshopTemplateConstructorInterface
	from '../../Sketch/interface/implements/WorkshopTemplateConstructorInterface';
import WorkshopTemplateCursorHandler from '../../Sketch/cursor/impl/WorkshopTemplateCursorHandler';
import WorkshopTemplateContextMenuHandler
	from '../../Sketch/mechanics/context-menu/impl/WorkshopTemplateContextMenuHandler';
import WorkshopTemplateKeyboardObserver
	from '../../Sketch/mechanics/keyboard-observer/WorkshopTemplateKeyboardObserver';
import MutablePagesComponentTree from '../../Sketch/component-tree/impl/MutablePagesComponentTree';
import DynamicGraphicFactory from '../../Sketch/factories/graphic/DynamicGraphicFactory';
import KeyCombination from '../../Sketch/utils/KeyCombination';
import { notificationError } from '../../Notifications/callNotifcation';
import ISketchStructure from '../../Sketch/ISketchStructure';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import MultiPageComponentCloner from '../../Sketch/mechanics/component-cloner/impl/MultiPageComponentCloner';
import MultiPageMagneticLines from '../../Sketch/mechanics/magnetic-lines/impl/MultiPageMagneticLines';
import MultiPageComponentGrouper from '../../Sketch/mechanics/component-grouper/impl/MultiPageComponentGrouper';
import EditModeContext from '../../Sketch/mechanics/EditModeContext';

interface IWorkshopTemplateConstructorProps {
	onBackPage: VoidFunction,
	templateData: ITemplateData,
	onRemoveTemplate: VoidFunction,
	structure: AnyComponentStructure,
	manipulatorContainer: HTMLDivElement,
}

class WorkshopTemplateConstructor extends DynamicMultiPageManipulator<
	WorkshopTemplateUseCases,
	WorkshopTemplateSpatialAreaTree
> {
	private readonly state: SketchState;
	private readonly cursorView: CursorView;
	private readonly loadingPage: LoadingPage;
	private readonly actionStore: ActionStore;
	private readonly moduleBuilder: ModuleBuilder;
	private readonly editModeContext: EditModeContext;
	private readonly injectorFactory: InjectorFactory;
	private readonly tableGridMutator: TableGridMutator;
	private readonly componentBuilder: ComponentBuilder;
	private readonly pasteCoordinator: PasteCoordinator;
	private readonly socketConnection: ManipulatorSocket;
	private readonly componentInjector: ComponentInjector;
	private readonly magneticLines: MultiPageMagneticLines;
	private readonly componentOrganizer: ComponentOrganizer;
	private readonly componentCloner: MultiPageComponentCloner;
	private readonly replicationIndicator: ReplicationIndicator;
	private readonly componentGrouper: MultiPageComponentGrouper;
	private readonly sketchStabilizer: SketchStructureStabilizer;
	private readonly cursorHandler: WorkshopTemplateCursorHandler;
	private readonly componentFocusObserver: ComponentFocusObserver;
	private readonly contextMenu: WorkshopTemplateContextMenuHandler;
	private readonly interface: WorkshopTemplateConstructorInterface;
	private readonly keyboardObserver: WorkshopTemplateKeyboardObserver;

	constructor(props: IWorkshopTemplateConstructorProps) {
		super(props.manipulatorContainer);

		try {
			this.setID(props.templateData.id);

			this.state = new SketchState();
			this.actionStore = new ActionStore();
			this.moduleBuilder = new ModuleBuilder();
			this.editModeContext = new EditModeContext();
			this.injectorFactory = new InjectorFactory();
			this.useCases = new WorkshopTemplateUseCases();
			this.tableGridMutator = new TableGridMutator();
			this.pasteCoordinator = new PasteCoordinator();
			this.componentInjector = new ComponentInjector();
			this.componentOrganizer = new ComponentOrganizer();
			this.componentGrouper = new MultiPageComponentGrouper();
			this.sketchStabilizer = new SketchStructureStabilizer();
			this.cursorHandler = new WorkshopTemplateCursorHandler();
			this.interface = new WorkshopTemplateConstructorInterface();
			this.contextMenu = new WorkshopTemplateContextMenuHandler();
			this.loadingPage = new LoadingPage(props.manipulatorContainer);
			this.replicationIndicator = new ReplicationIndicator(this.manipulatorElement);
			this.componentTree = new MutablePagesComponentTree(props.manipulatorContainer);
			this.areaTree = new WorkshopTemplateSpatialAreaTree(this.mousePositionObserver);

			this.componentFocusObserver = new ComponentFocusObserver(props.manipulatorContainer);
			this.scrollObserver.subscribe(this.areaTree.onScroll);

			this.scrollObserver.subscribe(this.componentTree.updatePageFocusIndex);

			this.addDestructListener(this.scrollObserver.destruct);
			this.cursorView = new CursorView(this.manipulatorElement);

			this.socketConnection = new ManipulatorSocket(props.templateData.id);

			this.socketConnection.addStartSendListener(this.replicationIndicator.showReplicationInProcess);
			this.socketConnection.addSendErrorListener(this.replicationIndicator.showReplicationError);
			this.socketConnection.addSendSuccessListener(this.replicationIndicator.showReplicationSuccess);

			this.actionStore.addPostUndoRedoListener(this.componentTree.syncPagePanels);

			this.graphicFactory = new DynamicGraphicFactory(this.interface);

			this.componentBuilder = new ComponentBuilder();

			this.pageStructureObserver.subscribe(this.savePreview);

			this.componentCloner = new MultiPageComponentCloner({
				componentTree: this.componentTree,
				graphicFactory: this.graphicFactory,
				toPasteSketchID: props.templateData.id,
				componentFactory: this.componentFactory,
				mouseObserver: this.mousePositionObserver,
			});

			this.actionStore.addPostUndoRedoListener(this.areaTree.sync);

			this.magneticLines = new MultiPageMagneticLines(this.componentTree);
			this.keyboardObserver = new WorkshopTemplateKeyboardObserver(this.componentTree, this.useCases);
			this.keyboardObserver
				.subscribeOnKeyDown(KeyCombination.CTRL, this.areaTree.forceUpdateAreaFromMousePosition);

			this.componentTree.addPostChangeFocusPageListener(this.interface.sync);

			this.scrollObserver.subscribe(this.areaTree.sync);

			this.addDestructListener(this.cursorHandler.destruct);
			this.addDestructListener(this.keyboardObserver.destruct);
			this.addDestructListener(this.socketConnection.destruct);

			this.addEnableLoadingModeListener(this.loadingPage.show);
			this.addDisableLoadingModeListener(this.loadingPage.hide);

			this.componentFocusObserver.connectDependencies({
				componentTree: this.componentTree,
			});
			this.cursorHandler.connectDependencies({
				sketchConstructor: this,
				areaTree: this.areaTree,
				useCases: this.useCases,
				interface: this.interface,
				cursorView: this.cursorView,
				contextMenu: this.contextMenu,
				magneticLines: this.magneticLines,
				componentTree: this.componentTree,
				tableOrganizer: this.tableOrganizer,
				editModeContext: this.editModeContext,
				keyboardObserver: this.keyboardObserver,
				componentInjector: this.componentInjector,
				sketchStabilizer: this.sketchStabilizer,
				componentOrganizer: this.componentOrganizer,
				mouseOffsetObserver: this.mouseOffsetObserver,
				pageStructureObserver: this.pageStructureObserver,
				mousePositionObserver: this.mousePositionObserver,
				componentFocusObserver: this.componentFocusObserver,
				pageTextOverflowObserver: this.pageTextOverflowObserver,
			});
			this.contextMenu.connectDependencies({
				areaTree: this.areaTree,
				useCases: this.useCases,
				tableOrganizer: this.tableOrganizer,
				manipulatorInterface: this.interface,
				mousePositionObserver: this.mousePositionObserver,
			});
			this.pageStructureObserver.connectDependencies({
				componentTree: this.componentTree,
			});
			this.componentFactory.connectDependencies({
				areaTree: this.areaTree,
				useCases: this.useCases,
				componentTree: this.componentTree,
				sketchStabilizer: this.sketchStabilizer,
				pageStructureObserver: this.pageStructureObserver,
				componentFocusObserver: this.componentFocusObserver,
			});
			this.pasteCoordinator.connectDependencies({
				componentCloner: this.componentCloner,
				graphicFactory: this.graphicFactory,
				componentFactory: this.componentFactory,
				componentInjector: this.componentInjector,
				mouseObserver: this.mousePositionObserver,
			});
			this.sketchStabilizer.connectDependencies({
				state: this.state,
				areaTree: this.areaTree,
				actionStore: this.actionStore,
				sender: this.socketConnection,
				componentTree: this.componentTree,
				focusObserver: this.componentFocusObserver,
			});
			this.injectorFactory.connectDependencies({
				cursorView: this.cursorView,
				componentTree: this.componentTree,
				componentAlignment: this.componentAlignment,
				mousePositionObserver: this.mousePositionObserver,
			});
			this.componentOrganizer.connectDependencies({
				componentTree: this.componentTree,
				graphicFactory: this.graphicFactory,
				focusObserver: this.componentFocusObserver,
			});
			this.componentTree.connectDependencies({
				graphicFactory: this.graphicFactory,
				componentFactory: this.componentFactory,
				tableOrganizer: this.tableOrganizer,
			});
			this.pageTextOverflowObserver.connectDependencies({
				componentTree: this.componentTree,
			});
			this.tableOrganizer.connectDependencies({
				componentTree: this.componentTree,
				graphicFactory: this.graphicFactory,
			});
			this.moduleBuilder.connectDependencies({
				componentTree: this.componentTree,
				graphicFactory: this.graphicFactory,
				componentBuilder: this.componentBuilder,
				componentFactory: this.componentFactory,
			});
			this.useCases.connectDependencies({
				spatialTree: this.areaTree,
				actionStore: this.actionStore,
				componentTree: this.componentTree,
				moduleBuilder: this.moduleBuilder,
				manipulatorInterface: this.interface,
				tableOrganizer: this.tableOrganizer,
				componentCloner: this.componentCloner,
				componentGrouper: this.componentGrouper,
				tableGridMutator: this.tableGridMutator,
				pasteCoordinator: this.pasteCoordinator,
				sketchStabilizer: this.sketchStabilizer,
				componentInjector: this.componentInjector,
				mouseObserver: this.mousePositionObserver,
				componentAlignment: this.componentAlignment,
				componentFocusObserver: this.componentFocusObserver,
			});
			this.componentInjector.connectDependencies({
				injectorFactory: this.injectorFactory,
			});
			this.areaTree.connectDependencies({
				componentTree: this.componentTree,
				editModeContext: this.editModeContext,
				rootSpatialElement: this.manipulatorElement,
			});
			this.state.connectDependencies({
				manipulator: this,
				componentTree: this.componentTree,
			});
			this.actionStore.connectDependencies({
				sketchStabilizer: this.sketchStabilizer,
			});
			this.componentGrouper.connectDependencies({
				componentTree: this.componentTree,
				componentBuilder: this.componentBuilder,
			});
			this.interface.connectDependencies({
				sketchManipulator: this,
				useCases: this.useCases,
				onBackPage: props.onBackPage,
				actionStore: this.actionStore,
				componentTree: this.componentTree,
				onRemoveTemplate: props.onRemoveTemplate,
				componentInjector: this.componentInjector,
				pageTextOverflowObserver: this.pageTextOverflowObserver,
			});
			this.graphicFactory.connectDependencies({
				useCases: this.useCases,
				componentTree: this.componentTree,
				componentFocusObserver: this.componentFocusObserver,
			});
			this.componentBuilder.connectDependencies({
				graphicFactory: this.graphicFactory,
				componentFactory: this.componentFactory,
			});

			this.componentTree.injectDependencies();
			this.state.injectDependencies();
			this.actionStore.injectDependencies();
			this.areaTree.injectDependencies();
			this.interface.injectDependencies();
			this.cursorHandler.injectDependencies();
			this.injectorFactory.injectDependencies();
			this.componentGrouper.injectDependencies();
			this.componentInjector.injectDependencies();
			this.pageStructureObserver.injectDependencies();
			this.graphicFactory.injectDependencies();
			this.pageTextOverflowObserver.injectDependencies();
			this.pasteCoordinator.injectDependencies();
			this.sketchStabilizer.injectDependencies();
			this.useCases.injectDependencies();
			this.contextMenu.injectDependencies();
			this.componentFocusObserver.injectDependencies();
			this.moduleBuilder.injectDependencies();
			this.componentOrganizer.injectDependencies();
		} catch (e) {
			notificationError(
				'Ошибка конструктора',
				'Возникли непредвиденные ошибки при инициализации конструктора, обратитесь в техническую поддержку.',
			);
			console.error(e);
		}

		const interfaceElements = this.interface.getElements();
		this.manipulatorElement.append(interfaceElements);

		const embedElement = this.componentTree.getElementForEmbedding();
		this.manipulatorElement.append(embedElement);

		this.addDestructListener(this.savePreview);

		this.setStructure({
			...props.templateData,
			root: props.structure,
		});

		this.areaTree.sync();

		this.state.syncState();

		this.interface.sync();

		this.sketchStabilizer.init();

		this.addPrevChangeNameListener(this.sketchStabilizer.startUserAction);
		this.addPostChangeNameListener(this.sketchStabilizer.stopUserAction);
	}

	/**
	 * Устанавливают структуру и отображает её в конструкторе.
	 * @param structure - структура для отображения.
	 */
	private setStructure = (structure: ISketchStructure): void => {
		const { id, name } = structure;

		this.disableLoadingMode();

		try {
			this.setID(id);
			this.setName(name);
			this.componentTree.load(structure.root);
			this.areaTree.sync();
			this.componentTree.syncPagePanels();
		} catch (err) {
			const manipulatorError = err as ManipulatorError;
			this.componentTree.reset();
			notificationError(
				'Ошибка инициализации',
				'Возникли непредвиденные ошибки при загрузке структуры, обратитесь в техническую поддержку.',
			);
			console.error(manipulatorError.stack);
		}
	};
}

export default WorkshopTemplateConstructor;
