import SpatialAreaType from './SpatialAreaType';
import IFrameArea from './IFrameArea';
import IAreaSizeMutable from '../../area-mutators/IAreaSizeMutable';
import ISpatialAreaBaseData from './ISpatialAreaBaseData';

/** Объект области для взаимодействия в пространстве квадрантов (Квадрант плоскости — любая из 4 областей
 * (углов), на которые плоскость делится двумя взаимно перпендикулярными прямыми, принятыми в качестве осей
 * координат). Может являться фреймом графики компонента,
 * областью для мутации размеров графики, областью изменения ширины столбцов таблицы, мутации размеров
 * изображения внутри компонента и т.д. */
abstract class SpatialArea<TData extends ISpatialAreaBaseData<any>> {
	public abstract readonly type: SpatialAreaType;
	/** Возвращает область, которую занимает этот объект в пространстве. */
	public abstract getGlobalFrameArea(): IFrameArea;
	/** Возвращает объект с дополнительной информацией для каждого типа области. */
	public abstract getData(): TData;
	/** Предназначена ли область для изменения размеров элемента. */
	public abstract isResizeType(): boolean;
	/** Возвращает все связанные с ней фреймы. */
	public abstract getRelatedFrames: () => IAreaSizeMutable[];
	/** Разрешено ли сейчас перемещение этой области. */
	public abstract isAllowMutatePosition: () => boolean;
}

export default SpatialArea;
