import React from 'react';
import { NewModal } from '../../../../../../ui/new-modal';
import { Title } from '../../../../../../ui/title';
import css from './change-workshop-templates-modal.module.scss';
import { ChangeableTagBar } from '../../../../../../../widgets/changeable-tag-bar';
import { IWorkshopTemplateData } from '../../../../../../../entities/templates/workshop/template/model/types';
import {
	useCreateWorkshopTemplateTag,
} from '../../../../../../../features/workshop/tags/create/api/useCreateWorkshopTemplateTag';
import { TemplateWorkshopSearchTagBar } from './template-workshop-search-tag-bar/template-workshop-search-tag-bar';
import {
	useBindTagToWorkshopTemplate,
} from '../../../../../../../features/workshop/templates/bind-tag/api/useBindTagToWorkshopTemplate';
import {
	useUnbindTagToWorkshopTemplate,
} from '../../../../../../../features/workshop/templates/unbind-tag/api/useUnbindTagToWorkshopTemplate';

interface ChangeWorkshopTemplateModalProps {
	isActive: boolean;
	setActive: (value: boolean) => void;
	template: IWorkshopTemplateData | undefined,
}

export const ChangeWorkshopTemplateModal = ({
	isActive,
	template,
	setActive,
}: ChangeWorkshopTemplateModalProps) => {
	const { onCreateTag } = useCreateWorkshopTemplateTag();
	const { onBindTag } = useBindTagToWorkshopTemplate(template?.template.id || '');
	const { onUnbindTag } = useUnbindTagToWorkshopTemplate(template?.template.id || '');

	const bindCreatedTag = async (name: string) => {
		const res = await onCreateTag(name);
		if (res) await onBindTag(res);
	};

	return (
		<NewModal styles={css.wrapper} active={isActive} setActive={setActive}>
			<Title styles={css.title}>Изменение тегов шаблона</Title>
			<TemplateWorkshopSearchTagBar template={template?.template} />
			<ChangeableTagBar
				unbindTag={onUnbindTag}
				onCreateTag={bindCreatedTag}
				activeTags={template?.template.tags.fromTemplates || []}
				disabledTags={template?.template.tags.fromModules || []}
			/>
		</NewModal>
	);
};
