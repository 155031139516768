import SpatialArea from '../SpatialArea';
import TableComponent from '../../../../../components/table/TableComponent';
import TableCell from '../../../../../graphic/table/cells/TableCell';
import IFrameArea from '../IFrameArea';
import IAreaSizeMutable from '../../../area-mutators/IAreaSizeMutable';
import SpatialAreaType from '../SpatialAreaType';
import ISpatialAreaBaseData from '../ISpatialAreaBaseData';
import TableGraphic from '../../../../../graphic/table/TableGraphic';

interface ISpatialTableCellData extends ISpatialAreaBaseData<TableGraphic> {
	areaCell: TableCell,
	component: TableComponent,
}

/**
 * Область для ячейки внутри таблицы.
 */
class SpatialTableCellArea extends SpatialArea<ISpatialTableCellData> {
	public readonly type: SpatialAreaType = SpatialAreaType.TABLE_CELL;

	private readonly cell: TableCell;
	private readonly graphic: TableGraphic;
	private readonly component: TableComponent;

	constructor(component: TableComponent, cell: TableCell, graphic: TableGraphic) {
		super();
		this.cell = cell;
		this.graphic = graphic;
		this.component = component;
	}

	public getGlobalFrameArea = (): IFrameArea => ({
		...this.cell.getFrameArea(),
	});

	public getRelatedFrames = (): IAreaSizeMutable[] => [];

	public isAllowMutatePosition = (): boolean => false;

	public isResizeType = (): boolean => false;

	public getData = (): ISpatialTableCellData => ({
		areaCell: this.cell,
		graphic: this.graphic,
		component: this.component,
	});
}

export default SpatialTableCellArea;
