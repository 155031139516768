import { useNavigate } from 'react-router-dom';
import { setAccount, setAccountAuthorized } from '../../redux/reducers/personalAccountReducer';
import { useAppDispatch } from './redux';
import { IAccount } from '../../entities/user/account/api/types';
import { apis } from '../api/apis';

export const useLogout = () => {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const onLogOut = () => {
		localStorage.removeItem('authorization');
		sessionStorage.removeItem('authorization');

		apis.forEach(el => dispatch(el.util?.resetApiState()));

		dispatch(setAccountAuthorized(false));
		dispatch(setAccount({} as IAccount));

		navigate('/');
	};

	return { onLogOut };
};
