// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _ from 'lodash';
import HTMLGenerator from '../../../utils/HTMLGenerator';
import Utils from '../../../utils/impl/Utils';
import ITableCellTexture from './ITableCellTexture';
import Editor from '../../../mechanics/mext/editor';
import { Model } from '../../../mechanics/mext/editor/types';
import IFrameArea from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import { TokenFormat } from '../../../mechanics/mext/parser';

/**
 * Ячейка таблицы.
 */
class TableCell {
	private readonly ELEMENT_CLASS_NAME = 'graphic-table_cell';
	private readonly ELEMENT_FOCUS_CLASS_NAME = 'focus';

	private readonly element: HTMLElement;
	private readonly editor: Editor;

	private id: string;
	private isFocus: boolean;

	private row: number;
	private column: number;
	private rowSpan: number;
	private columnSpan: number;
	private background: string;

	// Строка, с которой начинается перенос в родительской графике
	private startRow: number;
	// Является ли ячейка первой при выделении ячеек.
	private isStartFocus: boolean;

	constructor() {
		this.id = Utils.Generate.UUID4();
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASS_NAME,
		});
		this.row = 1;
		this.column = 1;
		this.rowSpan = 1;
		this.startRow = 0;
		this.columnSpan = 1;
		this.background = '';
		this.isStartFocus = false;
		this.editor = new Editor(this.element, {
			parse: {},
			nowrap: false,
			value: '',
			resetFormatOnNewline: false,
			shortcuts: {
				'Cmd+Z': editor => editor.undo(),
				'Cmd+Y': editor => editor.redo(),
				Enter: () => this.editor.disableEditable(),
				'Cmd+Shift+Z': editor => editor.redo(),
				'Cmd+B': editor => editor.toggleFormat(TokenFormat.Bold),
				'Cmd+I': editor => editor.toggleFormat(TokenFormat.Italic),
				'Cmd+U': editor => editor.toggleFormat(TokenFormat.Strike),
				// 'Cmd+Shift+C': editor => editor.toggleFormat(TokenFormat.Monospace),
				// 'Ctrl+L': editor => editor.pickLink(),
				// DoubleClick: () => alert('Вход в режим редактирования временно не работает'),
			},
		});
		this.isFocus = false;
		this.setGridId(this.id);

		this.element.addEventListener('focus', this.onDOMFocus);
	}

	/**
	 * Устанавливаем модель в редактор ячейки.
	 * @param content - модель редактора (объект типа Model).
	 */
	public setContent = (content: Model) => {
		this.editor.model = content;
	};

	public setBackground = (color: string) => {
		this.background = color;
		this.element.style.background = color;
	};

	public setRowSpan = (span: number) => {
		this.rowSpan = span;
		this.syncGridPosition();
	};

	public setColumnSpan = (span: number) => {
		this.columnSpan = span;
		this.syncGridPosition();
	};

	public setColumn = (column: number) => {
		this.column = column;
		this.syncGridPosition();
	};

	public setRow = (row: number) => {
		this.row = row;
		this.syncGridPosition();
	};

	/**
	 * Устанавливает значения строки, с которой начинается перенос в родительской графике
	 * @param row - номер строки.
	 */
	public setStartRow = (row: number) => {
		this.startRow = row;
		this.syncGridPosition();
	};

	public getTexture = (): ITableCellTexture => ({
		id: this.id,
		column: this.column,
		row: this.row,
		rowSpan: this.rowSpan,
		columnSpan: this.columnSpan,
		background: this.background,
		content: this.editor.getCopyModel(),
	});

	public enableMutationMode = () => {
		this.editor.enableEditable();
	};

	public disableMutationMode = () => {
		this.editor.disableEditable();
	};

	public getHeight = (): number => {
		const { height } = this.element.getBoundingClientRect();
		return height;
	};

	public enableFocus = () => {
		if (this.isFocus) {
			return;
		}
		this.isFocus = true;
		this.element.classList.add(this.ELEMENT_FOCUS_CLASS_NAME);
	};

	public disableFocus = () => {
		if (!this.isFocus) {
			return;
		}
		this.isFocus = false;
		this.unmarkStartFocus();
		this.element.classList.remove(this.ELEMENT_FOCUS_CLASS_NAME);
	};

	public markStartFocus = () => {
		this.isStartFocus = true;
	};

	public getIsStartFocus = () => this.isStartFocus;

	public getFrameArea = (): IFrameArea => {
		const {
			height, width, x, y,
		} = this.element.getBoundingClientRect();
		return {
			x: x + window.scrollX,
			y: y + window.scrollY,
			height,
			width,
			rotate: 0,
		};
	};

	public addPostInputListener = (listener: VoidFunction) => {
		this.editor.addPostInputEvent(listener);
	};

	public addPostChangeModelListener = (listener: VoidFunction) => {
		this.editor.addPostChangeModelEvent(listener);
	};

	public setID = (id: string) => {
		this.id = id;
	};

	/**
	 * Ставит курсор текста внутрь ячейки.
	 */
	public enableTextFocus = () => {
		this.editor.focus();
	};

	/**
	 * Возвращает уникальный идентификатор ячейки.
	 */
	public getID = (): string => this.id;

	/**
	 * Возвращает номер строки, в которой находится ячейка (строки начинаются с 0)
	 */
	public getRow = (): number => this.row;

	/**
	 * Возвращает номер колонки, в которой находится ячейка (колонки начинаются с 0)
	 */
	public getColumn = (): number => this.column;

	/**
	 * Возвращает количество строк, которые занимает ячейка.
	 */
	public getRowSpan = () => this.rowSpan;

	/**
	 * Возвращает количество колонок, которые занимает ячейка.
	 * В случае, если ячейка занимается одну клетку, то значение будет ровняться 1.
	 */
	public getColumnSpan = () => this.columnSpan;

	/**
	 * Возвращает HTML-элемент ячейки.
	 */
	public getElement = (): HTMLElement => this.element;

	/**
	 * Возвращает редактор ячейки.
	 */
	public getEditor = (): Editor => this.editor;

	/**
	 * Проверяет находиться ли ячейка в фокусе.
	 */
	public hasFocus = (): boolean => this.isFocus;

	/**
	 * Устанавливает CSS-свойство grid-area (имя для последующего построения макета) для HTML-элемента ячейки.
	 */
	private setGridId = (id: string) => {
		this.element.style.gridArea = id;
	};

	private syncGridPosition = () => {
		this.element.style.gridRowStart = (this.row - this.startRow + 1).toString();
		this.element.style.gridColumnStart = (this.column + 1).toString();

		this.element.style.gridRowEnd = (this.row - this.startRow + this.rowSpan + 1).toString();
		this.element.style.gridColumnEnd = (this.column + this.columnSpan + 1).toString();
	};

	private onDOMFocus = () => {
		this.enableFocus();
		this.markStartFocus();
	};

	private unmarkStartFocus = () => {
		this.isStartFocus = false;
	};
}

export default TableCell;
