import { useEffect, useMemo } from 'react';
import { TTemplateSortBy } from '../../../../../entities/templates/types';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { useDebounce } from '../../../../../shared/libs/useDebounce';
import { IWorkshopTemplate } from '../../../../../entities/templates/workshop/template/model/types';
import { setWorkshopTemplates } from '../../../../../entities/templates/workshop/template/model/slice';
import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { ITEM_LIMIT_PER_PAGE } from '../../../../../components/pages/collection/section/template/SectionTemplate';
import useDynamicPagination from '../../../../../hooks/useDynamicPagination';

export const useGetAllWorkshopTemplates = (sortBy?: TTemplateSortBy) => {
	const dispatch = useAppDispatch();

	const {
		search,
		templates,
		selectedTags,
		publishedFilter,
		tariffPermissionFilter,
	} = useAppSelector(state => state.workshopTemplate);

	const debounceSearch = useDebounce(search.trim(), 250);

	const setTemplates = (list: IWorkshopTemplate[]) => dispatch(setWorkshopTemplates(list));

	const tags = useMemo(() => selectedTags.map(tag => tag.id.toString()), [selectedTags]);

	const {
		data: initialTemplates,
		isLoading,
	} = workshopTemplateAPI.useGetAllQuery({
		tags,
		sortBy,
		page: 1,
		search: debounceSearch,
		limit: ITEM_LIMIT_PER_PAGE,
		filterBy: publishedFilter?.id,
		tariff: tariffPermissionFilter.permission,
	});

	const [getTemplates] = workshopTemplateAPI.useLazyGetAllQuery();

	const fetchData = async (page: number, perPage: number) => {
		if (templates) {
			return getTemplates({
				page,
				sortBy,
				limit: perPage,
				search: debounceSearch,
				tags,
				filterBy: publishedFilter?.id,
				tariff: tariffPermissionFilter.permission,
			})
				.unwrap()
				.then(data => {
					setTemplates([...templates, ...data.templates]);
				});
		}
		return undefined;
	};

	const {
		setCurrentPage,
		setTotalCount,
	} = useDynamicPagination(
		fetchData,
		ITEM_LIMIT_PER_PAGE,
		2,
		templates?.length || 0,
	);

	// * При изменении поискового значения, вмонтировании компонента
	useEffect(() => {
		if (initialTemplates) {
			setCurrentPage(2);
			setTemplates(initialTemplates?.templates || []);

			setTotalCount(initialTemplates?.totalCount || 0);
		}
	}, [initialTemplates]);

	// useEffect(() => {
	// 	cleanSelectedList();
	// }, [search, sortBy]);

	return {
		isLoading,
	};
};
