import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import { notificationError, notificationSuccess } from '../../Notifications/callNotifcation';
import { workshopTemplateAPI } from '../../../entities/templates/workshop/template/api/api';
import WorkshopTemplateConstructor from './WorkshopTemplateConstructor';
import store from '../../../redux/store/store';
import { templatePrivateAPI } from '../../../entities/templates/private/api/api';
import PrivateTemplateConstructor from '../PrivateTemplateConstructor/PrivateTemplateConstructor';

export interface TemplateConstructorProps {
	sketchID: string;
}

const ComponentWorkshopTemplateConstructor = (props: TemplateConstructorProps) => {
	const { sketchID } = props;

	const navigate = useNavigate();

	const refManipulatorContainer: React.RefObject<HTMLDivElement> = React.createRef();

	const onExitConstructor = () => {
		navigate(`/integrator/workshop/templates/${sketchID}`);
	};

	const [del] = workshopTemplateAPI.useDeleteWorkshopTemplateMutation();

	const onRemoveTemplate = async () => {
		try {
			await del({ templates: [sketchID] })
				.unwrap();

			notificationSuccess('Шаблон успешно удален!');
			navigate(-1);
		} catch (e) {
			notificationError('Возникла непредвиденная ошибка удаления шаблона');
		}
	};

	useEffect(() => {
		if (refManipulatorContainer.current === null || refManipulatorContainer.current === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}

		let manipulator: WorkshopTemplateConstructor;

		store.dispatch(workshopTemplateAPI.endpoints?.get.initiate({ template: sketchID }))
			.unwrap()
			.then(res => {
				const { template, rootComponent } = res;
				manipulator = new WorkshopTemplateConstructor({
					onRemoveTemplate,
					templateData: template,
					structure: rootComponent,
					onBackPage: onExitConstructor,
					manipulatorContainer: refManipulatorContainer.current as HTMLDivElement,
				});
			})
			.catch(() => {
				notificationError('Загрузка структуры', 'Возникла непредвиденная ошибка загрузки структуры.');
			});

		return () => {
			manipulator && manipulator.destruct();
		};
	}, []);

	return <div ref={refManipulatorContainer} className="sketch-manipulator workshop-template-template" />;
};

export const WorkshopTemplateConstructorWithRouter = () => {
	const { id } = useParams();
	if (id === undefined) {
		return <p>error get sketch id</p>;
	}

	return <ComponentWorkshopTemplateConstructor sketchID={id} />;
};
