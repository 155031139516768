import StaticMultiPageManipulator from '../StaticMultiPageManipulator';
import PagesComponentTree from '../../Sketch/component-tree/impl/PagesComponentTree';
import StaticGraphicFactory from '../../Sketch/factories/graphic/StaticGraphicFactory';
import { AnyComponentStructure } from '../../Sketch/Types';
import StaticComponentFactory from '../../Sketch/factories/component/StaticComponentFactory';

class OfferPrinter extends StaticMultiPageManipulator {
	constructor(manipulatorContainer: HTMLDivElement, structure: AnyComponentStructure) {
		super(manipulatorContainer);

		this.componentTree = new PagesComponentTree(manipulatorContainer);
		this.componentFactory = new StaticComponentFactory();
		this.graphicFactory = new StaticGraphicFactory();

		this.componentTree.connectDependencies({
			graphicFactory: this.graphicFactory,
			componentFactory: this.componentFactory,
		});
		this.graphicFactory.connectDependencies({
			componentTree: this.componentTree,
		});
		this.componentFactory.connectDependencies(null);

		this.componentTree.injectDependencies();
		this.graphicFactory.injectDependencies();
		this.componentFactory.injectDependencies();

		this.componentTree.load(structure);

		const embedElement = this.componentTree.getElementForEmbedding();
		this.manipulatorElement.append(embedElement);
	}
}

export default OfferPrinter;
